import textPicPath from "../../../../../resources/icons/Contact/Message.png";
import callPicPath from "../../../../../resources/icons/Contact/Call.png";
import emailPicPath from "../../../../../resources/icons/Contact/Email.png";

console.log(textPicPath, callPicPath, emailPicPath);

const actionTypes = [
	{
		id: "text",
		label: "Text",
		icon: textPicPath,
		key: "number",
		mapKey: "phoneNumbers",
		urlStart: "sms",
	},
	{
		id: "call",
		label: "Call",
		icon: callPicPath,
		key: "number",
		mapKey: "phoneNumbers",
		urlStart: "tel",
	},
	{
		id: "email",
		label: "Email",
		icon: emailPicPath,
		key: "email",
		mapKey: "emailAddresses",
		urlStart: "mailto",
	},
];

export default actionTypes;
