import React, {useEffect, useState, useContext} from "react";
import {NavLink} from "react-router-dom";

import {doc, getDoc, onSnapshot} from "firebase/firestore";

import {AuthContext} from "../../AuthProvider";
import Logo from "../../../../../resources/images/01.png";
import Profile from "../Components/Profile";
import ProfileModal from "./ProfileModal";
import Caregivers from "./Caregivers";
import {firestore} from "../../firebase";

const Header = () => {
	const {currentUser} = useContext(AuthContext);
	const [showAccountModal, setShowAccountModal] = useState(false);
	const [careGivers, setCareGivers] = useState([]);

	useEffect(() => {
		const userId = currentUser.uid;

		const careSnapshot = onSnapshot(doc(firestore, `users/${userId}/userData/care`), async careGiversDoc => {
			const newCareGivers = [];

			if (careGiversDoc.data()?.caregivers?.users) {
				Object.keys(careGiversDoc.data().caregivers.users).forEach(async key => {
					const careGiverDocRef = doc(firestore, `publicUsersInfo/${key}`);
					const careGiverDoc = await getDoc(careGiverDocRef);
					const careGiverData = careGiverDoc.data();
					console.log(careGiverData);

					newCareGivers.push(careGiverData.id);
				});
			}
			setCareGivers(newCareGivers);
		});

		return careSnapshot;
	}, [currentUser]);

	return (
		<header>
			{showAccountModal && <ProfileModal title="My Account" onClose={() => setShowAccountModal(false)} />}
			<div className="flex w-full justify-between px-3">
				<NavLink to="/app" className="flex items-center">
					<img style={{display: "inline"}} className="h-10 w-10 sm:h-20 sm:w-20" src={Logo} alt="logo" />
					<h5 className="text-l ml-3 inline font-bold text-black sm:text-2xl">Want2</h5>
					<h5 className="text-l inline font-bold text-blue-400 sm:text-2xl">Remember</h5>
				</NavLink>
				<div className="flex">
					<div className="mt-8">
						<Profile id={currentUser.uid} onClick={() => setShowAccountModal(true)} />
					</div>
					{careGivers.length > 0 && <Caregivers caregiverData={careGivers} />}
				</div>
			</div>
		</header>
	);
};

export default Header;
