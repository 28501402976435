import {React, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";

// Calendar Imports
import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file from react-date-range
import "react-date-range/dist/theme/default.css"; // theme css file for the calendar

// Images for the logos for Filters UI
import {FaTimes} from "react-icons/fa";
import filterIcon from "../../../../../resources/icons/Filters.png";

// The css file of the Filters UI
import classes from "./FiltersUI.module.css";
// Imported Data for Status Options
import ValueOptions from "../../../../../resources/ValueOptions";
import {memoryTypeIds, schemas} from "../../../../../resources/schemas";

const STATUS_DATA = ValueOptions.status;
const INITIAL_DATE_RANGE = {
	startDate: null,
	endDate: null,
	key: "selection",
};

// Main Filters function for mapping out all the memory and status filter options
const FilterPicker = ({displayData, data, selectedFilters, handleButtonClick}) => (
	<div className="flex flex-row flex-wrap px-5">
		{data &&
			data.map((item, index) => (
				<button
					type="button"
					key={item}
					className={
						selectedFilters?.includes(item)
							? `${classes.customButton} ${classes.customButtonClicked}`
							: classes.customButton
					}
					onClick={() => handleButtonClick(item)}
				>
					{displayData ? displayData[index] : item}
				</button>
			))}
	</div>
);

FilterPicker.propTypes = {
	displayData: PropTypes.arrayOf(PropTypes.string),
	data: PropTypes.arrayOf(PropTypes.string),
	selectedFilters: PropTypes.arrayOf(PropTypes.string),
	handleButtonClick: PropTypes.func,
};
FilterPicker.defaultProps = {
	displayData: undefined,
	data: [],
	selectedFilters: undefined,
	handleButtonClick: undefined,
};

const customButtonList = [
	{
		id: 1,
		label: "All Days",
		range: () => ({startDate: null, endDate: null}), // Custom logic for "All Days"
	},
	{
		id: 2,
		label: "Today",
		range: () => {
			const todayStart = new Date();
			const todayEnd = new Date();
			todayStart.setHours(0, 0, 0, 0);
			todayEnd.setHours(23, 59, 59, 59);
			return {startDate: todayStart, endDate: todayEnd};
		},
	},
	{
		id: 3,
		label: "Yesterday",
		range: () => {
			const yesterdayStart = new Date();
			const yesterdayEnd = new Date();
			yesterdayStart.setHours(0, 0, 0, 0);
			yesterdayEnd.setHours(23, 59, 59, 59);
			yesterdayStart.setDate(yesterdayStart.getDate() - 1);
			yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
			return {startDate: yesterdayStart, endDate: yesterdayEnd};
		},
	},
	{
		id: 4,
		label: "This Week",
		range: () => {
			const now = new Date();
			const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay()).setHours(
				0,
				0,
				0,
				0,
			);
			const endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (6 - now.getDay())).setHours(
				23,
				59,
				59,
				59,
			);
			return {startDate: startOfWeek, endDate: endOfWeek};
		},
	},
	{
		id: 5,
		label: "Last Week",
		range: () => {
			const now = new Date();
			const startOfLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7).setHours(
				0,
				0,
				0,
				0,
			);
			const endOfLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 1).setHours(
				23,
				59,
				59,
				59,
			);
			return {startDate: startOfLastWeek, endDate: endOfLastWeek};
		},
	},
	{
		id: 6,
		label: "This Month",
		range: () => {
			const now = new Date();
			const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).setHours(0, 0, 0, 0);
			const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).setHours(23, 59, 59, 59);
			return {startDate: startOfMonth, endDate: endOfMonth};
		},
	},
	{
		id: 7,
		label: "Last Month",
		range: () => {
			const now = new Date();
			const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1).setHours(0, 0, 0, 0);
			const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0).setHours(23, 59, 59, 59);
			return {startDate: startOfLastMonth, endDate: endOfLastMonth};
		},
	},
];

// Main function for the Filters UI
const FiltersUI = ({filterValues, setFilterValues, className = ""}) => {
	const [tempFilters, setTempFilters] = useState({});
	const [openFilters, setOpenFilters] = useState(false);
	// Handles the switching of tabs of "Memories", "Status", and "Date"
	const [toggleState, setToggleState] = useState(1);

	useEffect(() => {
		if (filterValues && !openFilters) {
			setTempFilters(cloneDeep(filterValues));
		}
	}, [filterValues, openFilters]);

	// Handles the popup of the filters container
	const handleFilterPopup = () => {
		setOpenFilters(!openFilters);
	};

	// Handles the selected Memory types
	const handleMemoryTypeClick = id => {
		const newTempFilters = tempFilters ? cloneDeep(tempFilters) : {};
		if (newTempFilters?.memoryTypes?.includes(id)) {
			newTempFilters.memoryTypes = newTempFilters.memoryTypes.filter(filterId => filterId !== id);
		} else {
			if (!newTempFilters?.memoryTypes) newTempFilters.memoryTypes = [];
			newTempFilters.memoryTypes.push(id);
		}
		setTempFilters(newTempFilters);
	};

	const handleStatusClick = id => {
		const newTempFilters = tempFilters ? cloneDeep(tempFilters) : {};
		if (newTempFilters?.status?.includes(id)) {
			newTempFilters.status = newTempFilters.status.filter(filterId => filterId !== id);
		} else {
			if (!newTempFilters?.status) newTempFilters.status = [];
			newTempFilters.status.push(id);
		}
		setTempFilters(newTempFilters);
	};

	// Handles the apply button for saving the selected filters
	const handleApply = () => {
		// Update the filter values in the parent component state
		setFilterValues(cloneDeep(tempFilters));
		setOpenFilters(false);
	};

	const handleButtonClick = customRange => {
		const {startDate, endDate} = customRange.range();
		const newTempFilters = tempFilters ? cloneDeep(tempFilters) : {};
		if (!newTempFilters?.dates) newTempFilters.dates = {};
		newTempFilters.dates = {startDate, endDate, key: "selection"};
		setTempFilters(newTempFilters);
	};

	const filterModal = () => (
		<div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center overflow-y-hidden border-2 bg-neutral-500 bg-opacity-25">
			<div className="flex flex-col rounded-lg bg-white p-1">
				<div className="flex flex-row justify-between">
					<p className="mt-1 text-xl font-bold">Select Filters</p>
					<button type="button" className="flex flex-col items-center justify-center" onClick={handleFilterPopup}>
						<FaTimes className="text-2xl" title="Close button" />
						<p className="font-bold">Close</p>
					</button>
				</div>
				<div className="mb-2 flex flex-row">
					<button
						type="button"
						className={toggleState === 1 ? `${classes.tabs} ${classes.activeTabs} ` : classes.tabs}
						onClick={() => setToggleState(1)}
					>
						Memories
					</button>
					<button
						type="button"
						className={toggleState === 2 ? `${classes.tabs} ${classes.activeTabs} ` : classes.tabs}
						onClick={() => setToggleState(2)}
					>
						Status
					</button>
					<button
						type="button"
						className={toggleState === 3 ? `${classes.tabs} ${classes.activeTabs} ` : classes.tabs}
						onClick={() => setToggleState(3)}
					>
						Date
					</button>
				</div>
				<div className="flex h-[400px] w-[580px] justify-center">
					{toggleState === 1 && (
						<div>
							<FilterPicker
								displayData={memoryTypeIds.map(id => schemas[id].displayName)}
								data={memoryTypeIds}
								selectedFilters={tempFilters?.memoryTypes}
								handleButtonClick={handleMemoryTypeClick}
							/>
						</div>
					)}
					{toggleState === 2 && (
						<div>
							<FilterPicker
								data={STATUS_DATA}
								selectedFilters={tempFilters?.status}
								handleButtonClick={handleStatusClick}
							/>
						</div>
					)}
					{toggleState === 3 && (
						<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
							<div style={{display: "flex", flexDirection: "column", alignItems: "center", marginRight: "70px"}}>
								{customButtonList.map(button => (
									<button
										key={button.id} // Use a unique identifier instead of array index
										type="button"
										onClick={() => handleButtonClick(button)}
										style={{marginBottom: "20px"}}
									>
										{button.label}
									</button>
								))}
							</div>
							<DateRange
								editableDateInputs
								onChange={item => {
									const {startDate, endDate} = item.selection;
									const tempStartDate = new Date(startDate);
									const tempEndDate = new Date(endDate);
									tempStartDate.setHours(0, 0, 0, 0);
									tempEndDate.setHours(23, 59, 59, 59);
									const temp = {
										startDate: tempStartDate,
										endDate: tempEndDate,
										key: "selection",
									};
									const newTempFilters = tempFilters ? cloneDeep(tempFilters) : {};
									if (!newTempFilters?.dates) newTempFilters.dates = {};
									newTempFilters.dates = temp;
									setTempFilters(newTempFilters);
								}}
								moveRangeOnFirstSelection={false}
								ranges={[tempFilters?.dates ?? INITIAL_DATE_RANGE]}
							/>
						</div>
					)}
				</div>
				<div className="flex flex-row-reverse py-2">
					<button type="submit" className="font-bold text-[#60aef5]" onClick={handleApply}>
						Apply
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<div className={className}>
			<button
				type="button"
				className="flex flex-col items-center rounded-lg bg-gray-200 p-1 text-sm font-bold hover:bg-gray-100"
				onClick={() => {
					setOpenFilters(true);
				}}
			>
				<img className="h-10 w-10" src={filterIcon} alt="Filter" />
				<p>Filters</p>
			</button>

			{openFilters && filterModal()}
		</div>
	);
};
FiltersUI.propTypes = {
	filterValues: PropTypes.shape({
		memoryTypes: PropTypes.arrayOf(PropTypes.string),
		status: PropTypes.arrayOf(PropTypes.string),
		date: PropTypes.shape({
			startDate: PropTypes.oneOfType([
				PropTypes.instanceOf(Date),
				PropTypes.shape({
					nanoseconds: PropTypes.number,
					seconds: PropTypes.number,
				}),
			]),
			endDate: PropTypes.oneOfType([
				PropTypes.instanceOf(Date),
				PropTypes.shape({
					nanoseconds: PropTypes.number,
					seconds: PropTypes.number,
				}),
			]),
		}),
	}),
	setFilterValues: PropTypes.func,
	className: PropTypes.string,
};
FiltersUI.defaultProps = {
	filterValues: undefined,
	setFilterValues: undefined,
	className: "",
};

export default FiltersUI;
