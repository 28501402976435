import React from "react";
import PropTypes from "prop-types";
import DatePicker from "../../Components/DatePicker";
import OneLineTextPicker from "../../Components/OneLineTextPicker";
import StatusPicker from "./StatusPicker";

import ValueOptions from "../../../../../../resources/ValueOptions";

const AddPayments = ({value = {}, onChange, sourceLabel, vendor, dueDate}) => {
	const updateTempValue = (property, newValue) => {
		const newObj = {...value, [property]: newValue};
		onChange(newObj);
	};

	return (
		<div>
			<OneLineTextPicker
				inputLabel={sourceLabel}
				inputPlaceholder="Enter Name"
				textInput={value.source ?? ""}
				setTextInput={input => updateTempValue("source", input)}
			/>
			{vendor && (
				<OneLineTextPicker
					inputLabel="Vendor"
					inputPlaceholder="Enter vendor"
					textInput={value.vendor ?? ""}
					setTextInput={input => updateTempValue("vendor", input)}
				/>
			)}
			<DatePicker
				label="Renewal Date"
				date={value.renewalDate}
				setDate={date => updateTempValue("renewalDate", date)}
			/>
			{dueDate && (
				<DatePicker label="Due Date" date={value.dueDate} setDate={date => updateTempValue("dueDate", date)} />
			)}
			<StatusPicker
				title="Reoccurrence Type"
				statuses={ValueOptions.billTypeRecurrence}
				selectedStatus={value.reoccurrenceType ?? ""}
				onSelectStatus={selection => updateTempValue("reoccurrenceType", selection)}
			/>
			<OneLineTextPicker
				inputLabel="Amount"
				inputPlaceholder="USD"
				textInput={value.amount ?? ""}
				setTextInput={input => updateTempValue("amount", input)}
				inputType="number"
			/>
			<OneLineTextPicker
				inputLabel="Reason"
				inputPlaceholder="Enter reason"
				textInput={value.reason ?? ""}
				setTextInput={input => updateTempValue("reason", input)}
			/>
		</div>
	);
};

AddPayments.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
	sourceLabel: PropTypes.string,
	vendor: PropTypes.bool,
	dueDate: PropTypes.bool,
};

AddPayments.defaultProps = {
	value: {},
	onChange: undefined,
	sourceLabel: "Source",
	vendor: false,
	dueDate: false,
};

export default AddPayments;
