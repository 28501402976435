import React, {useContext, useState, useEffect} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {AuthContext} from "../../AuthProvider";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";

const ProtectedRoute = () => {
	const {currentUser} = useContext(AuthContext);
	const {pathname, search} = useLocation();

	const [canRedirect, setCanRedirect] = useState(false);

	useEffect(() => {
		const redirectTimeout = setTimeout(() => {
			setCanRedirect(true);
		}, 200);
		return () => clearTimeout(redirectTimeout);
	}, [currentUser]);

	if (currentUser) {
		return (
			<div className="flex h-full w-full flex-col">
				<Header />
				<div className="flex flex-col-reverse grow sm:flex-row">
					<div className="flex sm:min-w-fit">
						<SidebarMenu />
					</div>
					<main className="grow">
						<Outlet />
					</main>
				</div>
			</div>
		);
	}
	// navigates to memory in case memory is public
	if (search.includes("memoryId")) {
		return (
			<main className="grow">
				<Outlet />
			</main>
		);
	}
	// delays redirect so if user is still logged in but just refreshed screen, gives auth enough time to reload credentials
	if (canRedirect)
		return <Navigate to={`/SignIn${currentUser ? "" : `/?path=${pathname}`}${search ?? `/${search}`}`} />;
	return null;
};

export default ProtectedRoute;
