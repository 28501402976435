import {isArray, cloneDeep, mergeWith, isEqual} from "lodash";
import {fieldTypes} from "./schemas";

const getAndTranslateDefaultMemoryValues = schema => {
	if (schema && schema.fields && schema.defaults) {
		const schemaData = cloneDeep(schema);
		const {fields} = schemaData;

		if (schemaData.defaults.memory) {
			const memoryDefaults = schemaData.defaults.memory;
			const newMemoryDefaults = {};
			const keys = Object.keys(memoryDefaults);

			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				const value = memoryDefaults[key];

				if (fields[key] && fields[key].type) {
					switch (fields[key].type) {
						case fieldTypes.date:
							switch (value) {
								case "now": {
									const now = new Date();
									newMemoryDefaults[key] = new Date(now.getFullYear(), now.getMonth(), now.getDate());
									break;
								}
								default:
									newMemoryDefaults[key] = value;
									break;
							}
							break;
						case fieldTypes.time:
							switch (value) {
								case "now":
									newMemoryDefaults[key] = new Date();
									break;
								default:
									newMemoryDefaults[key] = value;
									break;
							}
							break;
						default:
							newMemoryDefaults[key] = value;
							break;
					}
				}
			}
			return {...schemaData.defaults, memory: {...newMemoryDefaults}};
		}
		return schemaData.defaults;
	}
	return {};
};

// functions for removing all empty values from an object
// from Rubén Vega https://stackoverflow.com/a/66891697
const isEmpty = obj => {
	if (obj === "" || obj === null || JSON.stringify(obj) === "{}" || JSON.stringify(obj) === "[]" || obj === undefined) {
		return true;
	}
	return false;
};

const removeAllEmpty = (obj, keysToIgnore = [], removeFalseValues = false) => {
	if (typeof obj !== "object") {
		return obj;
	}
	const objCopy = cloneDeep(obj);
	const oKeys = Object.keys(objCopy);
	for (let j = 0; j < oKeys.length; j++) {
		const p = oKeys[j];
		if (!keysToIgnore.includes(p)) {
			switch (typeof objCopy[p]) {
				case "object":
					// check if value is a date since apparently they are considered objects
					if (objCopy[p] && Object.prototype.toString.call(objCopy[p]) === "[object Date]") {
						if (isNaN(objCopy[p])) delete objCopy[p];
					} else if (isArray(objCopy[p])) {
						for (let i = 0; i < objCopy[p].length; i++) {
							objCopy[p][i] = removeAllEmpty(objCopy[p][i], [], removeFalseValues);
							if (isEmpty(objCopy[p][i])) {
								objCopy[p].splice(i, 1);
								i -= 1;
							}
						}
						if (objCopy[p].length === 0) {
							if (isArray(objCopy)) {
								objCopy.splice(parseInt(p, 10), 1);
								j -= 1;
							} else {
								delete objCopy[p];
							}
						}
					} else if (isEmpty(objCopy[p])) {
						delete objCopy[p];
					} else {
						objCopy[p] = removeAllEmpty(objCopy[p], [], removeFalseValues);
						if (isEmpty(objCopy[p])) {
							delete objCopy[p];
						}
					}
					break;
				case "boolean":
					if (removeFalseValues && !objCopy[p]) {
						delete objCopy[p];
					}
					break;
				default:
					if (typeof objCopy[p] === "string") {
						objCopy[p] = objCopy[p].trim();
					}
					if (isEmpty(objCopy[p])) {
						delete objCopy[p];
					}
					break;
			}
		}
	}
	if (Object.keys(objCopy).length === 0) {
		return {};
	}
	return objCopy;
};

const objFlatten = (obj = {}, keysToFlatten = []) => {
	const tempObj = cloneDeep(obj);
	const objKeys = Object.keys(obj);
	const newTempObj = {};

	for (let i = 0; i < objKeys.length; i++) {
		const currKey = objKeys[i];
		const currValue = tempObj[currKey];

		if (keysToFlatten.includes(currKey) && typeof currValue === "object" && !isArray(currValue)) {
			const level1keys = Object.keys(currValue);
			for (let j = 0; j < level1keys.length; j++) {
				const currL1Key = level1keys[j];
				const currL1Value = currValue[currL1Key];
				newTempObj[`${currKey}.${currL1Key}`] = currL1Value;
			}
		} else {
			newTempObj[currKey] = currValue;
		}
	}

	return newTempObj;
};

// will do a deep search through an object and apply the customizer
// function to each value found that is not an object
const deepIterate = (src, customizer = () => {}) => {
	if (src !== null && src !== undefined && typeof src === "object") {
		const tempSrc = cloneDeep(src);
		if (isArray(tempSrc)) {
			for (let i = 0; i < tempSrc.length; i++) {
				tempSrc[i] = deepIterate(tempSrc[i], customizer);
			}
		} else {
			const srcKeys = Object.keys(tempSrc);
			for (let i = 0; i < srcKeys.length; i++) {
				const currKey = srcKeys[i];
				tempSrc[currKey] = deepIterate(tempSrc[currKey], customizer);
			}
		}
		return tempSrc;
	}
	return customizer(src);
};

const mergeArraysAndPreserveOrder = (arrays = [[]]) => {
	const valueMap1D = [];
	const valueMap2D = [[]];

	for (let i = 0; i < arrays[0].length; i++) {
		const currValue = arrays[0][i];
		const currentValueMap1D = valueMap1D.length;
		valueMap1D.push([currValue, []]);
		valueMap2D[0].push(currentValueMap1D);
	}

	for (let i = 1; i < arrays.length; i++) {
		const alreadyUsed = [];
		const currArray = arrays[i];
		valueMap2D.push([]);

		for (let j = 0; j < currArray.length; j++) {
			const currValue = currArray[j];
			const foundIndex = valueMap1D.findIndex(
				(value, index) => isEqual(value[0], currValue) && !alreadyUsed.includes(index),
			);
			if (foundIndex >= 0) {
				alreadyUsed.push(foundIndex);
				valueMap2D[i].push(foundIndex);
			} else {
				const newIndex = valueMap1D.length;
				valueMap1D.push([currValue, []]);
				alreadyUsed.push(newIndex);
				valueMap2D[i].push(newIndex);
			}
		}
	}

	for (let i = 0; i < valueMap2D.length; i++) {
		const currArray = valueMap2D[i];

		for (let j = 0; j < currArray.length - 1; j++) {
			const currIndex = currArray[j];
			const nextIndex = currArray[j + 1];
			if (!valueMap1D[currIndex][1].includes(nextIndex)) {
				valueMap1D[currIndex][1].push(nextIndex);
			}
		}
	}

	const output = [];
	for (let i = 0; i < valueMap1D.length; i++) {
		const stack = [];
		getRelativeOrder(i, valueMap1D, output, stack);
	}

	return output.reverse().map(value => valueMap1D[value][0]);
};

const getRelativeOrder = (destIndex, ogArray = [[]], destArray = [], stack = []) => {
	const indexArr = ogArray[destIndex][1];
	stack.push(destIndex);
	for (let i = 0; i < indexArr.length; i++) {
		const nextIndex = indexArr[i];
		// to avoid circular order
		if (!stack.includes(nextIndex)) getRelativeOrder(nextIndex, ogArray, destArray, stack);
	}
	stack.pop();
	if (!destArray.includes(destIndex)) destArray.push(destIndex);
};

const mergeCustomizer = (objValue, srcValue) => {
	if (isArray(objValue)) {
		return mergeArraysAndPreserveOrder([objValue, srcValue]);
	}
	return undefined;
};

// can be used with any js objects but it was made with the intention of merging schema
const mergeSchema = (schema1 = {}, schema2 = {}) => mergeWith(cloneDeep(schema1), cloneDeep(schema2), mergeCustomizer);

const versionGreaterThanOrEqual = (a, b) => {
	const aSplit = a
		.split(/\D/)
		.map(value => parseInt(value.slice(0, 3), 10))
		.filter(v => !isNaN(v));
	const bSplit = b
		.split(/\D/)
		.map(value => parseInt(value.slice(0, 3), 10))
		.filter(v => !isNaN(v));
	let aGreater = true;

	// makes both aSplit and bSplit the same size by appending 0s to the shorter array
	for (let i = aSplit.length; i < bSplit.length; i++) {
		aSplit.push(0);
	}
	for (let i = bSplit.length; i < aSplit.length; i++) {
		bSplit.push(0);
	}

	// console.log('aSplit:', aSplit);
	// console.log('bSplit:', bSplit);

	for (let i = 0; i < aSplit.length; i++) {
		const av = aSplit[i];
		const bv = bSplit[i];

		if (av > bv) {
			aGreater = true;
			break;
		} else if (av < bv) {
			aGreater = false;
			break;
		}
	}

	return aGreater;
};

const getContactName = contactInfo => {
	if (
		Boolean(contactInfo.prefix) ||
		Boolean(contactInfo.givenName) ||
		Boolean(contactInfo.middleName) ||
		Boolean(contactInfo.familyName) ||
		Boolean(contactInfo.suffix)
	) {
		const fullNameArr = [contactInfo.prefix, contactInfo.givenName, contactInfo.middleName, contactInfo.familyName];
		const fullName = fullNameArr.filter(value => Boolean(value)).join(" ");
		return contactInfo.suffix ? `${fullName}, ${contactInfo.suffix}` : fullName;
	}
	if (contactInfo.nickname) return contactInfo.nickname;
	if (contactInfo.jobTitle) return contactInfo.jobTitle;
	if (contactInfo.department) return contactInfo.department;
	if (contactInfo.company) return contactInfo.company;
	if (contactInfo.phoneNumbers && isArray(contactInfo.phoneNumbers)) {
		const pNumbers = [...contactInfo.phoneNumbers];
		for (let i = 0; i < pNumbers.length; i++) {
			const value = pNumbers[i];
			const {number} = value;
			if (number) {
				return number;
			}
		}
	}
	if (contactInfo.emailAddresses && isArray(contactInfo.emailAddresses)) {
		const emails = [...contactInfo.emailAddresses];
		for (let i = 0; i < emails.length; i++) {
			const value = emails[i];
			console.log("value", value);
			const {email} = value;
			if (email) {
				return email;
			}
		}
	}
	if (contactInfo.relationship) return contactInfo.relationship;

	return "";
};

const getContactInitials = name => {
	let initials = "";
	if (name && typeof name === "string" && Boolean(name)) {
		let isPhoneNumber = false;
		const filteredName = name.replace(/[\W\d\s]/g, "");
		if (!filteredName) isPhoneNumber = true;
		if (isPhoneNumber) initials = "#";
		else {
			const initialsArr = name
				.split(" ")
				.filter(value => Boolean(value))
				.map(value => value[0]);
			// for (let i = 0; i < initialsArr.length; i++) {
			// 	initials += initialsArr[i];
			// }
			if (initialsArr.length === 1) {
				[initials] = initialsArr; // sets initials to initialsArr[0]
			} else initials = `${initialsArr[0]}${initialsArr[initialsArr.length - 1]}`;
		}
	}
	return initials;
};

const combineDateAndTime = (theDay, theTime) => {
	// shouldn't directly change the values for theDay
	const tempDay = theDay.seconds ? new Date(theDay.seconds * 1000) : new Date(theDay);
	const tempTime = theTime.seconds ? new Date(theTime.seconds * 1000) : new Date(theTime);
	const offSet = tempDay.getTimezoneOffset();
	if (tempDay.getUTCHours() < offSet / 60) {
		tempDay.setDate(tempDay.getDate() - 1);
	}

	const extractedDay = tempDay.toISOString().split("T")[0];
	const extractedTime = tempTime.toISOString().split("T")[1];

	const combinedDate = new Date(`${extractedDay}T${extractedTime}`);

	// if UTC hour+min is so late that it spilled over to next day, increments UTC day value
	if (combinedDate.getUTCHours() * 60 + combinedDate.getUTCMinutes() - offSet < 0) {
		combinedDate.setDate(combinedDate.getDate() + 1);
	}

	return combinedDate;
};

const formatBytes = (bytes, decimals = 2) => {
	if (!+bytes) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export {
	removeAllEmpty,
	objFlatten,
	getAndTranslateDefaultMemoryValues,
	mergeArraysAndPreserveOrder,
	mergeSchema,
	versionGreaterThanOrEqual,
	deepIterate,
	getContactName,
	getContactInitials,
	combineDateAndTime,
	formatBytes,
};
