/* eslint-disable react/no-array-index-key */
import React, {cloneElement} from "react";
import PropTypes from "prop-types";
import style from "./DynamicList.module.css";

const DynamicList = ({
	onChange,
	children,
	dynamicLabel,
	value = [],
	defaultNewValue = {},
	className = "",
	itemName = "Item",
	childProps,
}) => {
	const finalClass = `${style.dynamicList} ${className}`;
	const childrenOutput = Array.isArray(children) ? children : [children];

	const handleChange = (newValue, index) => {
		const newOutput = [...value];
		newOutput[index] = newValue;

		onChange(newOutput);
	};

	const handleRemove = index => {
		const newList = [];

		for (let i = 0; i < value.length; i++) {
			if (index !== i) {
				newList.push(value[i]);
			}
		}

		onChange(newList);
	};

	const handleAdd = () => {
		onChange([...value, defaultNewValue]);
	};

	const content = Array.from(value).map((current, index) => {
		const clonedProps = {
			value: current,
			key: index,
			id: index,
			onChange: event => {
				handleChange(event.target ? event.target.value : event, index);
			},
			...childProps,
		};

		if (dynamicLabel) {
			clonedProps.label = `${dynamicLabel} ${index}`;
		}
		const clonedElement = cloneElement(childrenOutput[0], clonedProps);

		const headerContent = (
			<header className={style.header}>
				<h2>{`${itemName} ${index + 1}`}</h2>
				<button
					type="button"
					className={style.removeButton}
					onClick={() => {
						handleRemove(index);
					}}
				>
					Remove
				</button>
			</header>
		);

		return (
			<li className={style.item} key={index}>
				{headerContent}
				{clonedElement}
			</li>
		);
	});

	return (
		<div className={finalClass}>
			<ul>{content}</ul>
			<button type="button" onClick={handleAdd}>
				+ Add {itemName}
			</button>
		</div>
	);
};

DynamicList.propTypes = {
	onChange: PropTypes.func,
	children: PropTypes.any,
	dynamicLabel: PropTypes.string,
	value: PropTypes.array,
	defaultNewValue: PropTypes.object,
	className: PropTypes.string,
	itemName: PropTypes.string,
	childProps: PropTypes.object,
};
DynamicList.defaultProps = {
	onChange: undefined,
	children: undefined,
	dynamicLabel: undefined,
	value: [],
	defaultNewValue: {},
	className: "",
	itemName: "Item",
	childProps: undefined,
};

export default DynamicList;
