import React from "react";
import {NavLink, Outlet} from "react-router-dom";
import Logo from "../../../../../resources/images/01.png";

const HEADER_BUTTON_STYLE = "font-bold text-black text-center py-1 px-2 sm:px-5 rounded-xl hover:bg-white";

const HomeScreensParent = () => (
	<div className="flex h-full w-full flex-col bg-white">
		<div className="flex w-full items-center justify-between rounded-b-3xl bg-blue-50 px-2 py-2 text-xs sm:px-12 sm:text-base">
			<NavLink to="/" className="flex items-center px-2 py-1 sm:px-5">
				<img className="hidden h-10 w-10 sm:block sm:h-14 sm:w-14" src={Logo} alt="logo" />
				<div className="flex flex-wrap">
					<h5 className="ml-3 font-bold text-black">Want2</h5>
					<h5 className="font-bold text-blue-400">Remember</h5>
				</div>
			</NavLink>
			<NavLink to="/about">
				<h5 className={HEADER_BUTTON_STYLE}>About Us</h5>
			</NavLink>
			<NavLink to="/SignIn">
				<h5 className={HEADER_BUTTON_STYLE}>Log In</h5>
			</NavLink>
			<NavLink to="/SignUp">
				<h5 className={HEADER_BUTTON_STYLE}>Sign Up</h5>
			</NavLink>
		</div>
		<div className="mt-8 h-full w-full pb-10">
			<Outlet />
		</div>
	</div>
);

export default HomeScreensParent;
