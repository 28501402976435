import React from "react";
import PropTypes from "prop-types";
import {AsYouType} from "libphonenumber-js";
import OneLineTextPicker from "./OneLineTextPicker";

const PhonePicker = ({inputLabel, inputPlaceholder, phoneInput, setPhoneInput}) => (
	<OneLineTextPicker
		inputLabel={inputLabel}
		inputPlaceholder={inputPlaceholder}
		textInput={phoneInput}
		setTextInput={text => {
			if (phoneInput.length > 1 && phoneInput.length - text.length === 1) {
				let r = phoneInput.length - 1;
				let deletedNumber = false;
				for (let i = r; i >= 0; i--) {
					if (phoneInput[i] >= "0" && phoneInput[i] <= "9") {
						r = i;
						if (!deletedNumber) deletedNumber = true;
						else break;
					}
				}
				setPhoneInput(new AsYouType("US").input(phoneInput.slice(0, r + 1)));
			} else {
				setPhoneInput(new AsYouType("US").input(text));
			}
		}}
		inputType="tel"
	/>
);

PhonePicker.propTypes = {
	inputLabel: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	phoneInput: PropTypes.string,
	setPhoneInput: PropTypes.func,
};
PhonePicker.defaultProps = {
	inputLabel: undefined,
	inputPlaceholder: undefined,
	phoneInput: undefined,
	setPhoneInput: undefined,
};

export default PhonePicker;
