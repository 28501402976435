import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import {Popover, Whisper} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {getDownloadURL, ref} from "firebase/storage";
import {doc, getDoc} from "firebase/firestore";

import ProfileIcon from "../../../../../../resources/icons/Profile.png";
import {AuthContext} from "../../../AuthProvider";
import {firestore, storage} from "../../../firebase";

const QuickLookViewers = ({viewerIds}) => {
	const {currentUser} = useContext(AuthContext);
	const [viewerURLs, setViewerURLs] = useState({});
	const [viewerData, setViewerData] = useState({});
	const [defaultViewer, setDefaultViewer] = useState(null);

	useEffect(() => {
		if (Array.isArray(viewerIds)) {
			for (let i = 0; i < viewerIds.length; i++) {
				const currId = viewerIds[i];
				const viewerDocRef = doc(firestore, `publicUsersInfo/${currId}`);
				getDoc(viewerDocRef).then(docSnap => {
					if (docSnap.exists()) {
						const {profileImage} = docSnap.data();
						if (profileImage.thumbnail) {
							const {storagePath} = profileImage.thumbnail;
							if (storagePath) {
								const storageRef = ref(storage, storagePath);
								getDownloadURL(storageRef)
									.then(url => {
										setViewerURLs(prevValue => ({...prevValue, [currId]: url}));
									})
									.catch(e => {
										console.log("error:", e);
										setViewerURLs(prevValue => ({...prevValue, [currId]: ProfileIcon}));
									});
							}
						} else {
							setViewerURLs(prevValue => ({...prevValue, [currId]: ProfileIcon}));
						}
						setViewerData(prevValue => ({...prevValue, [currId]: docSnap.data()}));
					} else {
						console.log("No public user info doc found for user");
						setViewerURLs(prevValue => ({...prevValue, [currId]: ProfileIcon}));
					}
				});
			}
		}
	}, [viewerIds]);

	useEffect(() => {
		const firstValidViewer = viewerIds.find(id => id !== currentUser.uid && viewerURLs[id]);
		if (firstValidViewer) {
			setDefaultViewer(firstValidViewer);
		} else setDefaultViewer(null);
	}, [viewerURLs]);

	const speaker = (
		<Popover>
			{viewerIds
				.filter(id => id !== currentUser.uid && viewerURLs[id])
				.map(viewerId => {
					let userName = "";
					if (viewerData[viewerId]) {
						const {firstName, lastName} = viewerData[viewerId];
						const nameVar = [firstName, lastName];
						userName += nameVar.filter(x => x).join(" ");
					}
					return (
						<div key={viewerId} className="flex flex-row items-center">
							<img
								key={viewerId}
								className="h-8 w-8 rounded-full md:h-12 md:w-12"
								src={viewerURLs[viewerId]}
								alt="viewer pic"
							/>
							<p className="ml-2 font-semibold text-gray-400">{userName}</p>
						</div>
					);
				})}
		</Popover>
	);

	if (defaultViewer) {
		return (
			<div className="flex flex-row items-center justify-center">
				{viewerIds.filter(id => id !== currentUser.uid && viewerURLs[id]).length > 0 && (
					<Whisper placement="top" speaker={speaker} trigger="hover" controlId="control-id-hover">
						<div className="flex flex-row items-center">
							<img className="h-6 w-6 rounded-full md:h-10 md:w-10" src={viewerURLs[defaultViewer]} alt="viewer pic" />
							{viewerIds.filter(viewerId => viewerId !== currentUser.uid && viewerURLs[viewerId]).length > 1 && (
								<p className="font-semibold text-gray-400">{`+ ${
									viewerIds.filter(id => id !== currentUser.uid && viewerURLs[id]).length - 1
								}`}</p>
							)}
						</div>
					</Whisper>
				)}
			</div>
		);
	}
	return null;
};

QuickLookViewers.propTypes = {
	viewerIds: PropTypes.arrayOf(PropTypes.string),
};
QuickLookViewers.defaultProps = {
	viewerIds: undefined,
};

export default QuickLookViewers;
