import React from "react";
import PropTypes from "prop-types";
import Colors from "../../../../../../resources/Colors";

const DateDisplay = ({label, date}) => {
	let formattedDate = "";

	if (date instanceof Date) {
		formattedDate = date.toLocaleString();
	} else if (date && typeof date.toDate === "function") {
		formattedDate = date.toDate().toLocaleString();
	}

	return (
		<div className="m-10 my-2 flex justify-between p-4">
			<div>
				<p style={{color: Colors.lightGrey}}>{label}</p>
			</div>
			<div>
				<p>{formattedDate}</p>
			</div>
		</div>
	);
};

// Define PropTypes for DateDisplay
DateDisplay.propTypes = {
	label: PropTypes.string.isRequired,
	date: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.shape({
			nanoseconds: PropTypes.number,
			seconds: PropTypes.number,
		}), // Firestore timestamp
	]).isRequired,
};

export default DateDisplay;
