import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {combineDateAndTime} from "../../../../../../resources/commonUtilities";

const QuickLookTimeAgo = ({dateSchema, timeSchema, memData}) => {
	const [memoryTime, setMemoryTime] = useState(null);
	const [memoryDate, setMemoryDate] = useState(null);
	const [timeAgoMessage, setTimeAgoMessage] = useState("");

	useEffect(() => {
		let displayDate = null;

		// Extracts date from dateSchema
		if (dateSchema.length > 0) {
			for (let i = 0; i < dateSchema.length; i++) {
				const currDateId = dateSchema[i];
				if (memData.memory && memData.memory[currDateId]) {
					displayDate = new Date(memData.memory[currDateId].seconds * 1000);
					setMemoryDate(displayDate);
					break;
				}
			}
		}

		// Extracts time from timeSchema and combine with date
		if (displayDate && timeSchema.length > 0) {
			for (let i = 0; i < timeSchema.length; i++) {
				const currTimeId = timeSchema[i];
				if (memData.memory && memData.memory[currTimeId]) {
					const memoryTimes = new Date(memData.memory[currTimeId].seconds * 1000);
					const combinedDateTime = combineDateAndTime(displayDate, memoryTimes);
					setMemoryTime(combinedDateTime);
					break;
				}
			}
		}
	}, [memData, dateSchema, timeSchema]);

	useEffect(() => {
		let message = "";
		if (memoryDate) {
			const now = dayjs();
			const startOfToday = now.startOf("day");
			const tempMemoryDate = dayjs(memoryDate).startOf("day");
			const isDifferentDay = tempMemoryDate.isBefore(startOfToday);

			if (isDifferentDay) {
				const isNextDay = tempMemoryDate.add(1, "day").isSame(startOfToday, "day");

				if (isNextDay) {
					message = "a day ago";
				} else {
					message = dayjs(memoryDate).fromNow();
				}
			} else {
				message = memoryTime ? dayjs(memoryTime).format("LT") : "";
			}
		}
		setTimeAgoMessage(message);
	}, [memoryDate, memoryTime]);

	return timeAgoMessage ? <p className="text-sm font-semibold text-gray-400">{timeAgoMessage}</p> : null;
};

QuickLookTimeAgo.propTypes = {
	dateSchema: PropTypes.arrayOf(PropTypes.string),
	timeSchema: PropTypes.arrayOf(PropTypes.string),
	memData: PropTypes.object,
};
QuickLookTimeAgo.defaultProps = {
	dateSchema: [],
	timeSchema: [],
	memData: {},
};

export default QuickLookTimeAgo;
