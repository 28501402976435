import React, {useState, useEffect, useContext} from "react";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";

import {signInWithEmailAndPassword} from "firebase/auth";

import {AuthContext} from "../AuthProvider";
import {auth} from "../firebase";
import TextInput from "./Components/TextInput";
import Logo from "../../../../resources/images/01.png";

const SignIn = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	// Custom useAuth Hook
	const {currentUser} = useContext(AuthContext);
	const ogPath = searchParams.get("path") ?? null;

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	useEffect(() => {
		if (currentUser) navigate("/app");
	}, [currentUser]);

	const signIn = async (inputEmail, inputPassword, altPath = null) => {
		if (
			// Check for any missing fields
			inputEmail === "" ||
			inputPassword === ""
		) {
			alert("Please fill out all required fields");
		} else if (
			// Check if the email is valid
			!inputEmail
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				)
		) {
			alert("Please enter a valid email address");
		} else {
			await signInWithEmailAndPassword(auth, inputEmail, inputPassword)
				.then(user => {
					console.log(user);
					console.log("Signed in successfully");
					navigate(altPath ?? "/app");
					// alert("login successfully");
				})
				.catch(error => {
					alert(error.message);
				});
		}
	};

	const handleSubmit = async event => {
		event.preventDefault();
		await signIn(email, password, ogPath);
	};

	return (
		<div className="m-5 flex">
			<div className="m-auto font-extrabold">
				<center className="m-4">
					<NavLink to="/">
						<img style={{display: "inine"}} className="h-20 w-20" src={Logo} alt="logo" />
						<p className="text-3xl text-blue-300">Want2Remember</p>
					</NavLink>
					<p className="text-2xl text-blue-900">Sign In</p>
				</center>
				<form onSubmit={handleSubmit}>
					<div className="flex justify-center">
						<div className="rounded-md border-2 border-solid p-4">
							<div className="mx-2 my-4">
								<TextInput label="Email Address" value={email} onChange={setEmail} />
							</div>
							<div className="mx-2">
								<TextInput type="password" label="Password" value={password} onChange={setPassword} />
							</div>
							<div className="m-2 flex justify-end">
								<button
									className="rounded-lg text-xs font-semibold text-blue-400 hover:text-blue-700 active:text-blue-500"
									onClick={() => navigate("/ResetPassword")}
									type="button"
								>
									Forgot password?
								</button>
							</div>
							<div className="my-6 space-y-2 rounded-md px-2">
								<button
									className="w-96 rounded-md bg-blue-400 p-3 font-semibold text-white hover:bg-blue-700 focus:outline-none focus:ring focus:ring-violet-300 active:bg-blue-500"
									type="submit"
								>
									Login
								</button>
							</div>
						</div>
					</div>
				</form>
				<div className="my-6 flex items-center justify-center rounded-md border-2 border-solid px-2 py-4">
					<p className="px-2 font-semibold">Don&#39;t have an account?</p>
					<button
						type="button"
						className="rounded-lg font-semibold text-blue-400 hover:text-blue-700 active:text-blue-500"
						onClick={() => navigate("/SignUp")}
					>
						Sign Up.
					</button>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
