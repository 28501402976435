// Ryan and Marco
import React, {useState, useEffect, useRef, useContext} from "react";
import PropTypes from "prop-types";
import {VariableSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {query, where, orderBy, collection, onSnapshot} from "firebase/firestore";

import QuickLookCard from "./QuickLook";
import {AuthContext} from "../../AuthProvider";
import {firestore} from "../../firebase";
import {checkMemoryFilters} from "../../../../../resources/checkFilters";

const DEFAULT_HEIGHT = 300;

const MemoryQuickLooks = ({data, index, style}) => {
	const {filteredMemories, listRef, memoryHeights} = data;
	const setMemoryHeight = (i, size) => {
		listRef.current.resetAfterIndex(0);
		memoryHeights.current = {...memoryHeights.current, [i]: size};
	};
	const memoryRef = useRef(null);

	useEffect(() => {
		if (memoryRef.current) {
			const resizeObserver = new ResizeObserver(() => {
				if (memoryRef?.current?.clientHeight) {
					setMemoryHeight(index, memoryRef.current.clientHeight);
				}
			});
			resizeObserver.observe(memoryRef.current);
			return () => resizeObserver.disconnect();
		}
		return undefined;
	}, [memoryRef]);

	return (
		<div key={index} style={style}>
			<div ref={memoryRef} className="flex justify-center pb-4">
				<QuickLookCard memData={filteredMemories[index]} />
			</div>
		</div>
	);
};

MemoryQuickLooks.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number,
	style: PropTypes.object,
};
MemoryQuickLooks.defaultProps = {
	data: undefined,
	index: undefined,
	style: undefined,
};

const QuickLookUI = ({filterValues}) => {
	// References
	const listRef = useRef({});
	const memoryHeights = useRef({});

	const {currentUser} = useContext(AuthContext);
	const [memories, setMemories] = useState([]);
	const [currentIndex, setCurrentIndex] = useState();
	const [filteredMemories, setFilteredMemories] = useState([]);

	// Fetching memories from Firestore
	useEffect(() => {
		const q = query(
			collection(firestore, "memories"),
			where("viewers", "array-contains-any", [currentUser.uid]),
			orderBy("sortDate", "desc"),
		);
		const unsubscribe = onSnapshot(q, querySnapshot => {
			const tempMemories = [];
			querySnapshot.forEach(doc => {
				tempMemories.push({id: doc.id, ...doc.data()});
			});
			setMemories(tempMemories);

			const today = new Date();
			today.setHours(0, 0, 0, 0);
			const todayTime = today.getTime();

			let closestIndex = -1;
			let smallestDiff = Infinity;

			tempMemories.forEach((memory, index) => {
				const memoryDate = new Date(memory.sortDate.seconds * 1000);
				memoryDate.setHours(0, 0, 0, 0);
				const difference = Math.abs(memoryDate.getTime() - todayTime);

				if (difference < smallestDiff) {
					smallestDiff = difference;
					closestIndex = index;
				}
			});
			setCurrentIndex(closestIndex >= 0 ? closestIndex : null);
		});

		return unsubscribe; // Cleanup the subscription
	}, [currentUser.uid]);

	useEffect(() => {
		console.log("memories is repeating");
	}, [memories]);

	useEffect(() => {
		console.log("filterValues is repeating");
	}, [filterValues]);

	useEffect(() => {
		const {users, category, memoryTypes, status, dates, searchQuery} = filterValues;
		const temp = memories.filter(item =>
			checkMemoryFilters(
				item,
				users ?? {[currentUser.uid]: true},
				category ?? "",
				memoryTypes ?? [],
				status ?? [],
				dates ?? {},
				searchQuery ?? "",
			),
		);

		setFilteredMemories(temp);
	}, [filterValues, memories]);

	useEffect(() => {
		if (typeof currentIndex === "number" && listRef.current) {
			requestAnimationFrame(() => {
				listRef.current.scrollToItem(currentIndex);
			});
		}
	}, [currentIndex]);

	const memoryHeight = index => memoryHeights.current[index] ?? DEFAULT_HEIGHT;

	return (
		<AutoSizer>
			{({height, width}) => (
				<List
					width={width}
					height={height}
					itemData={{filteredMemories, listRef, memoryHeights}}
					itemCount={filteredMemories.length}
					itemSize={memoryHeight}
					ref={listRef}
				>
					{MemoryQuickLooks}
				</List>
			)}
		</AutoSizer>
	);
};
QuickLookUI.propTypes = {
	filterValues: PropTypes.object,
};
QuickLookUI.defaultProps = {
	filterValues: undefined,
};

export default QuickLookUI;
