import React from "react";
import PropTypes from "prop-types";
import InputContainer from "../../Components/InputContainer";
import Switch from "../../Components/Switch";

const MemoryPhoneCalendar = ({value, onChange, label, placeholder = "Add Memory To Calendar"}) => {
	const handleToggle = () => {
		if (typeof value !== "boolean") {
			onChange(true);
			return;
		}

		onChange(!value);
	};

	return (
		<InputContainer label={label}>
			<div>
				<p>{placeholder}</p>
				<Switch checked={value} onToggle={handleToggle} />
			</div>
		</InputContainer>
	);
};

MemoryPhoneCalendar.propTypes = {
	value: PropTypes.bool,
	onChange: PropTypes.func,
	label: PropTypes.string,
	placeholder: PropTypes.string,
};
MemoryPhoneCalendar.defaultProps = {
	value: undefined,
	onChange: undefined,
	label: undefined,
	placeholder: "Add Memory To Calendar",
};

export default MemoryPhoneCalendar;
