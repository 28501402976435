import React, {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {GoPlus} from "react-icons/go";
import {IconContext} from "react-icons";

import Colors from "../../../../../resources/Colors";

const CreateMemoryButton = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate("/app/MemoryTemplates");
	};

	return (
		<div
			onClick={handleClick}
			onKeyDown={handleClick}
			role="presentation"
			style={{backgroundColor: Colors.primaryBlue}}
			className="absolute bottom-0 right-0 z-50 m-4 flex h-24 w-24 cursor-pointer flex-col items-center justify-center rounded-full text-lg text-white md:h-28 md:w-28"
		>
			<IconContext.Provider value={useMemo(() => ({size: "36px"}), [])}>
				<GoPlus />
			</IconContext.Provider>
			<p className="font-medium">Create</p>
		</div>
	);
};
export default CreateMemoryButton;
