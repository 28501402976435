import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import {ref, getDownloadURL} from "firebase/storage";

import {storage} from "../../../firebase";

const FileEditor = ({existingFiles = [], setExistingFiles}) => {
	const [thumbnails, setThumbnails] = useState({});

	useEffect(() => {
		if (existingFiles) {
			existingFiles.forEach(file => {
				if (file.thumbnail && file.thumbnail.storagePath) {
					getDownloadURL(ref(storage, file.thumbnail.storagePath)).then(url => {
						setThumbnails(prevValue => ({...prevValue, [file.file.storagePath]: url}));
					});
				}
			});
		}
	}, [existingFiles]);

	return (
		<div className="text-center">
			<div className="flex flex-col justify-center p-5">
				{existingFiles
					? existingFiles.map(file => (
							<div key={file.file.storagePath}>
								<div className="flex justify-between">
									<p>{file.file.name}</p>
									<button
										type="button"
										className="font-bold text-red-500"
										onClick={() => {
											let temp = [...existingFiles];
											temp = temp.filter(f => f.file.storagePath !== file.file.storagePath);
											console.log("Filtered", temp);
											setExistingFiles(temp);
										}}
									>
										Remove
									</button>
								</div>
								{thumbnails[file.file.storagePath] && <img src={thumbnails[file.file.storagePath]} alt="thumbnail" />}
							</div>
						))
					: null}
			</div>
		</div>
	);
};

FileEditor.propTypes = {
	existingFiles: PropTypes.arrayOf(
		PropTypes.shape({
			file: PropTypes.shape({
				author: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				size: PropTypes.number.isRequired,
				storageBucket: PropTypes.string.isRequired,
				storagePath: PropTypes.string.isRequired,
			}).isRequired,
			thumbnail: PropTypes.shape({
				name: PropTypes.string.isRequired,
				size: PropTypes.number.isRequired,
				storageBucket: PropTypes.string.isRequired,
				storagePath: PropTypes.string.isRequired,
			}),
		}),
	),
	setExistingFiles: PropTypes.func,
};
FileEditor.defaultProps = {
	existingFiles: [],
	setExistingFiles: undefined,
};

export default FileEditor;
