import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";

import ValueOptions from "../../../../../../resources/ValueOptions";
import {AuthContext} from "../../../AuthProvider";
import classes from "./StatusPicker.module.css";

const CategoryPicker = ({selectedCategories, setSelectedCategories}) => {
	const {userCustomData} = useContext(AuthContext);

	const [categories, setCategories] = useState(ValueOptions.categories);

	useEffect(() => {
		const newCategories = [...categories];
		let requiresUpdate = false;
		selectedCategories.forEach(category => {
			if (!newCategories.includes(category)) {
				newCategories.push(category);
				requiresUpdate = true;
			}
		});
		if (requiresUpdate) {
			setCategories(newCategories);
		}
	}, [selectedCategories]);

	useEffect(() => {
		if (userCustomData) {
			const {categories: docCategories, categoriesOrder} = userCustomData.categoriesData;
			const newCategories = categoriesOrder.map(index => docCategories[index].name);
			categories.forEach(category => {
				if (!newCategories.includes(category)) {
					newCategories.push(category);
				}
			});
			setCategories(newCategories);
		}
	}, [userCustomData]);

	const renderCategoryButton = (category, key) => {
		const isSelected = selectedCategories.includes(category);
		const buttonClass = `${classes.status} ${isSelected ? classes.selected : classes.nonSelected}`;
		return (
			<button
				type="button"
				className={buttonClass}
				key={key}
				onClick={() => {
					let tempSelectedCategories = [...selectedCategories];
					if (selectedCategories.includes(category)) {
						tempSelectedCategories = tempSelectedCategories.filter(value => value !== category);
					} else {
						tempSelectedCategories.push(category);
					}
					setSelectedCategories([...tempSelectedCategories]);
				}}
			>
				{category}
			</button>
		);
	};

	return (
		<div className="borde-2 ustify-start w-full space-y-3 border-black bg-white text-left">
			<div className="flex">
				<span className="w-full basis-2/3 text-left text-xl font-bold text-gray-500">Select a category</span>
				{/* <p className="basis-1/3 text-blue-600 text-xl font-bold w-full px-2 text-right">Edit Categories</p> */}
			</div>
			<div className="flex flex-row flex-wrap">
				{categories.map((currentElement, index) => renderCategoryButton(currentElement, index))}
			</div>
		</div>
	);
};

CategoryPicker.propTypes = {
	selectedCategories: PropTypes.arrayOf(PropTypes.string),
	setSelectedCategories: PropTypes.func,
};
CategoryPicker.defaultProps = {
	selectedCategories: [],
	setSelectedCategories: undefined,
};

export default CategoryPicker;
