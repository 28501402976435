/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

import InputContainer from "../../Components/InputContainer";

const NativeTimePicker = ({value, onChange, label}) => {
	const formatTimeValue = timeValue => {
		let date;
		if (timeValue instanceof Date) {
			date = timeValue;
		} else if (timeValue && typeof timeValue.toDate === "function") {
			// Check for Firestore timestamp
			date = timeValue.toDate(); // Convert to Date object
		} else if (timeValue && timeValue.seconds) {
			// Fallback if toDate is not available
			date = new Date(timeValue.seconds * 1000); // Convert seconds to milliseconds
		} else {
			return "";
		}

		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	};

	const handleTimeChange = event => {
		const timeValue = event.target.value;
		const [hours, minutes] = timeValue.split(":").map(Number);
		const newDate = new Date();
		newDate.setHours(hours, minutes, 0, 0);
		onChange(newDate);
	};

	return (
		<InputContainer label={label}>
			<input type="time" value={formatTimeValue(value)} onChange={handleTimeChange} className="form-input" />
		</InputContainer>
	);
};

NativeTimePicker.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.shape({
			nanoseconds: PropTypes.number,
			seconds: PropTypes.number,
		}),
	]),
	onChange: PropTypes.func,
	label: PropTypes.string,
};

NativeTimePicker.defaultProps = {
	value: undefined,
	onChange: undefined,
	label: undefined,
};

export default NativeTimePicker;
