import React from "react";
import ReactDOM from "react-dom/client";
// this has to be before the root component import or the preflight styles will overwrite various custom styles.
import "./index.css";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
