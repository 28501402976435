import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
// Routes
import ErrorPage from "./error-page";
import HomeScreensParent from "./routes/HomeScreens";
import Home from "./routes/HomeScreens/Home";
import AboutUs from "./routes/HomeScreens/AboutUs";
import SignIn from "./routes/SignIn";
import SignUp from "./routes/SignUp";
import ResetPassword from "./routes/ResetPassword";
import ProtectedRoute from "./routes/ProtectedRoute";
import Memories from "./routes/Memories";
import MemoryTemplates from "./routes/MemoryTemplates";
import CreateTemplateScreen from "./routes/Create/CreateTemplate";
import CreateMemory from "./routes/Create/CreateMemory";
import DisplayMemoryInformation from "./routes/Detail/DisplayMemoryInformation";
import Contacts from "./routes/Contacts";
import CreateContact from "./routes/Create/CreateContact";
import CareManage from "./routes/Care/CareManage";
import CareRoleSelect from "./routes/Care/CareRoleSelect";
import CareUserSelect from "./routes/Care/CareUserSelect";
import CareUserAgree from "./routes/Care/CareUserAgree";
import DisplayContactDetails from "./routes/Detail/DisplayContactDetails";
// Auth Provider/Context
import {AuthProvider} from "./AuthProvider";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const router = createBrowserRouter([
	{
		id: "root",
		path: "/",
		element: <HomeScreensParent />,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "about",
				element: <AboutUs />,
			},
		],
	},
	{
		id: "signIn",
		path: "signIn",
		element: <SignIn />,
		errorElement: <ErrorPage />,
	},
	{
		id: "signUp",
		path: "signUp",
		element: <SignUp />,
		errorElement: <ErrorPage />,
	},
	{
		id: "passwordReset",
		path: "resetPassword",
		element: <ResetPassword />,
		errorElement: <ErrorPage />,
	},
	{
		id: "protected",
		path: "/app",
		element: <ProtectedRoute />,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Memories />,
			},
			{
				path: "MemoryTemplates",
				element: <MemoryTemplates />,
			},
			{
				path: "CreateTemplate",
				element: <CreateTemplateScreen />,
			},
			{
				path: "CreateMemory",
				element: <CreateMemory />,
			},
			{
				path: "DisplayMemoryInformation",
				element: <DisplayMemoryInformation />,
			},
			{
				path: "Contacts",
				element: <Contacts />,
			},
			{
				path: "CreateContact",
				element: <CreateContact />,
			},
			{
				path: "CareManage",
				element: <CareManage />,
			},
			{
				path: "CareRoleSelect",
				element: <CareRoleSelect />,
			},
			{
				path: "CareUserSelect/:role",
				element: <CareUserSelect />,
			},
			{
				path: "CareUserAgree/:role/:uid",
				element: <CareUserAgree />,
			},
			{
				path: "DisplayContactDetails",
				element: <DisplayContactDetails />,
			},
		],
	},
]);

const App = () => (
	<AuthProvider>
		<RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
	</AuthProvider>
);

export default App;
