import React, {useState, useEffect, useMemo, useContext} from "react";
import {FaRegPlusSquare} from "react-icons/fa";
import {IconContext} from "react-icons";
import {useNavigate} from "react-router-dom";
import {onSnapshot, query, where, orderBy, collection} from "firebase/firestore";

import {schemas, memoryTypeIds} from "../../../../resources/schemas";
import {AuthContext} from "../AuthProvider";
import {firestore} from "../firebase";
import colorLogo from "../../../../resources/icons/Colors.png";
import ColorPaletteModal from "./Components/Modals/ColorPaletteModal";
import ValueOptions from "../../../../resources/ValueOptions";

const {defaultMemoryTemplateColor, defaultMemoryTemplateTextColor} = ValueOptions;

const MemoryTemplates = () => {
	const navigate = useNavigate();
	const {currentUser} = useContext(AuthContext);
	const {selectedPalette} = useContext(AuthContext);
	const [showColorModal, setShowColorModal] = useState(false);
	const [customTemplates, setCustomTemplates] = useState({});
	const [customTemplateIds, setCustomTemplateIds] = useState([]);
	const templateIds = useMemo(() => memoryTypeIds.concat(customTemplateIds), [customTemplateIds]);

	useEffect(() => {
		if (currentUser) {
			const q = query(
				collection(firestore, "memoryTemplates"),
				where("viewers", "array-contains-any", [currentUser.uid]),
				orderBy("lastUpdated", "desc"),
			);

			const unSubCustomTemplates = onSnapshot(q, querySnapshot => {
				const tempTemplates = {};
				const tempTemplateIds = [];
				querySnapshot.forEach(d => {
					tempTemplates[d.id] = d.data();
					tempTemplateIds.push(d.id);
				});
				setCustomTemplates(tempTemplates);
				setCustomTemplateIds(tempTemplateIds);
			});

			return unSubCustomTemplates;
		}
		return undefined;
	}, [currentUser]);

	const handleClickMemory = memoryType => {
		navigate(`/app/CreateMemory/?memoryTypeId=${memoryType.id}`);
	};

	const renderIconContextProvider = style => (
		<IconContext.Provider value={style}>
			<FaRegPlusSquare />
		</IconContext.Provider>
	);

	return (
		<div className="flex flex-col">
			{showColorModal ? <ColorPaletteModal setShowColorModal={setShowColorModal} /> : null}
			<div className="flex w-full flex-row justify-between">
				<p className="m-10 text-3xl font-medium">Create Memory</p>
				<div
					onClick={() => {
						setShowColorModal(true);
					}}
					onKeyDown={() => {
						setShowColorModal(true);
					}}
					role="presentation"
					className="mr-14 flex w-24 cursor-pointer flex-col items-center"
				>
					<div className="mt-8 flex h-12 w-12 items-center justify-center rounded-lg bg-gray-200">
						<img src={colorLogo} className="h-9 w-9" alt="color logo" />
					</div>
					<p className="pt-2 text-sm">Change</p>
					<p className="text-sm">Color Palette</p>
				</div>
			</div>
			<div className="flex w-full flex-row flex-wrap justify-center">
				{templateIds.map(id => {
					const memoryType = customTemplates[id] ?? schemas[id];
					const backgroundColor =
						selectedPalette?.colors?.[id] ?? memoryType?.backgroundColor ?? defaultMemoryTemplateColor;
					const textColor =
						selectedPalette?.textColors?.[id] ?? memoryType?.textColors ?? defaultMemoryTemplateTextColor;
					return (
						<div
							key={id}
							onClick={() => {
								handleClickMemory(memoryType);
							}}
							onKeyDown={() => {
								handleClickMemory(memoryType);
							}}
							role="presentation"
							className="m-10 flex h-44 w-44 cursor-pointer flex-col items-center justify-evenly rounded-lg text-lg font-medium"
							style={{backgroundColor}}
						>
							<p style={{color: textColor}}>{memoryType.displayName}</p>
							{renderIconContextProvider({
								color: textColor,
								size: "24px",
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MemoryTemplates;
