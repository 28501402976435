const checkMemoryFilters = (
	itemData,
	userFilter,
	memoryCategoryFilter,
	memoryTypeFilters,
	memoryStatusFilters,
	memoryDateFilter,
	memorySearchQueryFilters,
) =>
	checkViewableUsers(itemData, userFilter) &&
	checkMemoryCategory(itemData, memoryCategoryFilter) &&
	checkMemoryType(itemData, memoryTypeFilters) &&
	checkMemoryStatus(itemData, memoryStatusFilters) &&
	checkMemoryDate(itemData, memoryDateFilter) &&
	checkMemorySearchQuery(itemData, memorySearchQueryFilters);

const checkContactFilters = (itemData, userFilter) => checkViewableUsers(itemData, userFilter);

// Helper Methods

const checkViewableUsers = (doc, userFilter) => doc?.viewers.find(user => userFilter[user] === true);

const checkMemoryCategory = (itemData, memoryCategoryFilter) => {
	if (!memoryCategoryFilter) {
		return true;
	}
	if (itemData.categories) {
		return itemData.categories.find(item => item.toLowerCase() === memoryCategoryFilter.toLowerCase());
	}
	return undefined;
};

const checkMemoryType = (itemData, memoryTypeFilters) => {
	if (memoryTypeFilters.length === 0) {
		return true;
	}
	// We have no type to filter by -- Note: this is an array
	return memoryTypeFilters.find(item => item === itemData.typeOfMemoryId ?? "");
};

// check status
const checkMemoryStatus = (itemData, memoryStatusFilter) => {
	if (memoryStatusFilter.length === 0) {
		return true;
	}
	// We have no type to filter by -- Note: this is an array

	return (
		// check status of memory
		memoryStatusFilter.find(item => item === itemData.memory?.status) ||
		// check status of simple tasks
		(itemData.memory?.simpleTasks &&
			itemData.memory?.simpleTasks.find(task => memoryStatusFilter.find(item => item === task.status))) ||
		// check status of tasks
		(itemData.memory?.tasks &&
			itemData.memory?.tasks.find(task => memoryStatusFilter.find(item => item === task.status)))
	);
};

const checkMemoryDate = (itemData, memoryDateFilter) => {
	const {startDate, endDate} = memoryDateFilter;
	if (itemData.sortDate) {
		const memoryDate = new Date(itemData.sortDate.seconds * 1000);
		if (!startDate && !endDate) {
			return true;
		}
		if (startDate && !endDate) {
			return memoryDate >= startDate;
		}
		if (!startDate && endDate) {
			return memoryDate < endDate;
		}
		return memoryDate >= startDate && memoryDate < endDate;
	}
	return true;
};

const checkMemorySearchQuery = (itemData, memorySearchQueryFilters) => {
	// If there's no search query, pass the item by default
	if (!memorySearchQueryFilters) return true;

	const searchQuery = memorySearchQueryFilters.toLowerCase();

	return (
		(itemData.title && itemData.title.toLowerCase().includes(searchQuery)) ||
		// (itemData.typeOfMemory && itemData.typeOfMemory.toLowerCase().includes(searchQuery)) ||
		// (itemData.memory?.status && itemData.memory.status.toLowerCase().includes(searchQuery)) ||
		(itemData.memory?.tags && itemData.memory.tags.some(tag => tag.toLowerCase().includes(searchQuery)))
	);
};

export {checkMemoryFilters, checkContactFilters};
