import React from "react";
import PropTypes from "prop-types";
import {updateMemoryVisibility} from "../../../utilities/firestoreUtilities";

const ShareModal = ({setShowShareModal, isPublic, setIsPublic, memoryId}) => {
	const link = `${
		window.location.href.split(window.location.pathname)[0]
	}/app/DisplayMemoryInformation/?memoryId=${memoryId}`;

	const togglePrivacy = () => {
		const newIsPublic = !isPublic;
		setIsPublic(newIsPublic);
		updateMemoryVisibility(memoryId, newIsPublic)
			.then(() => {
				console.log("Visibility updated successfully.");
			})
			.catch(error => {
				console.error("Failed to update visibility:", error);
			});
	};
	const copyLink = () => {
		navigator.clipboard.writeText(link).then(() => {
			console.log("Link copied to clipboard!");
		});
	};

	return (
		<div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center overflow-y-hidden bg-neutral-500 bg-opacity-25">
			<div className="bg-gray-100 w-full mx-4 p-4 rounded-xl md:w-1/2 lg:w-1/3">
				<div className="flex justify-between items-center border-b border-gray-200 py-3">
					<div className="flex items-center justify-center">
						<p className="text-xl font-bold text-gray-800">Share Memory</p>
					</div>
					<button
						type="button"
						className=" cursor-pointer text-gray-500 w-8 h-8 flex items-center justify-center"
						onClick={() => {
							setShowShareModal(false);
						}}
					>
						X
					</button>
				</div>
				<div className="my-4">
					<button
						className={`px-4 py-2 rounded-full font-bold transition-colors ${
							isPublic ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
						}`}
						onClick={togglePrivacy}
						type="button"
					>
						{isPublic ? "Public" : "Private"}
					</button>
					<div className="border-2 border-gray-200 flex justify-between items-center mt-4 py-2">
						<input className="w-full outline-none bg-transparent" type="text" placeholder="" value={link} readOnly />

						<button className=" text-blue-600 rounded text-sm py-2 px-5 mr-2" onClick={copyLink} type="button">
							Copy
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

ShareModal.propTypes = {
	setShowShareModal: PropTypes.func.isRequired,
	isPublic: PropTypes.bool.isRequired,
	setIsPublic: PropTypes.func.isRequired,
	memoryId: PropTypes.string.isRequired,
};

export default ShareModal;
