import React, {useState} from "react";
import PropTypes from "prop-types";

const TitlePicker = ({label, value, onChange}) => {
	const [numOfRows, setNumOfRows] = useState(2);

	const handleChange = event => {
		const height = event.target.scrollHeight;
		const rowHeight = "36";
		const trows = Math.ceil(height / rowHeight);
		if (trows > numOfRows) {
			setNumOfRows(trows);
		} else if (!event.target.value || event.target.value === "") {
			setNumOfRows(2);
		}
		onChange(event.target.value);
	};

	return (
		<div
			className="w-full rounded-br-[30px] bg-white
        font-bold focus:border-white "
		>
			<span
				className="w-full basis-2/3 text-left
        text-xl font-bold text-gray-500"
			>
				{label}
			</span>

			<textarea
				className="min-h-[72px] w-full resize-y space-x-2 rounded-br-[30px] border-2
          px-2 text-left text-3xl
          font-bold text-blue-500 outline-none"
				name="TitleArea"
				rows={numOfRows}
				onChange={handleChange}
				value={value}
				placeholder="Enter Title"
				maxLength={90}
			>
				{value}
			</textarea>
		</div>
	);
};

TitlePicker.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};
TitlePicker.defaultProps = {
	label: undefined,
	value: undefined,
	onChange: undefined,
};

export default TitlePicker;
