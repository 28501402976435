import {React, useState, useEffect} from "react";
import CreateMemoryButton from "./CreateMemoryButton";
import FiltersUI from "./FiltersUI";
import QuickLookUI from "./QuickLookUI";
import Search from "./Search";

// const DEFAULT_FILTER = {
// 	memoryTypes: [],
// 	status: [],
// 	date: {
// 		startDate: new Date(),
// 		endDate: new Date(),
// 	},
// searchQuery: "",
// };

const Main = () => {
	// Initializing state with useState hook
	const [filters, setFilters] = useState({});

	useEffect(() => {
		console.log("filters:", JSON.stringify(filters, null, "\t"));
	}, [filters]);

	return (
		<div className="flex h-full flex-col">
			<CreateMemoryButton />
			<div className="flex flex-row items-center justify-between px-2">
				<div />
				<Search
					className="w-full max-w-md"
					placeholder="Search Memory"
					setSearchQuery={query => setFilters(prevFilters => ({...prevFilters, searchQuery: query}))}
				/>
				<FiltersUI filterValues={filters} setFilterValues={setFilters} />
			</div>

			<div className="flex h-full w-full overflow-clip">
				{/* Assuming QuickLookUI needs the filters, passing them as props */}
				<QuickLookUI filterValues={filters} />
			</div>
		</div>
	);
};

export default Main;
