import React from "react";
import PropTypes from "prop-types";
import style from "./ProgressBar.module.css";

const ProgressBar = ({progress}) => {
	const fillStyle = {
		width: `${progress * 100}%`,
	};
	return (
		<div className={style.progressBar}>
			<div className={style.progressFill} style={fillStyle} />
		</div>
	);
};

ProgressBar.propTypes = {
	progress: PropTypes.number,
};
ProgressBar.defaultProps = {
	progress: undefined,
};

export default ProgressBar;
