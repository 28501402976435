import React from "react";
import PropTypes from "prop-types";
import actionTypes from "./actionTypes";

const ContactModal = ({contactIn, close, type}) => {
	const actionType = actionTypes.find(action => action.id === type);

	if (!actionType) {
		return null; // or render a fallback component
	}

	return (
		<div className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center overflow-auto border-inherit bg-white">
			<div className="h-auto w-auto rounded-lg border-2 border-b-white bg-white p-5">
				{`Select ${type === "text" || type === "call" ? "a Phone Number" : "an Email"}`}
				<button type="button" className="mr-5px ml-auto font-bold" onClick={close} style={{marginLeft: "10px"}}>
					X (Close)
				</button>
			</div>

			<div>
				{contactIn &&
					actionType &&
					actionType.mapKey &&
					contactIn[actionType.mapKey] &&
					contactIn[actionType.mapKey].map((value, index) => (
						<div key={value[actionType.key] ?? index}>
							<p>{value.label}</p>
							<a href={`${actionType.urlStart}:${value[actionType.key]}`} style={{marginLeft: "10px"}}>
								{value[actionType.key]}
							</a>
						</div>
					))}
			</div>
		</div>
	);
};

ContactModal.propTypes = {
	contactIn: PropTypes.object,
	close: PropTypes.func,
	type: PropTypes.string,
};
ContactModal.defaultProps = {
	contactIn: undefined,
	close: undefined,
	type: "",
};

export default ContactModal;
