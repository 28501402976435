import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {GeoPoint} from "firebase/firestore";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from "react-google-places-autocomplete";

import Location from "../../../../../../resources/icons/Location.png";
import CloseIcon from "../../../../../../resources/icons/Close.png";
import BlueCheckMark from "../../../../../../resources/icons/BlueCheckmark.png";
import Pencil from "../../../../../../resources/icons/Edit.png";
import ModalTiles from "../../Components/Modals/ModalTiles";
import ValueOptions from "../../../../../../resources/ValueOptions";
import InputContainer from "../../Components/InputContainer";

const MapPin = () => (
	<img className="h-8 w-8 -translate-x-1/2 -translate-y-full object-scale-down" src={Location} alt="location" />
);

const LocationPicker = ({location, setLocation}) => {
	const [showModal, setShowModal] = useState(false);
	const [showManualAddressModal, setShowManualAddressModal] = useState(false);
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [tempLocation, setTempLocation] = useState(location);
	const [showCurrentLocation, setCurrentLocation] = useState(false);
	const [isValid, setIsValid] = useState(false);

	Geocode.setApiKey(ValueOptions.googleMapsAPIKey);
	Geocode.setLocationType("ROOFTOP");

	useEffect(() => {
		if (document.getElementById("googleMaps") == null) {
			const script = document.createElement("script");
			script.id = "googleMaps";
			script.src = `https://maps.googleapis.com/maps/api/js?key=${ValueOptions.googleMapsAPIKey}&libraries=places&callback=Function.prototype`;
			script.async = true;
			document.head.appendChild(script);
		}
	}, []);

	const success = position => {
		Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
			.then(
				response => {
					// console.log(JSON.stringify(response.results[0], null, "\t"));
					const fullAddress = response.results[0].formatted_address;
					let address1 = "";
					let address2 = "";
					let streetNum = "";
					let route = "";
					let city = "";
					let state = "";
					let country = "";
					let zip = "";
					for (let i = 0; i < response.results[0].address_components.length; i++) {
						for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
							switch (response.results[0].address_components[i].types[j]) {
								case "street_number":
									streetNum = response.results[0].address_components[i].long_name;
									break;
								case "route":
									route = response.results[0].address_components[i].long_name;
									break;
								case "subpremise":
									address2 = response.results[0].address_components[i].long_name;
									break;
								case "locality":
									city = response.results[0].address_components[i].long_name;
									break;
								case "administrative_area_level_1":
									state = response.results[0].address_components[i].long_name;
									break;
								case "country":
									country = response.results[0].address_components[i].long_name;
									break;
								case "postal_code":
									zip = response.results[0].address_components[i].long_name;
									break;
								default:
									break;
							}
						}
					}
					address1 = `${streetNum} ${route}`;
					setIsValid(true);
					setTempLocation({
						...tempLocation,
						locationDetails: {
							fullAddress,
							address1,
							address2,
							city,
							state,
							country,
							zip,
						},
						latLng: new GeoPoint(position.coords.latitude, position.coords.longitude),
						locationType: "current_location",
					});
				},
				error => {
					console.error(error);
				},
			)
			.catch(error => console.error(error));
	};
	const error = e => {
		console.error(e);
	};
	const options = {
		enableHighAccuracy: true,
		timeout: 6000,
		maximumAge: 0,
	};

	const display =
		location.locationType === "none" || location.locationType === "error"
			? "Select Location"
			: location.locationDetails.fullAddress;

	return (
		<>
			<InputContainer label="Location" onClick={() => setShowModal(true)} style={{cursor: "pointer"}}>
				<div>
					<p>{display}</p>
					<img className="h-7 w-7 object-scale-down" src={Location} alt="location" />
				</div>
			</InputContainer>

			{showModal && (
				<div
					className="fixed inset-0 flex items-center justify-center font-bold"
					style={{backgroundColor: "rgba(0, 0, 0, 0.7)"}}
				>
					<div className="w-96 rounded-md bg-gray-100 p-3">
						<div className="flex justify-between">
							{location.locationType !== "none" && location.locationType !== "error" && !showEditModal && (
								<div className="flex">
									<div className="flex items-center ">
										<p>Edit</p>

										<button
											type="button"
											className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
											onClick={() => {
												setShowEditModal(true);
												setTempLocation({...location});
											}}
										>
											<img className="h-5 w-9 place-self-center object-scale-down" src={Pencil} alt="pencil" />
										</button>
									</div>
								</div>
							)}

							<div className="place-self-left" />
							<div className="flex justify-end">
								<div className="flex items-center">
									<p>Close</p>

									<button
										type="button"
										className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
										onClick={() => {
											setShowModal(false);
											setShowEditModal(false);
										}}
									>
										<img className="place-self-center object-scale-down" src={CloseIcon} alt="close" />
									</button>
								</div>
							</div>
						</div>
						{(location.locationType === "none" || location.locationType === "error" || showEditModal) && (
							<div className="flex flex-col">
								<ModalTiles
									title="Search for Location"
									description="Search for location using Google"
									pic={Location}
									onClick={() => {
										setShowSearchModal(true);
										setShowModal(false);
									}}
								/>
								<ModalTiles
									title="Manual Address"
									description="Enter your very own manual address"
									pic={Location}
									onClick={() => setShowManualAddressModal(true)}
								/>
								<ModalTiles
									title="Current Location"
									description="Use your current location address"
									pic={Location}
									onClick={() => {
										navigator.geolocation.getCurrentPosition(success, error, options);
										setCurrentLocation(true);
									}}
								/>
							</div>
						)}

						{(location.locationType === "custom" ||
							location.locationType === "googleSearch" ||
							location.locationType === "current_location") &&
							!showEditModal && (
								<div className="flex flex-col">
									<div className="mt-4 h-80 w-full bg-blue-400">
										<GoogleMapReact
											yesIWantToUseGoogleMapApiInternals
											center={{
												lat: location.latLng.latitude,
												lng: location.latLng.longitude,
											}}
											zoom={15}
										>
											<MapPin lat={location.latLng.latitude} lng={location.latLng.longitude} />
										</GoogleMapReact>
									</div>
									<p>{location.locationDetails.address1}</p>
									<p>{location.locationDetails.address2}</p>
									<p>{location.locationDetails.city}</p>
									<p>{location.locationDetails.state}</p>
									<p>{location.locationDetails.country}</p>
									<p>{location.locationDetails.zip}</p>
								</div>
							)}
					</div>
				</div>
			)}

			{showCurrentLocation && (
				<div className="fixed inset-0 flex items-center justify-center font-bold">
					<div className="w-96 rounded-md bg-gray-100 p-3">
						<div className="flex flex-col">
							<div className="flex flex-row items-center justify-between">
								<div className="flex items-center justify-end">
									<p>Cancel</p>
									<button
										type="button"
										className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
										onClick={() => {
											setCurrentLocation(false);
											setShowEditModal(false);
											setTempLocation({...location});
											setIsValid(false);
										}}
									>
										<img className="place-self-center object-scale-down " src={CloseIcon} alt="close" />
									</button>
								</div>
								<div className="place-self-left" />
								<div className="flex justify-end">
									<div className="flex items-center">
										<p>Save</p>

										<button
											type="button"
											className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
											onClick={() => {
												if (isValid) {
													setCurrentLocation(false);
													setShowEditModal(false);
													setIsValid(false);
													const newLocation = {...location, ...tempLocation};
													setLocation({
														...newLocation,
														locationType: "current_location",
													});
												}
											}}
										>
											<img
												className="h-8 w-8 place-self-center object-scale-down"
												src={BlueCheckMark}
												alt="checkmark"
											/>
										</button>
									</div>
								</div>
							</div>

							{tempLocation.latLng != null && (
								<div className="mt-4 h-80 w-full bg-blue-400">
									<GoogleMapReact
										yesIWantToUseGoogleMapApiInternals
										center={{
											lat: tempLocation.latLng.latitude,
											lng: tempLocation.latLng.longitude,
										}}
										zoom={15}
									>
										<MapPin lat={tempLocation.latLng.latitude} lng={tempLocation.latLng.longitude} />
									</GoogleMapReact>
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			{showManualAddressModal && (
				<div className="fixed inset-0 flex items-center justify-center font-bold">
					<div className="w-96 rounded-md bg-gray-100 p-3">
						<div className="flex flex-col">
							<div className="flex flex-row items-center justify-between">
								<div className="flex items-center justify-end">
									<p>Cancel</p>
									<button
										type="button"
										// className="flex justify-end inset-0 bg-white m-1 font-bold text-xs hover:border-blue-800 active:border-blue-200 border-solid border-2 border-blue-200"
										className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
										onClick={() => {
											// add alert safeguard ---- TO DO
											setShowManualAddressModal(false);
											setShowEditModal(false);
											setTempLocation({...location});
										}}
									>
										{/* <p className="text-red-500 font-bold">Cancel</p> */}

										<img className="place-self-center object-scale-down " src={CloseIcon} alt="close" />
									</button>
								</div>

								<div className="flex items-center justify-end">
									<p>Save</p>

									<button
										type="button"
										className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
										onClick={() => {
											// add form verification ---- TO DO
											// setLocation({
											//   ...location,
											//   locationType: "custom",
											// });
											const address = `${tempLocation.locationDetails.address1}, ${
												tempLocation.locationDetails.address2 ? `${tempLocation.locationDetails.address2}, ` : ""
											}${tempLocation.locationDetails.city}, ${tempLocation.locationDetails.state}, ${
												tempLocation.locationDetails.zip
											}, ${tempLocation.locationDetails.country}`;

											Geocode.fromAddress(address).then(
												response => {
													const {lat, lng} = response.results[0].geometry.location;
													setLocation({
														locationDetails: {
															...tempLocation.locationDetails,
															fullAddress: address,
														},
														latLng: new GeoPoint(lat, lng),
														locationType: "custom",
													});
												},
												e => {
													setShowManualAddressModal(false);
													setShowEditModal(false);
													setShowModal(false);
													setLocation({
														locationDetails: {},
														locationType: "error",
													});

													console.error(e);
												},
											);
											setShowManualAddressModal(false);
											setShowEditModal(false);
										}}
									>
										<img className="h-8 w-8 place-self-center object-scale-down" src={BlueCheckMark} alt="checkmark" />
									</button>
								</div>
							</div>
							<div className="flex flex-col">
								<span className="text-md -mb-6 w-full text-left font-bold text-gray-400">Address 1</span>
								<input
									className="mb-6 mt-6 w-full rounded-md border border-gray-400/100 p-2 focus:outline-none"
									type="text"
									value={tempLocation.locationDetails.address1}
									onChange={text =>
										setTempLocation({
											...tempLocation,
											locationDetails: {
												...tempLocation.locationDetails,
												address1: text.target.value,
											},
										})
									}
								/>
								<span className="text-md -mt-6 w-full text-left font-bold text-gray-400">Address 2</span>
								<input
									className="mb-6 w-full rounded-md border border-gray-400/100 p-2 focus:outline-none"
									type="text"
									value={tempLocation.locationDetails.address2}
									onChange={text =>
										setTempLocation({
											...tempLocation,
											locationDetails: {
												...tempLocation.locationDetails,
												address2: text.target.value,
											},
										})
									}
								/>
								<span className="text-md -mt-6 w-full text-left font-bold text-gray-400">City</span>
								<input
									className="mb-6 w-full rounded-md border border-gray-400/100 p-2 focus:outline-none"
									type="text"
									value={tempLocation.locationDetails.city}
									onChange={text =>
										setTempLocation({
											...tempLocation,
											locationDetails: {
												...tempLocation.locationDetails,
												city: text.target.value,
											},
										})
									}
								/>
								<span className="text-md -mt-6 w-full text-left font-bold text-gray-400">State</span>
								<input
									className="mb-6 w-full rounded-md border border-gray-400/100 p-2 focus:outline-none"
									type="text"
									value={tempLocation.locationDetails.state}
									onChange={text =>
										setTempLocation({
											...tempLocation,
											locationDetails: {
												...tempLocation.locationDetails,
												state: text.target.value,
											},
										})
									}
								/>
								<span className="text-md -mt-6 w-full text-left font-bold text-gray-400">Zip</span>
								<input
									className="mb-6 w-full rounded-md border border-gray-400/100 p-2 focus:outline-none"
									type="text"
									value={tempLocation.locationDetails.zip}
									onChange={text =>
										setTempLocation({
											...tempLocation,
											locationDetails: {
												...tempLocation.locationDetails,
												zip: text.target.value,
											},
										})
									}
								/>
								<span className="text-md -mt-6 w-full text-left font-bold text-gray-400">Country</span>
								<input
									className="w-full rounded-md border border-gray-400/100 p-2 focus:outline-none"
									type="text"
									value={tempLocation.locationDetails.country}
									onChange={text =>
										setTempLocation({
											...tempLocation,
											locationDetails: {
												...tempLocation.locationDetails,
												country: text.target.value,
											},
										})
									}
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			{showSearchModal && (
				<div
					className="fixed inset-0 flex items-center justify-center font-bold"
					style={{backgroundColor: "rgba(0, 0, 0, 0.7)"}}
				>
					<div className="w-96 rounded-md bg-gray-100 p-3">
						<div className="flex flex-col">
							<div className="mb-3 flex flex-row items-center justify-between">
								<div className="flex items-center justify-end">
									<p>Cancel</p>
									<button
										type="button"
										className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
										onClick={() => {
											// add alert safeguard ---- TO DO
											setTempLocation({...location});
											setShowSearchModal(false);
											setShowModal(true);
											setShowEditModal(false);
										}}
									>
										<img className="place-self-center object-scale-down " src={CloseIcon} alt="close" />
									</button>
								</div>
								<div className="flex items-center justify-end">
									<p>Save</p>

									<button
										type="button"
										className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
										onClick={() => {
											const newLocation = {...location, ...tempLocation};
											if (isValid) {
												setLocation({
													...newLocation,
													locationType: "googleSearch",
												});
												setShowSearchModal(false);
												setShowEditModal(false);
												setIsValid(false);
											}
										}}
									>
										<img className="h-8 w-8 place-self-center object-scale-down" src={BlueCheckMark} alt="checkmark" />
									</button>
								</div>
							</div>
							<GooglePlacesAutocomplete
								debounce="500"
								selectProps={{
									placeholder: "Search for location",
									components: {
										DropdownIndicator: null,
										IndicatorSeparator: null,
									},
									tempLocation,
									onChange: temp => {
										geocodeByPlaceId(temp.value.place_id).then(results => {
											getLatLng(results[0])
												.then(({lat, lng}) => {
													Geocode.fromLatLng(lat, lng).then(
														response => {
															const fullAddress = response.results[0].formatted_address;
															let address1 = "";
															let address2 = "";
															let streetNum = "";
															let route = "";
															let city = "";
															let state = "";
															let country = "";
															let zip = "";
															for (let i = 0; i < response.results[0].address_components.length; i++) {
																for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
																	switch (response.results[0].address_components[i].types[j]) {
																		case "street_number":
																			streetNum = response.results[0].address_components[i].long_name;
																			break;
																		case "route":
																			route = response.results[0].address_components[i].long_name;
																			break;
																		case "subpremise":
																			address2 = response.results[0].address_components[i].long_name;
																			break;
																		case "locality":
																			city = response.results[0].address_components[i].long_name;
																			break;
																		case "administrative_area_level_1":
																			state = response.results[0].address_components[i].long_name;
																			break;
																		case "country":
																			country = response.results[0].address_components[i].long_name;
																			break;
																		case "postal_code":
																			zip = response.results[0].address_components[i].long_name;
																			break;
																		default:
																			break;
																	}
																}
															}
															address1 = `${streetNum} ${route}`;
															setTempLocation({
																...location,
																locationDetails: {
																	fullAddress,
																	address1,
																	address2,
																	city,
																	state,
																	country,
																	zip,
																},
																latLng: new GeoPoint(lat, lng),
																locationType: "googleSearch",
															});
															setIsValid(true);
														},
														e => {
															console.error(e);
														},
													);
												})
												.catch(e => console.error(e));
										});
									},
								}}
							/>
							{tempLocation.latLng != null && (
								<div className="mt-4 h-80 w-full bg-gray-200">
									<GoogleMapReact
										yesIWantToUseGoogleMapApiInternals
										center={{
											lat: tempLocation.latLng.latitude,
											lng: tempLocation.latLng.longitude,
										}}
										zoom={15}
									>
										<MapPin lat={tempLocation.latLng.latitude} lng={tempLocation.latLng.longitude} />
									</GoogleMapReact>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

LocationPicker.propTypes = {
	location: PropTypes.shape({
		apiResponse: PropTypes.object, // always empty
		latLng: PropTypes.shape({
			latitude: PropTypes.number,
			longitude: PropTypes.number,
		}),
		locationDetails: PropTypes.shape({
			fullAddress: PropTypes.string,
			address1: PropTypes.string,
			address2: PropTypes.string,
			city: PropTypes.string,
			state: PropTypes.string,
			country: PropTypes.string,
			zip: PropTypes.string,
		}),
		locationName: PropTypes.string, // always empty
		locationType: PropTypes.oneOf(["googleSearch", "current_location", "custom", "error", "none"]),
	}),
	setLocation: PropTypes.func,
};
LocationPicker.defaultProps = {
	location: ValueOptions.defaultLocationInfo,
	setLocation: undefined,
};

export default LocationPicker;
