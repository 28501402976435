import {React} from "react";
import {NavLink} from "react-router-dom";
import {HiDocumentText} from "react-icons/hi2";
import {SiHandshake} from "react-icons/si";
import {FaPaintBrush, FaCloud} from "react-icons/fa";
import PropTypes from "prop-types";

import smartRoger from "../../../../../resources/images/02.png";
import Logo from "../../../../../resources/images/01.png";

import HomeScreenImg from "../../../../../resources/images/Screenshot_HomeScreen_Want 2 Remember.jpg";
import CreateMemoryScreenImg from "../../../../../resources/images/CreateMemoryScreenV1.jpg";
import WebPic from "../../../../../resources/images/webScreenShot.png";

const BoxInfo = ({icon, title, description}) => (
	<div className="flex flex-col items-center rounded-2xl p-4 hover:bg-blue-50">
		{icon}
		<span className="font-bold">{title}</span>
		<p className="max-w-xs text-center">{description}</p>
	</div>
);

BoxInfo.propTypes = {
	icon: PropTypes.object,
	title: PropTypes.string,
	description: PropTypes.string,
};
BoxInfo.defaultProps = {
	icon: undefined,
	title: undefined,
	description: undefined,
};

const Home = () => (
	<div className="flex flex-col items-center px-4">
		<div className="relative flex items-center overflow-hidden rounded-lg bg-blue-50 p-4">
			<div className="overflow-hidden rounded-lg bg-gray-800 p-1">
				<img src={HomeScreenImg} alt="home" className="h-24 overflow-hidden rounded-md sm:h-32 md:h-48 xl:h-96" />
			</div>
			<div className="ml-2 overflow-hidden rounded-lg bg-gray-800 p-1 sm:mr-2">
				<img
					src={CreateMemoryScreenImg}
					alt="create memory"
					className="h-24 overflow-hidden rounded-md sm:h-32 md:h-48 xl:h-96"
				/>
			</div>
			<div className="hidden overflow-hidden rounded-lg bg-gray-800 p-1 sm:block">
				<img src={WebPic} alt="web" className="h-24 overflow-hidden rounded-md sm:h-32 md:h-48 xl:h-96" />
			</div>
			<div className="z-10 ml-4">
				<p className="w-24 text-center font-bold sm:w-28 sm:text-lg md:w-36 md:text-2xl">
					Helping you keep track of what is important
				</p>
			</div>
			<img
				className="absolute -bottom-[32%] -right-2 w-16 -scale-x-100 sm:-bottom-[39%] sm:w-24 md:-bottom-[42%] md:w-36 xl:-bottom-[31%] xl:w-48"
				src={Logo}
				alt="logo"
			/>
		</div>
		<p className="text-center font-bold sm:text-lg">Try our Want2Remember beta apps today!</p>
		<div className="my-2 flex flex-wrap justify-center">
			<a
				href="https://testflight.apple.com/join/Gtv7YJlC"
				className="m-2 flex rounded-2xl bg-blue-400 px-6 py-1 font-bold text-white hover:bg-blue-200"
			>
				iOS
			</a>
			<a
				href="https://appdistribution.firebase.dev/i/d3fead13bf5a108e"
				className="m-2 flex rounded-2xl bg-blue-400 px-6 py-1 font-bold text-white hover:bg-blue-200"
			>
				Android
			</a>
			<NavLink
				to="/SignIn"
				className="m-2 flex rounded-2xl bg-blue-400 px-6 py-1 font-bold text-white hover:bg-blue-200"
			>
				Web
			</NavLink>
		</div>
		<div className="flex flex-wrap items-center justify-center">
			<img className="mx-8 my-4 h-auto w-28 -scale-x-100" src={smartRoger} alt="smart Roger" />
			<p className="max-w-xs">
				Whether you are suffering from a cognitive impairment or just have a tough time remembering things,
				Want2Remember is here to help
			</p>
		</div>
		<div className="flex flex-wrap justify-center">
			<BoxInfo
				icon={<FaCloud title="Cloud Icon" className="text-4xl text-blue-400" />}
				title="Cloud Based"
				description="All memories are stored both locally on your mobile device and in the cloud so you always have access to them."
			/>
			<BoxInfo
				icon={<HiDocumentText title="File Icon" className="text-4xl text-blue-400" />}
				title="Files Support"
				description="Want2Remember supports adding a variety of different files to your memories such as images and videos so that your memories have everything they need to capture the moment "
			/>
			<BoxInfo
				icon={<FaPaintBrush title="Documents Icon" className="text-4xl text-blue-400" />}
				title="Customization"
				description="Multiple color pallets are available to choose from. Perfect for expressing yourself! Several color pallets are also provided to help those who deal with the most common forms of colorblindness."
			/>
			<BoxInfo
				icon={<SiHandshake title="Documents Icon" className="text-4xl text-blue-400" />}
				title="Care Giver"
				description="Rely on someone for care? Want2Remember supports adding other users as a caregiver so they can help manage your memories and contacts online. More features are always in development!"
			/>
		</div>
	</div>
);

export default Home;
