// Ryan and Marco
import React, {useMemo} from "react";
import PropTypes from "prop-types";
import Delayed from "../../../../../../resources/icons/Status/Delayed.png";
import Done from "../../../../../../resources/icons/Status/Done.png";
import InProgress from "../../../../../../resources/icons/Status/InProgress.png";
import Pending from "../../../../../../resources/icons/Status/Pending.png";
import Stopped from "../../../../../../resources/icons/Status/Stopped.png";

const statusIcons = {
	"Delayed": Delayed,
	"Done": Done,
	"In Progress": InProgress,
	"Pending": Pending,
	"Stopped": Stopped,
};

const QuickLookGenInfo = ({memoryTitle, status, memoryType, bgColor, textColor}) => {
	const icon = useMemo(() => (status ? statusIcons[status] : undefined), [status]);
	return (
		<div className="h-full w-full rounded-bl-2xl p-3" style={{backgroundColor: `${bgColor}`}}>
			<p
				className="text-left text-xl font-bold sm:text-2xl md:text-3xl"
				style={{
					color: `${textColor}`,
				}}
			>
				{memoryTitle}
			</p>
			<div className="my-3 flex flex-row flex-wrap items-center">
				<p
					className="md:text-md basis-1/2 text-left text-sm"
					style={{
						color: `${textColor}`,
					}}
				>
					{memoryType}
				</p>
				{Boolean(status) && (
					<div className="flex basis-1/2 flex-row flex-wrap items-center justify-end">
						<p
							className="md:text-md mr-2 text-sm"
							style={{
								color: `${textColor}`,
							}}
						>
							{status}
						</p>
						<img className="h-6 w-6" src={icon} alt="status" />
					</div>
				)}
			</div>
		</div>
	);
};

QuickLookGenInfo.propTypes = {
	memoryTitle: PropTypes.string,
	status: PropTypes.oneOf(["Delayed", "Done", "In Progress", "Pending", "Stopped", ""]),
	memoryType: PropTypes.string,
	bgColor: PropTypes.string,
	textColor: PropTypes.string,
};
QuickLookGenInfo.defaultProps = {
	memoryTitle: undefined,
	status: undefined,
	memoryType: undefined,
	bgColor: undefined,
	textColor: undefined,
};

export default QuickLookGenInfo;
