const text = "TEXT";
const date = "DATE";
// const calendarSwitch = 'CALENDAR_SWITCH';
const time = "TIME";
const emoji = "EMOJI";
const phoneNumber = "PHONE_NUMBER";
const emailAddress = "EMAIL_ADDRESS";
// const reminderScheduledTime = 'REMINDER_SCHEDULED_TIME';
// const reminderSnoozeDuration = 'REMINDER_SNOOZE_DURATION';
// const reoccurrence = 'REOCCURRENCE';
const url = "URL";
const password = "PASSWORD";
const tags = "TAGS";
const status = "STATUS";
const location = "LOCATION";
const people = "PEOPLE";
// const locationName = 'LOCATION_NAME';
// const locationAddress = 'LOCATION_ADDRESS';
const doubleText = "DOUBLE_TEXT";
const currency = "CURRENCY";
// const bills = 'BILLS';
// const deposits = 'DEPOSITS';
const payments = "PAYMENTS";
const paymentMethod = "PAYMENT_METHOD";
const transactionType = "TRANSACTION_TYPE";
const tasks = "TASKS";
const simpleTasks = "SIMPLE_TASKS";

// background colors generated at: https://coolors.co/7e93a0-65e7d0-4c061d-679dee-9c6bd9-cd533b-f2b202-3f63be-074f57

const GenericMemory = {
	fields: {
		date: {
			label: "Memory Date",
			type: date,
		},
		// calendarSwitch: {
		// 	label: 'Add to Calendar',
		// 	type: calendarSwitch,
		// },
		time: {
			label: "Memory Time",
			type: time,
		},
		emoji: {
			label: "Emoji",
			type: emoji,
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		status: {
			label: "Status",
			type: status,
		},
		// reminderDate: {
		// 	label: 'Reminder Date',
		// 	type: date,
		// },
		// reminderTime: {
		// 	label: 'Reminder Time',
		// 	type: time,
		// },
		// reminderScheduledTime: {
		// 	label: 'Reminder Scheduled Time',
		// 	type: reminderScheduledTime,
		// },
		// reminderSnoozeDuration: {
		// 	label: 'Snooze Timer',
		// 	type: reminderSnoozeDuration,
		// },
		location: {
			label: "location",
			type: location,
		},
		people: {
			label: "People",
			type: people,
		},
		// reoccurrence: {
		// 	label: 'Reoccurrence',
		// 	type: reoccurrence,
		// },
		notes: {
			label: "Notes",
			type: doubleText,
		},
	},
	componentDisplayOrder: [
		"status",
		"tags",
		"date",
		"time",
		// 'reminderDate',
		// 'reminderTime',
		// 'reminderScheduledTime',
		// 'reoccurrence',
		// 'reminderSnoozeDuration',
		// 'calendarSwitch',
		"location",
		"people",
		"emoji",
		"notes",
	],
	backgroundColor: "#65E7D0",
	textColor: "#000000",
	displayName: "Generic Memory",
	id: "GenericMemory",
	quickLook: {
		date: ["date"],
		time: ["time"],
		people: "people",
		location: "location",
		// location: {
		// 	icon: 'place',
		// 	name: 'location',
		// },
	},
	defaults: {
		memory: {
			date: "now",
			time: "now",
			notes: [{}, {}, {}],
		},
	},
};

const SimpleMemory = {
	fields: {
		date: {
			label: "Memory Date",
			type: date,
		},
		time: {
			label: "Memory Time",
			type: time,
		},
		// reminderScheduledTime: {
		// 	label: 'Reminder Scheduled Time',
		// 	type: reminderScheduledTime,
		// },
		// reminderSnoozeDuration: {
		// 	label: 'Snooze Timer',
		// 	type: reminderSnoozeDuration,
		// },
		// reoccurrence: {
		// 	label: 'Reoccurrence',
		// 	type: reoccurrence,
		// },
		status: {
			label: "Status",
			type: status,
		},
		emoji: {
			label: "Emoji",
			type: emoji,
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		location: {
			label: "location",
			type: location,
		},
		people: {
			label: "People",
			type: people,
		},
		// TODO: migrate old format
		notes: {
			label: "Notes",
			type: doubleText,
		},
		// calendarSwitch: {
		// 	label: 'Add to Calendar',
		// 	type: calendarSwitch,
		// },
	},
	componentDisplayOrder: [
		"status",
		"tags",
		"date",
		"time",
		// 'reminderScheduledTime',
		// 'reoccurrence',
		// 'reminderSnoozeDuration',
		// 'calendarSwitch',
		"location",
		"people",
		"emoji",
		"notes",
	],
	backgroundColor: "#074F57",
	textColor: "#ffffff",
	displayName: "Simple Memory",
	id: "SimpleMemory",
	quickLook: {
		date: ["date"],
		time: ["time"],
		people: "people",
		location: "location",
		// location: {
		// 	icon: 'place',
		// 	name: 'location',
		// },
	},
	defaults: {
		memory: {
			date: "now",
			time: "now",
			notes: [{}],
		},
	},
};

const ToDo = {
	fields: {
		date: {
			label: "Memory Date",
			type: date,
		},
		startingTime: {
			label: "Starting Time",
			type: time,
		},
		endingTime: {
			label: "Ending Time",
			type: time,
		},
		// reminderDate: {
		// 	label: 'Reminder Date',
		// 	type: date,
		// },
		// calendarSwitch: {
		// 	label: 'Add to Calendar',
		// 	type: calendarSwitch,
		// },
		status: {
			label: "Status",
			type: status,
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		emoji: {
			label: "Emoji",
			type: emoji,
		},
		tasks: {
			label: "Task",
			type: tasks,
		},
		location: {
			label: "Location",
			type: location,
		},
		people: {
			label: "People",
			type: people,
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
	},
	componentDisplayOrder: [
		"status",
		"tags",
		"date",
		"startingTime",
		"endingTime",
		// 'reminderDate',
		// 'calendarSwitch',
		"location",
		"people",
		"emoji",
		"tasks",
		"notes",
	],
	backgroundColor: "#679DEE",
	textColor: "#000000",
	displayName: "ToDo",
	id: "ToDo",
	quickLook: {
		date: ["date"],
		time: ["startingTime", "endingTime"],
		people: "people",
		location: "location",
	},
	defaults: {
		memory: {
			date: "now",
			startingTime: "now",
			endingTime: "now",
			tasks: [{}, {}],
			// calendarSwitch: true,
			status: "Pending",
		},
	},
};

const SimpleToDo = {
	fields: {
		date: {
			label: "Memory Date",
			type: date,
		},
		startingTime: {
			label: "Starting Time",
			type: time,
		},
		endingTime: {
			label: "Ending Time",
			type: time,
		},
		// reminderDate: {
		// 	label: 'Reminder Date',
		// 	type: date,
		// },
		// calendarSwitch: {
		// 	label: 'Add to Calendar',
		// 	type: calendarSwitch,
		// },
		status: {
			label: "Status",
			type: status,
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		simpleTasks: {
			label: "Simple Task",
			type: simpleTasks,
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
	},
	componentDisplayOrder: [
		"status",
		"tags",
		"date",
		"startingTime",
		"endingTime",
		// 'reminderDate',
		// 'calendarSwitch',
		"simpleTasks",
		"notes",
	],
	backgroundColor: "#679DEE",
	textColor: "#000000",
	displayName: "Simple ToDo",
	id: "SimpleToDo",
	quickLook: {
		date: ["date"],
		time: ["startingTime", "endingTime"],
	},
	defaults: {
		memory: {
			date: "now",
			startingTime: "now",
			endingTime: "now",
			simpleTasks: [{}, {}, {}, {}, {}],
			status: "Pending",
		},
	},
};

const Appointment = {
	fields: {
		emoji: {
			label: "Emoji",
			type: emoji,
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		status: {
			label: "Status",
			type: status,
		},
		date: {
			label: "Memory Date",
			type: date,
		},
		startingTime: {
			label: "Starting Time",
			type: time,
		},
		endingTime: {
			label: "Ending Time",
			type: time,
		},
		// reminderDate: {
		// 	label: 'Reminder Date',
		// 	type: date,
		// },
		// reminderScheduledTime: {
		// 	label: 'Reminder Time',
		// 	type: reminderScheduledTime,
		// },
		// reminderSnoozeDuration: {
		// 	label: 'Snooze Timer',
		// 	type: reminderSnoozeDuration,
		// },
		// reoccurrence: {
		// 	label: 'Reoccurrence',
		// 	type: reoccurrence,
		// },
		location: {
			label: "location",
			type: location,
		},
		thingsToBring: {
			label: "Things to Bring",
			type: text,
			options: {
				multiline: true,
			},
		},
		people: {
			label: "People",
			type: people,
		},
		questionsToAsk: {
			label: "Questions To Ask",
			type: text,
			options: {
				multiline: true,
			},
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
		// calendarSwitch: {
		// 	label: 'Add to Calendar',
		// 	type: calendarSwitch,
		// },
	},
	componentDisplayOrder: [
		"status",
		"tags",
		"date",
		"startingTime",
		"endingTime",
		// 'reminderDate',
		// 'reminderScheduledTime',
		// 'reoccurrence',
		// 'reminderSnoozeDuration',
		// 'calendarSwitch',
		"location",
		"people",
		"emoji",
		"thingsToBring",
		"questionsToAsk",
		"notes",
	],
	backgroundColor: "#F2B202",
	textColor: "#ffffff",
	displayName: "Appointment",
	id: "Appointment",
	quickLook: {
		date: ["date"],
		time: ["startingTime", "endingTime"],
		startTime: "startingTime",
		endTime: "endingTime",
		people: "people",
		location: "location",
	},
	defaults: {
		memory: {
			date: "now",
			startingTime: "now",
			endingTime: "now",
			// calendarSwitch: true,
		},
	},
};

const Interaction = {
	fields: {
		date: {
			label: "Memory Date",
			type: date,
		},
		time: {
			label: "Memory Time",
			type: time,
		},
		location: {
			label: "location",
			type: location,
		},
		// calendarSwitch: {
		// 	label: 'Add to Calendar',
		// 	type: calendarSwitch,
		// },
		people: {
			label: "People",
			type: people,
		},
		emoji: {
			label: "Emoji",
			type: emoji,
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		status: {
			label: "Status",
			type: status,
		},
		// reminderDate: {
		// 	label: 'Reminder Date',
		// 	type: date,
		// },
		// reminderTime: {
		// 	label: 'Reminder Time',
		// 	type: time,
		// },
		// reminderScheduledTime: {
		// 	label: 'Reminder Scheduled Time',
		// 	type: reminderScheduledTime,
		// },
		// reminderSnoozeDuration: {
		// 	label: 'Snooze Timer',
		// 	type: reminderSnoozeDuration,
		// },
		// reoccurrence: {
		// 	label: 'Reoccurrence',
		// 	type: reoccurrence,
		// },
		doNotForgetToSay: {
			label: "Do not forget to say",
			options: {
				multiline: true,
			},
			type: text,
		},
		interactions: {
			label: "Interaction",
			type: doubleText,
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
	},
	componentDisplayOrder: [
		"status",
		"tags",
		"date",
		"time",
		// 'reminderDate',
		// 'reminderTime',
		// 'reminderScheduledTime',
		// 'reoccurrence',
		// 'reminderSnoozeDuration',
		// 'calendarSwitch',
		"location",
		"people",
		"emoji",
		"doNotForgetToSay",
		"interactions",
		"notes",
	],
	backgroundColor: "#CD533B",
	textColor: "#000000",
	displayName: "Interaction",
	id: "Interaction",
	quickLook: {
		date: ["date"],
		time: ["time"],
		people: "people",
		location: "location",
		// location: {
		// 	icon: 'place',
		// 	name: 'location',
		// },
	},
	defaults: {
		memory: {
			date: "now",
			time: "now",
			interactions: [{}],
		},
	},
};

const WebsitePassword = {
	fields: {
		websiteAddress: {
			label: "Website Address",
			type: url,
		},
		userName: {
			label: "User Name",
			type: text,
			options: {
				autoCapitalize: "none",
				keyboardType: "email-address",
			},
		},
		password: {
			label: "Password",
			type: password,
		},
		pin: {
			label: "Pin",
			type: text,
			options: {
				keyboardType: "number-pad",
			},
		},
		dateCreated: {
			label: "Date Created",
			type: date,
		},
		timeCreated: {
			label: "Time Created",
			type: time,
		},
		recoveryEmail: {
			label: "Recovery Email",
			type: emailAddress,
		},
		recoveryPhoneNumber: {
			label: "Recovery Phone Number",
			type: phoneNumber,
		},
		techSupportPhoneNumber: {
			label: "Tech Support Phone Number",
			type: phoneNumber,
		},
		securityQuestions: {
			label: "Security Questions",
			type: doubleText,
			options: {
				titleLabel: "Security Question",
				titlePlaceHolder: "Write Security Question",
				contentLabel: "Security Answer",
				contentPlaceHolder: "Write Security Answer",
			},
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
	},
	componentDisplayOrder: [
		"tags",
		"websiteAddress",
		"userName",
		"password",
		"pin",
		"dateCreated",
		"timeCreated",
		"recoveryEmail",
		"recoveryPhoneNumber",
		"techSupportPhoneNumber",
		"securityQuestions",
		"notes",
	],
	backgroundColor: "#7E93A0",
	textColor: "#000000",
	displayName: "Website Password",
	id: "WebsitePassword",
	quickLook: {
		date: ["dateCreated"],
		time: ["timeCreated"],
		url: "websiteAddress",
		// location: {
		// 	icon: "website",
		// 	name: "websiteAddress",
		// },
	},
	defaults: {
		categories: ["Secure"],
		memory: {
			dateCreated: "now",
			timeCreated: "now",
		},
	},
};

const SimplePassword = {
	fields: {
		userName: {
			label: "User Name",
			type: text,
			options: {
				autoCapitalize: "none",
				keyboardType: "email-address",
			},
		},
		password: {
			label: "Password",
			type: password,
		},
		dateCreated: {
			label: "Date Created",
			type: date,
		},
		timeCreated: {
			label: "Time Created",
			type: time,
		},
		tags: {
			label: "Tags",
			type: tags,
		},
		securityQuestions: {
			label: "Security Questions",
			type: doubleText,
			options: {
				titleLabel: "Security Question",
				titlePlaceHolder: "Write Security Question",
				contentLabel: "Security Answer",
				contentPlaceHolder: "Write Security Answer",
			},
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
	},
	componentDisplayOrder: ["tags", "userName", "password", "dateCreated", "timeCreated", "securityQuestions", "notes"],
	backgroundColor: "#9C6BD9",
	textColor: "#000000",
	displayName: "Simple Password",
	id: "SimplePassword",
	quickLook: {
		date: ["dateCreated"],
		time: ["timeCreated"],
	},
	defaults: {
		categories: ["Secure"],
		memory: {
			dateCreated: "now",
			timeCreated: "now",
		},
	},
};

const CreditCard = {
	fields: {
		provider: {
			label: "Provider",
			type: text,
		},
		dateReceived: {
			label: "Date Received",
			type: date,
		},
		timeReceived: {
			label: "Time Received",
			type: time,
		},
		number: {
			label: "Card Number",
			type: password,
			options: {
				keyboardType: "number-pad",
			},
		},
		expiration: {
			label: "Expiration Date",
			type: date,
		},
		pin: {
			label: "Pin",
			type: password,
			options: {
				keyboardType: "number-pad",
			},
		},
		cvc: {
			label: "CVC",
			type: password,
			options: {
				keyboardType: "number-pad",
			},
		},
		securityQuestions: {
			label: "Security Questions",
			type: doubleText,
			options: {
				titleLabel: "Security Question",
				titlePlaceHolder: "Write Security Question",
				contentLabel: "Security Answer",
				contentPlaceHolder: "Write Security Answer",
			},
		},
		// bills: {
		// 	label: 'Bills',
		// 	type: payments,
		// 	options: {
		// 		sourceLabel: "Bill",
		// 		vendor: true,
		// 		dueDate: true,
		// 	}
		// },
		// payments: {
		// 	label: 'Payments',
		// 	type: payments,
		// },
		// reminderDate: {
		// 	label: 'Reminder Date',
		// 	type: date,
		// },
		// reminderTime: {
		// 	label: 'Reminder Time',
		// 	type: time,
		// },
		// reminderScheduledTime: {
		// 	label: 'Reminder Scheduled Time',
		// 	type: reminderScheduledTime,
		// },
		// reminderSnoozeDuration: {
		// 	label: 'Snooze Timer',
		// 	type: reminderSnoozeDuration,
		// },
		// reoccurrence: {
		// 	label: 'Reoccurrence',
		// 	type: reoccurrence,
		// },
		websiteAddress: {
			label: "Website Address",
			type: url,
		},
		userName: {
			label: "User Name",
			type: text,
			options: {
				autoCapitalize: "none",
				keyboardType: "email-address",
			},
		},
		password: {
			label: "Password",
			type: password,
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
	},
	componentDisplayOrder: [
		"provider",
		"number",
		"pin",
		"cvc",
		"expiration",
		"dateReceived",
		"timeReceived",
		"websiteAddress",
		"userName",
		"password",
		// 'address',
		// 'bills',
		// 'payments',
		// 'reminderDate',
		// 'reminderTime',
		// 'reminderScheduledTime',
		// 'reoccurrence',
		// 'reminderSnoozeDuration',
		"securityQuestions",
		"notes",
	],
	backgroundColor: "#4C061D",
	textColor: "#ffffff",
	displayName: "Credit Card",
	id: "CreditCard",
	defaults: {
		categories: ["Secure"],
		memory: {
			dateReceived: "now",
			timeReceived: "now",
		},
	},
	quickLook: {
		date: ["dateReceived"],
		time: ["timeReceived"],
		url: "websiteAddress",
	},
};

const BankAccount = {
	fields: {
		bankName: {
			label: "Bank Name",
			type: text,
		},
		routingNumber: {
			label: "Routing Number",
			type: text,
			options: {
				keyboardType: "number-pad",
			},
		},
		accountNumber: {
			label: "Account Number",
			type: password,
			options: {
				keyboardType: "number-pad",
			},
		},
		pin: {
			label: "Pin",
			type: password,
			options: {
				keyboardType: "number-pad",
			},
		},
		dateCreated: {
			label: "Date Created",
			type: date,
		},
		timeCreated: {
			label: "Time Created",
			type: time,
		},
		// reminderDate: {
		// 	label: 'Reminder Date',
		// 	type: date,
		// },
		// reminderTime: {
		// 	label: 'Reminder Time',
		// 	type: time,
		// },
		// reminderScheduledTime: {
		// 	label: 'Reminder Time',
		// 	type: reminderScheduledTime,
		// },
		// reminderSnoozeDuration: {
		// 	label: 'Snooze Timer',
		// 	type: reminderSnoozeDuration,
		// },
		// reoccurrence: {
		// 	label: 'Reoccurrence',
		// 	type: reoccurrence,
		// },
		// billingAddress: {
		// 	label: 'Billing Address',
		// 	type:,
		// },
		bankAddress: {
			label: "Bank Address",
			type: location,
		},
		bankWebsite: {
			label: "Bank Website",
			type: url,
		},
		userName: {
			label: "User Name",
			type: text,
			options: {
				autoCapitalize: "none",
				keyboardType: "email-address",
			},
		},
		password: {
			label: "Password",
			type: password,
		},
		techSupportPhoneNumber: {
			label: "Tech Support Phone Number",
			type: phoneNumber,
		},
		techSupportPin: {
			label: "Tech Support Pin",
			type: password,
			options: {
				keyboardType: "number-pad",
			},
		},
		recoveryEmail: {
			label: "Recovery Email",
			type: emailAddress,
		},
		recoveryPhoneNumber: {
			label: "Recovery Phone Number",
			type: phoneNumber,
		},
		securityQuestions: {
			label: "Security Questions",
			type: doubleText,
			options: {
				titleLabel: "Security Question",
				titlePlaceHolder: "Write Security Question",
				contentLabel: "Security Answer",
				contentPlaceHolder: "Write Security Answer",
			},
		},
		notes: {
			label: "Notes",
			type: doubleText,
		},
		people: {
			label: "People",
			type: people,
		},
		// bills: {
		// 	label: 'Bills',
		// 	type: payments,
		// 	options: {
		// 		sourceLabel: "Bill",
		// 		vendor: true,
		// 		dueDate: true,
		// 	}
		// },
		// deposits: {
		// 	label: 'Deposits',
		// 	type: payments,
		// },
	},
	componentDisplayOrder: [
		"bankName",
		"routingNumber",
		"accountNumber",
		"pin",
		"bankWebsite",
		"userName",
		"password",
		"dateCreated",
		"timeCreated",
		"people",
		"recoveryEmail",
		"recoveryPhoneNumber",
		"techSupportPhoneNumber",
		"techSupportPin",
		// 'billingAddress',
		"bankAddress",
		// 'bills',
		// 'deposits',
		// 'reminderDate',
		// 'reminderTime',
		// 'reminderScheduledTime',
		// 'reoccurrence',
		// 'reminderSnoozeDuration',
		"securityQuestions",
		"notes",
	],
	backgroundColor: "#3F63BE",
	textColor: "#000000",
	displayName: "Bank Account",
	id: "BankAccount",
	defaults: {
		categories: ["Secure"],
		memory: {
			dateCreated: "now",
			timeCreated: "now",
		},
	},
	quickLook: {
		date: ["dateCreated"],
		time: ["timeCreated"],
		people: "people",
		url: "bankWebsite",
		location: "bankAddress",
	},
};

const Purchase = {
	fields: {
		purchaseCategory: {
			label: "Purchase Category",
			type: text,
		},
		vendorName: {
			label: "Name of Vendor",
			type: text,
		},
		purchaseAmount: {
			label: "Purchase Amount",
			type: currency,
		},
		purchaseDate: {
			label: "Date of Purchase",
			type: date,
		},
		purchaseTime: {
			label: "Purchase Time",
			type: time,
		},
		transactionType: {
			label: "Transaction Type",
			type: transactionType,
		},
		purchaseMethod: {
			label: "Purchase Method",
			type: paymentMethod,
		},
		accountUsed: {
			label: "Account Used",
			type: text,
		},
		address: {
			label: "Billing Address",
			type: location,
		},
		// dueDate: {
		// 	label: 'Due Date',
		// 	type: date,
		// },
		// nextDueDate: {
		// 	label: 'Next Due Date',
		// 	type: date,
		// },
		descriptions: {
			label: "Description",
			type: doubleText,
			options: {
				contentLabel: "Description",
				contentPlaceHolder: "Write Description",
			},
		},
	},
	componentDisplayOrder: [
		"purchaseCategory",
		"vendorName",
		"purchaseAmount",
		"purchaseDate",
		"purchaseTime",
		"transactionType",
		"purchaseMethod",
		"accountUsed",
		"address",
		// 'dueDate',
		// 'nextDueDate',
		"descriptions",
	],
	backgroundColor: "#4C061D",
	textColor: "#ffffff",
	displayName: "Purchase",
	id: "Purchase",
	quickLook: {
		date: ["purchaseDate"],
		time: ["purchaseTime"],
		location: "address",
	},
	defaults: {
		memory: {
			purchaseDate: "now",
			purchaseTime: "now",
			descriptions: [{}],
		},
	},
};

const fieldTypes = {
	text,
	date,
	// calendarSwitch,
	time,
	emoji,
	phoneNumber,
	emailAddress,
	// reminderScheduledTime,
	// reminderSnoozeDuration,
	// reoccurrence,
	url,
	password,
	tags,
	status,
	currency,
	payments,
	paymentMethod,
	transactionType,
	location,
	people,
	doubleText,
	tasks,
	simpleTasks,
};

const memoryTypeIds = [
	"GenericMemory",
	"Interaction",
	"SimpleMemory",
	"ToDo",
	"Appointment",
	"SimpleToDo",
	"CreditCard",
	"WebsitePassword",
	"BankAccount",
	"SimplePassword",
	"Purchase",
];

const schemas = {
	GenericMemory,
	SimpleMemory,
	ToDo,
	SimpleToDo,
	Appointment,
	Interaction,
	WebsitePassword,
	SimplePassword,
	CreditCard,
	BankAccount,
	Purchase,
};

export {schemas, fieldTypes, memoryTypeIds};
