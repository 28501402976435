import React, {useState, useEffect, useRef} from "react";
import {collection, query, where, onSnapshot} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {VariableSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import PropTypes from "prop-types";

import {auth, firestore} from "../../firebase";
import createMemorystyle from "../Create/CreateMemory.module.css";
import Contact from "./Contact";
import Search from "../Memories/Search";

const DEFAULT_HEIGHT = 124;

const ContactQuickLook = ({data, index, style}) => {
	const {sortedContacts, listRef, contactHeights} = data;
	const contactData = sortedContacts[index];

	const setContactHeight = (i, size) => {
		listRef.current.resetAfterIndex(0);
		contactHeights.current = {...contactHeights.current, [i]: size};
	};
	const contactRef = useRef(null);

	useEffect(() => {
		if (contactRef.current) {
			const resizeObserver = new ResizeObserver(() => {
				if (contactRef?.current?.clientHeight) {
					setContactHeight(index, contactRef.current.clientHeight);
				}
			});
			resizeObserver.observe(contactRef.current);
			return () => resizeObserver.disconnect();
		}
		return undefined;
	}, [contactRef]);

	return (
		<div key={contactData?.id ?? index} style={style}>
			<div ref={contactRef} className="flex justify-center pb-2">
				<Contact contactIn={contactData} />
			</div>
		</div>
	);
};
ContactQuickLook.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number,
	style: PropTypes.object,
};
ContactQuickLook.defaultProps = {
	data: undefined,
	index: undefined,
	style: undefined,
};

const Contacts = () => {
	const navigate = useNavigate();
	const [contacts, setContacts] = useState([]);
	const [contactQuery, setContactQuery] = useState("");
	const [sortedContacts, setSortedContacts] = useState([]);

	const listRef = useRef({});
	const contactHeights = useRef({});

	useEffect(() => {
		const q = query(collection(firestore, "contacts"), where("viewers", "array-contains", auth.currentUser.uid));
		const userContacts = onSnapshot(q, querySnapshot => {
			const tempContacts = [];
			querySnapshot.forEach(doc => {
				tempContacts.push(doc.data());
			});
			setContacts(tempContacts);
			listRef.current.resetAfterIndex(0);
		});
		return userContacts;
	}, []);

	useEffect(() => {
		if (contacts) {
			const tempSortedContacts = filterContacts(contacts, contactQuery).sort((a, b) => {
				const fullNameA = ((a.contactInfo.givenName || "") + (a.contactInfo.familyName || "")).toLowerCase();
				const fullNameB = ((b.contactInfo.givenName || "") + (b.contactInfo.familyName || "")).toLowerCase();
				return fullNameA.localeCompare(fullNameB);
			});
			setSortedContacts(tempSortedContacts);
		}
	}, [contacts, contactQuery]);

	const contactHeight = index => contactHeights.current[index] ?? DEFAULT_HEIGHT;

	const filterContacts = (contactsToFilter, filterQuery) =>
		contactsToFilter.filter(contact => {
			if (filterQuery === "") {
				return true;
			}
			const fullName = ((contact.contactInfo.givenName || "") + (contact.contactInfo.familyName || "")).toLowerCase();
			return fullName.includes(filterQuery.toLowerCase());
		});

	const goBack = () => {
		navigate(-1);
	};

	const addContact = () => {
		navigate("/app/CreateContact");
	};

	return (
		<div className="relative flex h-full flex-col text-center">
			<div className={createMemorystyle.top}>
				<button type="button" onClick={goBack}>
					Back
				</button>
			</div>
			<div className="my-10 text-3xl font-bold">Contacts</div>
			<div className="mx-5 mb-6 inline-block">
				<div className="flex flex-row justify-between">
					<Search
						className="w-full max-w-xs"
						placeholder="Search Contacts"
						setSearchQuery={value => setContactQuery(value)}
					/>
					<div className="flex items-center">
						<button
							type="button"
							className="flex justify-end rounded-3xl border-2 border-solid border-blue-200 bg-white p-2 text-sm font-bold hover:border-blue-800 active:border-blue-200"
							onClick={addContact}
						>
							Add Contact
						</button>
					</div>
				</div>
			</div>
			<div className="h-full w-full">
				<AutoSizer>
					{({height, width}) => (
						<List
							height={height}
							width={width}
							itemData={{sortedContacts, listRef, contactHeights}}
							itemCount={sortedContacts.length}
							itemSize={contactHeight}
							ref={listRef}
						>
							{ContactQuickLook}
						</List>
					)}
				</AutoSizer>
			</div>
		</div>
	);
};

export default Contacts;
