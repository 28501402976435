import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {addDoc, serverTimestamp, collection} from "firebase/firestore";

import {auth, firestore} from "../../firebase";
import Profile from "../Components/Profile";
import StepCounter from "./Components/StepCounter";
import ValueOptions from "../../../../../resources/ValueOptions";

const roleToText = {
	Carereceiver: "care receiver",
	Caregiver: "care giver",
};

const roleExplanation = {
	Caregiver:
		"As a care giver, you will be potentially able to access the desired user's " +
		'"memories" within Want2Remember as well as their contacts. After pressing ' +
		"the continue button down below, your request to be the user's care giver " +
		"will be sent.",
	Carereceiver:
		"As a care receiver, you will be granting the desired user access to potentially " +
		'view and edit your "memories" within Want2Remember as well your contacts. ' +
		"After pressing the continue button down below, your request to be the user's " +
		"care receiver will be sent.",
};

const CareUserAgree = () => {
	const navigate = useNavigate();
	const {role, uid} = useParams();
	const [creatingRequestDoc, setCreatingRequestDoc] = useState(false);

	const createRequestDoc = () => {
		setCreatingRequestDoc(true);
		const careGiverRequestData = {
			users: [auth.currentUser.uid, uid],
			caregiver: role === "Caregiver" ? auth.currentUser.uid : uid,
			carereceiver: role === "Caregiver" ? uid : auth.currentUser.uid,
			issuedBy: auth.currentUser.uid,
			status: "pending",
			dateCreated: serverTimestamp(),
			lastUpdateReceived: serverTimestamp(),
		};
		addDoc(collection(firestore, "pendingCareGiver"), careGiverRequestData)
			.then(() => navigate("/app/CareManage"))
			.catch(error => {
				console.log("error:", error);
			});
	};

	return (
		<div className="flex flex-col items-center space-y-4">
			<StepCounter options={ValueOptions.careSetupNavOptions} selectedIndex={2} />
			<p className="rounded-lg bg-[#F1F5FC] p-2 font-semibold text-blue-900 md:w-3/4 lg:w-1/2">
				{roleExplanation[role]}
			</p>
			<div className="flex flex-row items-center ">
				<p className="mr-4 font-semibold">{`Send request to be this user's ${roleToText[role]}?`}</p>
				<Profile displayFullName id={uid} />
			</div>
			<div className="flex flex-row items-center space-x-4">
				<button
					type="button"
					className="h-10 w-44 rounded-md bg-[#60AEF6] text-center font-bold text-white hover:text-blue-200"
					onClick={() => navigate("/app/CareRoleSelect")}
					disabled={creatingRequestDoc}
				>
					Cancel
				</button>
				<button
					type="button"
					className="h-10 w-44 rounded-md bg-[#60AEF6] text-center font-bold text-white hover:text-blue-200"
					onClick={() => createRequestDoc()}
					disabled={creatingRequestDoc}
				>
					Continue
				</button>
			</div>
		</div>
	);
};

export default CareUserAgree;
