/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

import Profile from "../Components/Profile";

const Caregivers = ({caregiverData}) => (
	<div className="m-2 rounded-lg bg-gray-200 px-2 ">
		<span className="text-sm font-bold">CAREGIVERS</span>
		<div className="flex items-center justify-center gap-2">
			{caregiverData && caregiverData.map((currentElement, index) => <Profile key={index} id={currentElement} />)}
		</div>
	</div>
);

Caregivers.propTypes = {
	caregiverData: PropTypes.arrayOf(PropTypes.string),
};
Caregivers.defaultProps = {
	caregiverData: undefined,
};

export default Caregivers;
