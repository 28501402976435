import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import {MdClose} from "react-icons/md";
import {IconContext} from "react-icons";
import {hexToHsva, hsvaToHex} from "@uiw/color-convert";
import {Wheel, ShadeSlider} from "@uiw/react-color";
import {cloneDeep} from "lodash";

import {memoryTypeIds, schemas} from "../../../../../../resources/schemas";
import {updateCustomPalette, updateDefaultPaletteKey} from "../../../utilities/firestoreUtilities";
import Colors from "../../../../../../resources/Colors";
import {AuthContext} from "../../../AuthProvider";
import ValueOptions from "../../../../../../resources/ValueOptions";

import styles from "./ColorPaletteModal.module.css";

const {defaultMemoryTemplateColor, defaultMemoryTemplateTextColor} = ValueOptions;

const ColorPaletteModal = ({setShowColorModal}) => {
	const {customDocumentData} = useContext(AuthContext);

	const [colorPalettes, setColorPalettes] = useState({});
	const [colorPaletteOrder, setColorPaletteOrder] = useState([]);
	const [actualSelectedPaletteKey, setActualSelectedPaletteKey] = useState("0");
	const [selectedPaletteKey, setSelectedPaletteKey] = useState("0");
	const [customPaletteMade, setCustomPaletteMade] = useState(false);

	const [selectedPaletteToEditKey, setSelectedPaletteToEditKey] = useState(null);
	const [customModalColorPalette, setCustomModalColorPalette] = useState({});
	const [selectedMemoryTypeId, setSelectedMemoryTypeId] = useState(memoryTypeIds[0]);

	useEffect(() => {
		if (customDocumentData?.colorPalettesData) {
			const {colorPalettesData} = customDocumentData;
			const {
				colorPalettes: tempColorPalettes,
				colorPaletteOrder: tempPalettesOrder,
				defaultPaletteKey,
				customPaletteMade: tempCustomPaletteMade,
			} = colorPalettesData;

			setColorPalettes(tempColorPalettes);
			setColorPaletteOrder(tempPalettesOrder);
			setActualSelectedPaletteKey(defaultPaletteKey);
			setSelectedPaletteKey(defaultPaletteKey);
			setCustomPaletteMade(tempCustomPaletteMade);
		}
	}, [customDocumentData]);

	const customBackgroundColor =
		customModalColorPalette?.colors?.[selectedMemoryTypeId] ??
		schemas?.[selectedMemoryTypeId]?.backgroundColor ??
		defaultMemoryTemplateColor;
	const customTextColor =
		customModalColorPalette?.textColors?.[selectedMemoryTypeId] ??
		schemas?.[selectedMemoryTypeId]?.textColor ??
		defaultMemoryTemplateTextColor;

	return (
		<div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center overflow-y-hidden bg-neutral-500 bg-opacity-25">
			{selectedPaletteToEditKey ? (
				<div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center overflow-y-hidden bg-neutral-500 bg-opacity-25">
					<div className="h-3/4 w-96 rounded-3xl bg-white">
						<div className="flex h-1/6 justify-between p-6">
							<p>{colorPalettes[selectedPaletteToEditKey]?.name}</p>
							<IconContext.Provider value={styles.colorPaletteIcon}>
								<MdClose
									className="cursor-pointer"
									onClick={() => {
										setSelectedPaletteToEditKey(null);
										setSelectedMemoryTypeId(memoryTypeIds[0]);
									}}
								/>
							</IconContext.Provider>
						</div>
						<div className="h-2/3 w-full">
							<div className="flex h-2/5 w-full flex-row flex-wrap px-10 overflow-scroll">
								{memoryTypeIds.map(id => (
									<div
										key={id}
										className="m-2 flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-transparent"
										style={{
											border: id === selectedMemoryTypeId ? `3px solid ${Colors.primaryBlue}` : "0px",
										}}
										role="presentation"
										onClick={() => {
											setSelectedMemoryTypeId(id);
										}}
										onKeyDown={() => {
											setSelectedMemoryTypeId(id);
										}}
									>
										<div
											className="h-8 w-8 rounded-full"
											style={{
												backgroundColor:
													customModalColorPalette?.colors[id] ??
													schemas[id]?.backgroundColor ??
													defaultMemoryTemplateColor,
											}}
										/>
									</div>
								))}
							</div>
							<div className="flex h-3/5 w-full">
								<div className="flex h-full w-1/3 flex-col">
									<div className="flex h-1/2 w-full flex-col items-center justify-center space-y-2 py-2">
										<p className="text-slate-600">Preview</p>
										<div
											className="flex h-16 w-16 items-center justify-center rounded-full border-2 text-center"
											style={{backgroundColor: customBackgroundColor}}
										>
											<p
												style={{
													fontSize: "9px",
													color: customTextColor,
												}}
											>
												{schemas[selectedMemoryTypeId].displayName}
											</p>
										</div>
									</div>
									<div className="flex h-1/2 w-full flex-col items-center justify-center space-y-2">
										<p className="text-xs">Text Color</p>
										<div className="flex h-1/2 w-full flex-row items-center justify-center">
											<div
												className="flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-transparent"
												style={{
													border: customTextColor === "#000000" ? `3px solid ${Colors.primaryBlue}` : "0px",
												}}
												role="presentation"
												onClick={() => {
													const tempPalette = cloneDeep(customModalColorPalette);
													tempPalette.textColors[selectedMemoryTypeId] = "#000000";
													setCustomModalColorPalette(tempPalette);
												}}
												onKeyDown={() => {
													const tempPalette = cloneDeep(customModalColorPalette);
													tempPalette.textColors[selectedMemoryTypeId] = "#000000";
													setCustomModalColorPalette(tempPalette);
												}}
											>
												<div className="h-8 w-8 rounded-full" style={{backgroundColor: "black"}} />
											</div>
											<div
												className="flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-transparent"
												style={{
													border: customTextColor !== "#000000" ? `3px solid ${Colors.primaryBlue}` : "0px",
												}}
												role="presentation"
												onClick={() => {
													const palette = cloneDeep(customModalColorPalette);
													palette.textColors[selectedMemoryTypeId] = "#ffffff";
													setCustomModalColorPalette(palette);
												}}
												onKeyDown={() => {
													const palette = cloneDeep(customModalColorPalette);
													palette.textColors[selectedMemoryTypeId] = "#ffffff";
													setCustomModalColorPalette(palette);
												}}
											>
												<div
													className="h-8 w-8 rounded-full"
													style={{
														border: "2px solid gray",
														backgroundColor: "white",
													}}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="flex w-2/3 flex-col items-center justify-center">
									<div className=" space-y-2">
										<Wheel
											color={hexToHsva(customBackgroundColor)}
											onChange={color => {
												const palette = cloneDeep(customModalColorPalette);
												palette.colors[selectedMemoryTypeId] = hsvaToHex(color.hsva);
												setCustomModalColorPalette(palette);
											}}
											height={125}
											width={125}
										/>
										<ShadeSlider
											hsva={hexToHsva(customBackgroundColor)}
											onChange={newShade => {
												const palette = cloneDeep(customModalColorPalette);
												const prevColor = customBackgroundColor;
												const newColor = hsvaToHex({
													...hexToHsva(prevColor),
													...newShade,
												});
												palette.colors[selectedMemoryTypeId] = newColor;
												setCustomModalColorPalette(palette);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="flex h-1/6 items-center justify-end p-5">
							<p
								style={{color: Colors.newBlue}}
								className="cursor-pointer text-sm"
								role="presentation"
								onClick={() => {
									setSelectedPaletteToEditKey(null);
									updateCustomPalette(customModalColorPalette);
									setSelectedPaletteKey("custom");
									setSelectedMemoryTypeId(memoryTypeIds[0]);
									document.getElementById("scrolling_div").scrollTo({top: 0, behavior: "smooth"});
								}}
								onKeyDown={() => {
									setSelectedPaletteToEditKey(null);
									updateCustomPalette(customModalColorPalette);
									setSelectedPaletteKey("custom");
									setSelectedMemoryTypeId(memoryTypeIds[0]);
									document.getElementById("scrolling_div").scrollTo({top: 0, behavior: "smooth"});
								}}
							>
								Apply
							</p>
						</div>
					</div>
				</div>
			) : null}

			<div className="h-4/5 w-96 flex-col rounded-3xl bg-white">
				<div className=" flex h-1/6 justify-between p-6">
					<p>Change Color Palette</p>
					<IconContext.Provider value={styles.colorPaletteIcon}>
						<MdClose
							className="cursor-pointer"
							onClick={() => {
								setShowColorModal(false);
								setSelectedPaletteKey(actualSelectedPaletteKey);
							}}
						/>
					</IconContext.Provider>
				</div>
				<div id="scrolling_div" className="h-2/3 w-full items-center space-y-12 overflow-y-scroll">
					{colorPaletteOrder.map(k => {
						// if the key matches the key for the custom palette and the custom palette has not been updated by the user, don't show the custom palette
						if (k === "custom" && !customPaletteMade) return null;
						return (
							<ColorPaletteDisplay
								key={k}
								palette={colorPalettes[k]}
								paletteId={k}
								selectedPaletteId={selectedPaletteKey}
								setSelectedPaletteId={setSelectedPaletteKey}
								setSelectedPaletteIdToEdit={setSelectedPaletteToEditKey}
								setCustomModalColorPalette={setCustomModalColorPalette}
							/>
						);
					})}
				</div>
				<div className="flex h-1/6 items-center justify-end p-5">
					<p
						style={{color: Colors.newBlue}}
						className="cursor-pointer text-sm"
						role="presentation"
						onClick={() => {
							setShowColorModal(false);
							updateDefaultPaletteKey(selectedPaletteKey);
						}}
						onKeyDown={() => {
							setShowColorModal(false);
							updateDefaultPaletteKey(selectedPaletteKey);
						}}
					>
						Apply
					</p>
				</div>
			</div>
		</div>
	);
};

ColorPaletteModal.propTypes = {
	setShowColorModal: PropTypes.func.isRequired,
};

const ColorPaletteDisplay = ({
	palette,
	paletteId,
	selectedPaletteId,
	setSelectedPaletteId,
	setSelectedPaletteIdToEdit,
	setCustomModalColorPalette,
}) => (
	<div className="mx-auto h-96 w-10/12 rounded-xl bg-white shadow-xl">
		<div
			className="h-full w-full p-5"
			role="presentation"
			onClick={() => {
				setSelectedPaletteIdToEdit(paletteId);
				setCustomModalColorPalette(palette);
			}}
			onKeyDown={() => {
				setSelectedPaletteIdToEdit(paletteId);
				setCustomModalColorPalette(palette);
			}}
		>
			<div className="flex flex-row justify-between pb-5">
				<p className="text-sm">{palette?.name}</p>
				<input
					type="checkbox"
					checked={paletteId === selectedPaletteId}
					onChange={() => {
						setSelectedPaletteId(paletteId);
						setSelectedPaletteIdToEdit(false);
					}}
					className="h-4 w-4"
				/>
			</div>
			<div className="flex w-full flex-row flex-wrap justify-center">
				{memoryTypeIds.map(id => (
					<div key={id} className="h-13 flex w-1/2 flex-col items-center text-xs">
						<div
							style={{
								backgroundColor: palette?.colors[id] ?? schemas[id]?.backgroundColor ?? defaultMemoryTemplateColor,
							}}
							className="h-8 w-8 rounded-full"
						/>
						<p>{schemas[id].displayName}</p>
					</div>
				))}
			</div>
		</div>
	</div>
);

ColorPaletteDisplay.propTypes = {
	palette: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		description: PropTypes.string,
		canEdit: PropTypes.bool.isRequired,
		colors: PropTypes.object.isRequired,
		textColors: PropTypes.object.isRequired,
	}).isRequired,
	paletteId: PropTypes.string.isRequired,
	selectedPaletteId: PropTypes.string,
	setSelectedPaletteId: PropTypes.func.isRequired,
	setSelectedPaletteIdToEdit: PropTypes.func.isRequired,
	setCustomModalColorPalette: PropTypes.func.isRequired,
};

ColorPaletteDisplay.defaultProps = {
	selectedPaletteId: undefined,
};

export default ColorPaletteModal;
