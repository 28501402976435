import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const DATE_OPTIONS = {month: "short"};

const QuickLookMemoryDate = ({dateSchema, memData}) => {
	const [memoryDate, setMemoryDate] = useState(null);

	useEffect(() => {
		if (dateSchema.length > 0) {
			for (let i = 0; i < dateSchema.length; i++) {
				const currDateId = dateSchema[i];
				if (memData.memory && memData.memory[currDateId]) {
					const displayDate = new Date(memData.memory[currDateId].seconds * 1000);
					setMemoryDate(displayDate);
					break;
				}
			}
		}
	}, [memData, dateSchema]);

	if (memoryDate) {
		return (
			<div>
				<p className="text-3xl font-bold text-gray-400">{memoryDate.getDate()}</p>
				<p className="text-sm font-bold text-gray-400">
					{new Intl.DateTimeFormat("en-US", DATE_OPTIONS).format(memoryDate).toUpperCase()}
				</p>
				<p className="text-sm font-semibold text-gray-400">{memoryDate.getFullYear()}</p>
			</div>
		);
	}
	return null;
};

QuickLookMemoryDate.propTypes = {
	dateSchema: PropTypes.arrayOf(PropTypes.string),
	memData: PropTypes.object,
};
QuickLookMemoryDate.defaultProps = {
	dateSchema: [],
	memData: {},
};

export default QuickLookMemoryDate;
