/* eslint-disable react/no-array-index-key */
import React, {useState, useEffect, useContext} from "react";
import {FaTrashAlt, FaCheck, FaTimes} from "react-icons/fa";
import {doc, addDoc, setDoc, updateDoc, collection, onSnapshot, query, where} from "firebase/firestore";
import {NavLink} from "react-router-dom";

import Profile from "../Components/Profile";
import {AuthContext} from "../../AuthProvider";
import {firestore} from "../../firebase";
import Switch from "../Components/Switch";
import ValueOptions from "../../../../../resources/ValueOptions";
import CloseIcon from "../../../../../resources/icons/Close.png";
import BlueCheckMark from "../../../../../resources/icons/BlueCheckmark.png";

const CareManage = () => {
	const {currentUser} = useContext(AuthContext);
	const [caregivers, setCaregivers] = useState([]);
	const [supporting, setSupporting] = useState([]);
	const [pendingCareDocs, setPendingCareDocs] = useState([]);
	const [alreadyRespondedRequests, setAlreadyRespondedRequests] = useState({});
	const [userNames, setUserNames] = useState({});
	const [showPermissionModal, setShowPermissionModal] = useState(false);
	const [tempPermissions, setTempPermissions] = useState({});
	const [focusedCaregiver, setFocusedCaregiver] = useState({});

	useEffect(() => {
		if (currentUser) {
			const q = query(
				collection(firestore, "pendingCareGiver"),
				where("users", "array-contains-any", [currentUser.uid]),
			);
			const userCareDocUnsubscribe = onSnapshot(doc(firestore, `users/${currentUser.uid}/userData/care`), d => {
				const tempCaregivers = [];
				const tempSupporting = [];
				if (d.data()?.caregivers?.users) {
					const userUids = Object.keys(d.data().caregivers.users);
					for (let i = 0; i < userUids.length; i++) {
						const currentUid = userUids[i];
						const permissions = d.data().caregivers.users[currentUid];
						tempCaregivers.push({
							id: currentUid,
							permissions,
							role: permissions.role,
						});
					}
				}
				if (d.data()?.supporting?.users) {
					const userUids = Object.keys(d.data().supporting.users);
					for (let i = 0; i < userUids.length; i++) {
						const currentUid = userUids[i];
						const permissions = d.data().supporting.users[currentUid];
						tempSupporting.push({
							id: currentUid,
							permissions,
							role: permissions.role,
						});
					}
				}
				setCaregivers(tempCaregivers);
				setSupporting(tempSupporting);
			});

			const pendingCareUnsubscribe = onSnapshot(q, querySnapshot => {
				const tempPendingCareDocs = [];
				console.log("total pending care docs:", querySnapshot.size);
				querySnapshot.forEach(d => {
					tempPendingCareDocs.push({
						...d.data(),
						docRef: d.ref,
						docId: d.id,
					});
				});
				setPendingCareDocs(tempPendingCareDocs);
			});
			return () => {
				userCareDocUnsubscribe();
				pendingCareUnsubscribe();
			};
		}
		return undefined;
	}, [currentUser]);

	const updateCareRequest = (docRef, docId, response) => {
		if (!alreadyRespondedRequests[docId]) {
			const tempAlreadyRespondedRequests = {...alreadyRespondedRequests};
			tempAlreadyRespondedRequests[docId] = true;
			setAlreadyRespondedRequests({...tempAlreadyRespondedRequests});
			updateDoc(docRef, {status: response});
		}
	};

	const deleteUserFromCare = (role, userId) => {
		addDoc(collection(firestore, "users", currentUser.uid, "userData", "care", "pendingUpdate"), {
			users: {
				[userId]: {
					delete: true,
					role,
				},
			},
		});
	};

	const userBox = ({userId, pending, onClick, remove, permissions}) => {
		const permissionText = permissions ? getPermissionAsString(permissions) : "";
		return (
			<div className="-z-0 mr-4 min-w-[250px] rounded-lg border-2 border-slate-200 bg-white p-2 drop-shadow-md">
				<div className="flex flex-row justify-between">
					<Profile
						className="mr-4"
						id={userId}
						displayFullName
						hideName
						exportUserName={value => setUserNames({...userNames, [userId]: value})}
						onClick={onClick}
					/>
					<div className="flex flex-row">
						{pending && (
							<div>
								<p className="rounded-full bg-[#9FEA93] p-2 text-sm font-bold text-[#27AE60]">Pending</p>
							</div>
						)}
						{remove && (
							<div>
								<button
									type="button"
									className="ml-2 flex flex-row items-center rounded-lg bg-red-400 p-2 text-sm font-medium text-white"
									onClick={() => remove()}
								>
									<FaTrashAlt title="Trash" className="mr-2" />
									Remove
								</button>
							</div>
						)}
					</div>
				</div>
				<span className="font-semibold">{userNames[userId]}</span>
				{permissionText.length > 0 && <p className="font-medium text-gray-500">{`Permissions: ${permissionText}`}</p>}
			</div>
		);
	};

	const showCareUsers = (role, pendingUsers, users) => (
		<div className="flex flex-row flex-wrap">
			{pendingUsers.map((userData, index) => {
				const userUid = userData[role];
				const removeUser = () => updateCareRequest(userData.docRef, userData.docId, "denied");
				return (
					<div className="flex flex-row" key={index}>
						{userBox({
							userId: userUid,
							pending: true,
							remove: removeUser,
						})}
					</div>
				);
			})}
			{users.map((userData, index) => {
				const removeUser = () => deleteUserFromCare(role === "caregiver" ? "caregiver" : "supporting", userData.id);
				return (
					<div className="flex flex-row items-center" key={index}>
						{userBox({
							userId: userData.id,
							remove: removeUser,
							permissions: userData.permissions,
							onClick: () => {
								setFocusedCaregiver(userData);
								setTempPermissions({
									...userData.permissions,
									role: userData.role,
								});
								setShowPermissionModal(true);
							},
						})}
					</div>
				);
			})}
		</div>
	);

	const getPermissionAsString = permissionsObj => {
		let permissionsText = "";
		const permissionsKeys = Object.keys(permissionsObj);
		for (let i = 0; i < permissionsKeys.length; i++) {
			const pKey = permissionsKeys[i];
			if (ValueOptions.carePermissions[pKey] && permissionsObj[pKey]) {
				if (permissionsText.length > 0) permissionsText += ", ";
				permissionsText += ValueOptions.carePermissions[pKey];
			}
		}
		return permissionsText;
	};

	const careRequestsView = () => (
		<div className="flex flex-row flex-wrap">
			{pendingCareDocs
				.filter(value => value.issuedBy !== currentUser.uid)
				.map((careRequestData, index) => (
					<div key={index} className="flex flex-row items-center rounded-full bg-[#60aef6] px-2 py-1">
						<Profile
							className="mr-2"
							id={careRequestData.issuedBy}
							hideName
							displayFullName
							exportUserName={value =>
								setUserNames(obj => ({
									...obj,
									[careRequestData.issuedBy]: value,
								}))
							}
						/>
						<p className="w-40 text-sm font-bold text-white sm:w-56 sm:text-base">{`${
							userNames[careRequestData.issuedBy]
						} would like to ${
							careRequestData.caregiver === currentUser.uid ? "be supported by you" : "be your caregiver"
						}`}</p>
						<div className="mx-2 flex flex-row">
							<button
								type="button"
								className="mr-2 flex flex-col items-center justify-center font-bold text-[#1d79ce]"
								onClick={() => updateCareRequest(careRequestData.docRef, careRequestData.docId, "accepted")}
							>
								<FaCheck
									className="h-auto w-auto rounded-full bg-[#b7dcff] p-1 sm:p-2 sm:text-xl"
									title="Accept button"
								/>
								<p className="text-xs sm:text-base">Accept</p>
							</button>
							<button
								type="button"
								className="flex flex-col items-center justify-center font-bold text-[#1d79ce]"
								onClick={() => updateCareRequest(careRequestData.docRef, careRequestData.docId, "denied")}
							>
								<FaTimes
									className="h-auto w-auto rounded-full bg-[#b7dcff] p-1 sm:p-2 sm:text-xl"
									title="Accept button"
								/>
								<p className="text-xs sm:text-base">Decline</p>
							</button>
						</div>
					</div>
				))}
		</div>
	);

	const updatePermissions = (caregiverData, permissions) => {
		setDoc(
			doc(firestore, `users/${currentUser.uid}/userData/care/pendingUpdate/permissionsUpdate`),
			{
				users: {
					[caregiverData.id]: {...permissions},
				},
			},
			{merge: true},
		).catch(error => console.error(error));
	};

	const permissionsModal = () =>
		showPermissionModal && (
			<div
				className="fixed inset-0 flex items-center justify-center font-bold"
				style={{backgroundColor: "rgba(0, 0, 0, 0.7)"}}
			>
				<div className="w-96 rounded-md bg-gray-100 p-3">
					<div className="flex flex-row items-center justify-between">
						<div className="flex items-center">
							<p>Cancel</p>
							<button
								type="button"
								className="m-1 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
								onClick={() => {
									setShowPermissionModal(false);
									setFocusedCaregiver({});
									setTempPermissions({});
								}}
							>
								<img className="h-8 w-8 object-scale-down" src={CloseIcon} alt="close" />
							</button>
						</div>
						<div className="flex items-center">
							<p>Save</p>
							<button
								type="button"
								className="m-1 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
								onClick={() => {
									updatePermissions(focusedCaregiver, tempPermissions);
									setShowPermissionModal(false);
									setFocusedCaregiver({});
									setTempPermissions({});
								}}
							>
								<img className="h-5 w-5 object-scale-down" src={BlueCheckMark} alt="checkmark" />
							</button>
						</div>
					</div>
					{Object.entries(ValueOptions.carePermissions).map(([key, value]) => (
						<div className="flex flex-row items-center justify-between pt-6" key={key}>
							<div>{value}</div>
							<Switch
								checked={tempPermissions[key] ?? false}
								onToggle={() =>
									setTempPermissions(() => ({
										...tempPermissions,
										[key]: !tempPermissions[key],
									}))
								}
							/>
						</div>
					))}
				</div>
			</div>
		);

	return (
		<div className="flex flex-col">
			{pendingCareDocs.filter(value => value.issuedBy !== currentUser.uid).length > 0 && (
				<div>
					<span className="mb-2 text-3xl font-bold">Care Requests</span>
					{careRequestsView()}
				</div>
			)}
			<span className="text-3xl font-bold">My Caregivers</span>
			<div className="my-4 flex flex-row flex-wrap">
				{showCareUsers(
					"caregiver",
					pendingCareDocs.filter(value => value.carereceiver === currentUser.uid && value.issuedBy === currentUser.uid),
					caregivers,
					null,
				)}
			</div>
			<NavLink
				className="my-2 flex h-[60px] w-[225px] items-center justify-center rounded-full bg-[#60AEF6] p-2 text-center font-bold text-white hover:text-blue-200"
				to="/app/CareUserSelect/Carereceiver"
			>
				<p>+ Add a Caregiver</p>
			</NavLink>
			<span className="text-3xl font-bold">People You Support</span>
			<div className="my-4 flex flex-row flex-wrap">
				{showCareUsers(
					"carereceiver",
					pendingCareDocs.filter(value => value.caregiver === currentUser.uid && value.issuedBy === currentUser.uid),
					supporting,
					null,
				)}
			</div>
			<NavLink
				className="my-2 flex h-[60px] w-[225px] items-center justify-center rounded-full bg-[#60AEF6] p-2 text-center font-bold text-white hover:text-blue-200"
				to="/app/CareUserSelect/Caregiver"
			>
				<p>+ Add a user to Support</p>
			</NavLink>
			{permissionsModal()}
		</div>
	);
};

export default CareManage;
