import React, {useState, useEffect, createContext, useMemo} from "react";
import PropTypes from "prop-types";
import {doc, onSnapshot} from "firebase/firestore";

import {firestore, auth} from "./firebase";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
	const [currentUser, setCurrentUser] = useState(undefined);
	const [careData, setCareData] = useState(undefined);
	const [customDocumentData, setCustomDocumentData] = useState(undefined);
	const [lastPermissionUpdate, setLastPermissionUpdate] = useState(0);
	const [selectedPalette, setSelectedPalette] = useState({});

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setCurrentUser(user);
		});

		return unsubscribe;
	}, []);

	useEffect(() => {
		if (currentUser && currentUser.uid) {
			const unSubCustomClaimsUpdate = onSnapshot(doc(firestore, "users", currentUser.uid, "userData", "care"), d => {
				if (d.exists()) {
					const tempCareData = d.data();
					if (tempCareData.lastPermissionsUpdate && tempCareData.lastPermissionsUpdate !== lastPermissionUpdate) {
						currentUser.getIdToken(true);
						setLastPermissionUpdate(tempCareData.lastPermissionsUpdate);
					}
					setCareData(tempCareData);
				}
			});

			return unSubCustomClaimsUpdate;
		}
		return undefined;
	}, [currentUser]);

	useEffect(() => {
		if (currentUser && currentUser.uid) {
			return onSnapshot(doc(firestore, "users", currentUser.uid, "userData", "custom"), d => {
				if (d.exists()) {
					const customDocData = d.data();
					setCustomDocumentData(customDocData);
					const {colorPalettesData} = customDocData;
					const {defaultPaletteKey, colorPalettes} = colorPalettesData;
					setSelectedPalette(colorPalettes[defaultPaletteKey]);
				}
			});
		}
		return undefined;
	}, [currentUser]);

	const authValues = useMemo(
		() => ({
			currentUser,
			careData,
			selectedPalette, // Providing selected palette
			customDocumentData,
		}),
		[currentUser, careData, selectedPalette, customDocumentData],
	);

	return <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
	children: PropTypes.element,
};
AuthProvider.defaultProps = {
	children: undefined,
};

export {AuthContext, AuthProvider};
