import React, {useState} from "react";
import PropTypes from "prop-types";
import InputContainer from "../../Components/InputContainer";
import CloseIcon from "../../../../../../resources/icons/Close.png";

const TagsPicker = ({label = "Tags", tags, setTags}) => {
	const [newTag, setNewTag] = useState("");
	const [modalOpen, setModalOpen] = useState(false);

	const AddTag = () => {
		if (newTag !== "" && tags.filter(item => item === newTag).length === 0) {
			const tempTagList = [...tags];
			tempTagList.push(newTag);
			setTags(tempTagList);
		}
		setNewTag("");
	};

	const removeTag = targetIndex => {
		const existingItem = tags[targetIndex];

		const isConfirmFlag = confirm(`Are you sure you want to remove the tag ${existingItem}`);

		if (isConfirmFlag) {
			const updatedItems = tags.filter((item, index) => index !== targetIndex);
			setTags(updatedItems);
		}
	};

	const renderTag = (tag, index) => (
		<div className="mx-0.5 my-1 flex max-h-[30px] overflow-hidden rounded-full border-2 border-blue-200 px-2" key={tag}>
			<span className="pr-1 font-semibold text-blue-400" id={tag} name="btntags">
				{tag}
			</span>
			<button
				type="button"
				className="font-semibold"
				id={`${tag}btnremove`}
				onClick={() => {
					removeTag(index);
				}}
			>
				x
			</button>
		</div>
	);

	return (
		<div>
			<InputContainer
				label={label}
				buttonLabel="Add Tags"
				buttonOnClick={() => {
					setModalOpen(true);
				}}
			>
				<div className="mx-0.5 my-1 flex-wrap !justify-start text-left">
					<button
						type="button"
						className="mr-1 max-h-[32px] min-w-[88px] rounded-full border-2 border-blue-200 bg-blue-200 px-1 py-0.5 font-semibold !text-white"
						onClick={() => {
							setModalOpen(true);
						}}
					>
						Add Tag +
					</button>
					{tags.map((currentElement, index) => renderTag(currentElement, index))}
				</div>
			</InputContainer>
			{modalOpen && (
				<div
					className="fixed inset-0 flex items-center justify-center font-bold"
					style={{backgroundColor: "rgba(0, 0, 0, 0.7)"}}
				>
					<div className="w-96 rounded-xl bg-white p-3">
						<div className="mt-1 flex justify-between">
							<h1 className="w-1/2 basis-1/2 text-left text-2xl font-bold">Add a Tag</h1>

							<div className="flex justify-end">
								<div className="flex items-center">
									<p>Close</p>
									<button
										type="button"
										className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
										onClick={() => {
											setModalOpen(false);
										}}
									>
										<img
											// className="place-self-center object-scale-down"
											src={CloseIcon}
											alt="close"
										/>
									</button>
								</div>
							</div>
						</div>
						<div className="w-full px-4 py-2">
							<textarea
								className="px w-full rounded-md border-2 pl-4 pt-6 text-lg font-bold placeholder:text-gray-200"
								type="text"
								value={newTag}
								id="txttag"
								key="txttag"
								onChange={text => setNewTag(text.target.value)}
								onKeyDown={event => {
									if (event.key === "Enter") {
										event.preventDefault();
										AddTag();
									}
								}}
								placeholder="Enter text, then tap Add"
							/>
						</div>
						<div className="mb-4 text-right">
							<button type="button" className="pr-0 font-bold text-blue-500" onClick={AddTag}>
								Add
							</button>
						</div>

						<div className="flex min-h-[100px] flex-wrap rounded-md border">
							{tags.map((currentElement, index) => renderTag(currentElement, index))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

TagsPicker.propTypes = {
	label: PropTypes.string,
	tags: PropTypes.arrayOf(PropTypes.string),
	setTags: PropTypes.func,
};
TagsPicker.defaultProps = {
	label: "Tags",
	tags: undefined,
	setTags: undefined,
};

export default TagsPicker;
