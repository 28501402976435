import {React} from "react";
import businessRoger from "../../../../../resources/images/04.png";

const AboutUs = () => (
	<div className="flex flex-col items-center px-4">
		<div className="max-w-4xl">
			<div>
				<h5 className="text-center">Our Vision</h5>
				<p>
					Our journey began by addressing a critical aspect of daily living: short-term memory. We realized that by
					creating a solution to support memory, we could make a significant difference in the lives of our users.
				</p>
			</div>
			<br />
			<div>
				<h5 className="text-center">Want2Remember - Empowering Memory</h5>
				<p>
					“Want2Remember” is not just an app; it&apos;s a companion that helps save memories for later recall while
					assisting in creating reminders for future events. We understand that the ability to recall past experiences
					and plan for the future is essential. However, we also emphasize the importance of living in the present,
					especially for those with cognitive impairments.
				</p>
			</div>
			<br />
			<div>
				<h5 className="text-center">Caregiver Support</h5>
				<p>
					We acknowledge the invaluable role that caregivers play in the lives of those with cognitive impairments.
					Often unpaid and dedicated, caregivers provide essential support for daily living tasks. We are designing our
					app to facilitate caregiver support and enable remote caregiving, making the journey smoother for both
					caregivers and those they care for.
				</p>
			</div>
			<br />
			<div>
				<h5 className="text-center">Our Commitment</h5>
				<p>
					Our commitment goes beyond technology; it extends to the well-being and dignity of our users. We2Link is not
					just about products; it&apos;s about a community that cares. We are continuously evolving, with the eventual
					goal of providing a comprehensive suite of daily living support features within a single app.
				</p>
			</div>
			<br />
			<p>
				Thank you for joining us on this journey to empower independence and enhance the lives of those with cognitive
				impairments. Together, we can make a difference, one memory at a time.
			</p>
			<br />
			<div className="flex justify-center">
				<img src={businessRoger} alt="business Roger" className="h-48" />
			</div>
		</div>
	</div>
);

export default AboutUs;
