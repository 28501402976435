import Colors from "./Colors";

export default {
	defaultMemoryTemplateColor: Colors.lightSilver,
	defaultMemoryTemplateTextColor: "#000000",
	status: ["Pending", "In Progress", "Delayed", "Stopped", "Done"],
	paymentMethods: ["Cash", "Check", "Credit Card", "Bank Transfer"],
	transactionTypes: ["Bill", "Purchase"],
	categories: ["Fact", "Event", "People", "Secure"],
	notificationTimes: [
		"None",
		"On Time",
		"5 Minutes Before",
		"10 Minutes Before",
		"15 Minutes Before",
		"30 Minutes Before",
		"1 Hour Before",
		"2 Hour Before",
	],
	reoccurrences: ["None", "Daily", "Weekly", "Monthly"],
	billTypeRecurrence: ["One Time", "Monthly", "Twice a Month", "Four Times a Year", "Twice a Year", "Yearly"],
	googleMapsAPIKey: "AIzaSyDfoStBsBgadB53aEylYxtuz9w8BPTxXBU",
	defaultLocationInfo: {
		locationType: "none",
		locationName: "",
		locationDetails: {
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			country: "",
		},
		apiResponse: {},
	},
	careSetupNavOptions: ["Search", "Confirm", "Agree"],
	carePermissions: {
		canEditMemories: "Edit Memories",
		canViewMemories: "View Memories",
		canEditContacts: "Edit Contacts",
		canViewContacts: "View Contacts",
	},
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	],
	contactSearchKeys: ["contactInfo.givenName", "contactInfo.familyName", "contactInfo.displayName", "name"],
	bannedFileExtensions: [
		"ade",
		"adp",
		"apk",
		"appx",
		"appxbundle",
		"bat",
		"cab",
		"chm",
		"cmd",
		"com",
		"cpl",
		"dll",
		"dmg",
		"ex",
		"ex_",
		"exe",
		"hta",
		"ins",
		"isp",
		"iso",
		"jar",
		"js",
		"jse",
		"lib",
		"lnk",
		"mde",
		"msc",
		"msi",
		"msix",
		"msixbundle",
		"msp",
		"mst",
		"nsh",
		"pif",
		"ps1",
		"scr",
		"sct",
		"shb",
		"sys",
		"vb",
		"vbe",
		"vbs",
		"vxd",
		"wsc",
		"wsf",
		"wsh",
		"zip",
	],
	webVersion: "v0.0.8b10",
};
