export default {
	primaryBlue: "#60aef5", // Roger the Fox -- Blue
	secondaryBlue: "#285286",
	lightSilver: "#b2bec3",
	primarySilver: "#bdc3c7",
	darkSilver: "#636e72",
	successGreen: "#00b894",
	confirmedGreen: "#4cd137",
	warning: "#fdcb6e",
	tabGrey: "#8e8e93",
	red: "#d63031",
	softRed: "#ff7675",
	addButton: "#0084FC",
	darkGrey: "#666666",
	mediumGrey: "#b4b4b4",
	lightGrey: "#bdbdbd",
	newBlue: "#2d8ce3",
	newBlueFaded: "#C5E1FB",
	lighterGrey: "#eaeaea",
	newGreyFaded: "#4f4f4f",
	backgroundGrey: "#EDEDED",
	cayenne: "#e0605c",
	celosia_orange: "#ed7a3c",
	dazzling_blue: "#0065ac",
	fressia: "#ffd600",
	hemlock: "#acd0b4",
	paloma: "#b4b8b3",
	placid_blue: "#92bcde",
	radiant_orchid: "#b670a8",
	sand: "#d5bc9c",
	violet_tulip: "#9d9cc9",
	mobileLinkBlue: "#4397e6",
};
