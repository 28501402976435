import React from "react";
import PropTypes from "prop-types";

const TextInput = ({label, type, value, maxLength, pattern, onChange}) => (
	<div>
		<div className="w-full rounded-md border-2 bg-white font-bold">
			<p className="w-full px-2 text-gray-400">{label}</p>
			<input
				className="w-full space-x-2 rounded-md text-lg font-bold"
				type={type}
				value={value}
				maxLength={maxLength}
				pattern={pattern}
				onChange={text => onChange(text.target.value)}
			/>
		</div>
	</div>
);

TextInput.propTypes = {
	label: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
	maxLength: PropTypes.number,
	pattern: PropTypes.string,
	onChange: PropTypes.func,
};
TextInput.defaultProps = {
	label: undefined,
	type: undefined,
	value: undefined,
	maxLength: undefined,
	pattern: undefined,
	onChange: undefined,
};

export default TextInput;
