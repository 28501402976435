import React from "react";
import PropTypes from "prop-types";
import InputContainer from "./InputContainer";

const OneLineTextPicker = ({
	inputLabel,
	inputPlaceholder,
	textInput,
	setTextInput,
	inputType = "text",
	multiline = false,
	readOnly = false,
}) => (
	<InputContainer label={inputLabel}>
		{multiline ? (
			<textarea
				className="mt-2 w-full pl-3 text-lg font-bold"
				rows="3"
				type={inputType}
				placeholder={inputPlaceholder}
				value={textInput}
				onChange={text => setTextInput(text.target.value)}
				readOnly={readOnly}
			/>
		) : (
			<input
				className="mt-2 w-full pl-3 text-lg font-bold"
				type={inputType}
				value={textInput}
				placeholder={inputPlaceholder}
				onChange={text => setTextInput(text.target.value)}
				readOnly={readOnly}
			/>
		)}
	</InputContainer>
);

OneLineTextPicker.propTypes = {
	inputLabel: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	textInput: PropTypes.string,
	setTextInput: PropTypes.func,
	inputType: PropTypes.string,
	multiline: PropTypes.bool,
	readOnly: PropTypes.bool,
};
OneLineTextPicker.defaultProps = {
	inputLabel: undefined,
	inputPlaceholder: undefined,
	textInput: undefined,
	setTextInput: undefined,
	inputType: "text",
	multiline: false,
	readOnly: false,
};

export default OneLineTextPicker;
