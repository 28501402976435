import React from "react";
import {NavLink} from "react-router-dom";

import {FaHome, FaAddressBook, FaClinicMedical, FaPlus, FaPencilAlt} from "react-icons/fa";

const NavLinkStyle =
	"flex justify-center sm:justify-start grow items-center mb-2 bg-white rounded-lg p-2 mx-2 text-slate-500 hover:text-sky-600";

/* import styles from "./SidebarMenu.module.css"; */

const SidebarMenu = () => (
	<div>
		{/*	<nav className={styles.sidebar}>
			<NavLink to="/app" className={styles.navLink}>	*/}

		<nav className="flex flex-row flex-wrap sm:flex-col">
			<NavLink to="/app" className={NavLinkStyle}>
				<FaHome title="House Icon" />
				<p className="pl-2 font-bold">Home</p>
			</NavLink>

			{/* <NavLink to="/app/MemoryTemplates" className={styles.navLink}> */}

			<NavLink to="/app/MemoryTemplates" className={NavLinkStyle}>
				<FaPlus title="Add Memory Icon" />
				<p className="pl-2 font-bold">Create Memories</p>
			</NavLink>

			<NavLink to="/app/CreateTemplate" className={NavLinkStyle}>
				<FaPencilAlt title="Tempplate" />
				<p className="font-bold pl-2">Template Creator</p>
			</NavLink>

			{/* <NavLink to="/app/Contacts" className={styles.navLink}> */}

			<NavLink to="/app/Contacts" className={NavLinkStyle}>
				<FaAddressBook title="Address Book Icon" />
				<p className="pl-2 font-bold">Contacts</p>
			</NavLink>

			{/* <NavLink to="/app/CareManage" className={styles.navLink}> */}

			<NavLink to="/app/CareManage" className={NavLinkStyle}>
				<FaClinicMedical title="Care Building Icon" />
				<p className="pl-2 font-bold">Care Support</p>
			</NavLink>
		</nav>
	</div>
);
export default SidebarMenu;
