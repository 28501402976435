import React, {useState, useEffect, useContext, useMemo} from "react";
import {useNavigate, useLocation, useSearchParams} from "react-router-dom";
import {FaArrowLeft, FaPencilAlt, FaShare} from "react-icons/fa";
import {IconContext} from "react-icons";
import {doc, deleteDoc, onSnapshot} from "firebase/firestore";

import {updateMemory} from "../../utilities/firestoreUtilities";
import {schemas, memoryTypeIds} from "../../../../../resources/schemas";
import {AuthContext} from "../../AuthProvider";
import {firestore} from "../../firebase";
import Colors from "../../../../../resources/Colors";
import MemoryFieldDisplay from "./MemoryFieldDisplay";
import StatusModal from "./Components/StatusModal";
import DateDisplay from "./Components/DateDisplay";
import FilePreview from "./Components/FilePreview";
import ValueOptions from "../../../../../resources/ValueOptions";

import Delayed from "../../../../../resources/icons/Status/Delayed.png";
import Done from "../../../../../resources/icons/Status/Done.png";
import InProgress from "../../../../../resources/icons/Status/InProgress.png";
import Pending from "../../../../../resources/icons/Status/Pending.png";
import Stopped from "../../../../../resources/icons/Status/Stopped.png";
import ShareModal from "../Components/Modals/ShareModal";

const {defaultMemoryTemplateColor, defaultMemoryTemplateTextColor} = ValueOptions;

const STATUS_ICONS = {
	"Delayed": Delayed,
	"Done": Done,
	"In Progress": InProgress,
	"Pending": Pending,
	"Stopped": Stopped,
};

const DisplayMemoryInformation = () => {
	const navigate = useNavigate();
	const {pathname, search} = useLocation();
	const [searchParams] = useSearchParams();
	const {currentUser, selectedPalette} = useContext(AuthContext);

	const memoryId = searchParams.get("memoryId");

	const [memoryInfo, setMemoryInfo] = useState({});
	const [memoryType, setMemoryType] = useState({});
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [isPublic, setIsPublic] = useState(false);
	const [showShareModal, setShowShareModal] = useState(false);

	const memoryFields = [];

	useEffect(() => {
		const unsubscribe = onSnapshot(
			doc(firestore, `memories/${memoryId}`),
			docSnapshot => {
				if (!docSnapshot.exists()) {
					console.log("Memory does not exist!");
					navigate("/ErrorPage"); // Adjust as needed
					return;
				}

				const data = docSnapshot.data();
				const memoryIsPublic = Boolean(data.isPublic);

				if (!memoryIsPublic && (!currentUser?.uid || !data?.viewers?.includes(currentUser.uid))) {
					console.log("Unauthorized access attempt to a private memory.");
					navigate("/SignIn"); // Adjust as needed
					return;
				}

				setMemoryInfo(data);
				setIsPublic(memoryIsPublic);
				let memTypeId = null;
				if (!data.typeOfMemoryId && data.typeOfMemory) {
					memTypeId = memoryTypeIds.find(memId => schemas[memId].displayName === data.typeOfMemory);
				} else {
					memTypeId = data.typeOfMemoryId;
				}
				setMemoryType(schemas[memTypeId] ?? data?.schema);
			},
			error => {
				console.warn(error);
				if (!currentUser?.uid)
					navigate(`/SignIn/?path=${pathname}/${search}`); // Adjust as needed
				else navigate("/app");
			},
		);

		return () => unsubscribe(); // Clean up on unmount
	}, [currentUser, memoryId, navigate]);

	useEffect(() => {
		console.log("memoryInfo:", JSON.stringify(memoryInfo, null, "\t"));
	}, [memoryInfo]);

	const goBack = () => {
		navigate(-1);
	};

	const editMemory = () => {
		if (currentUser?.uid && memoryInfo && memoryInfo.editors.includes(currentUser.uid)) {
			navigate(`/app/CreateMemory/?memoryType=${memoryInfo.typeOfMemory}&memoryId=${memoryId}`);
		}
	};

	const deleteMemory = () => {
		if (currentUser?.uid && memoryInfo && memoryInfo.editors.includes(currentUser.uid)) {
			deleteDoc(doc(firestore, "memories", `${memoryId}`));
			navigate("/app");
		}
	};

	const handleStatusChange = status => {
		const data = {
			memory: {
				status,
			},
		};
		updateMemory(memoryId, data);
		setShowStatusModal(false);
	};

	if (memoryType && memoryType.componentDisplayOrder) {
		memoryType.componentDisplayOrder.forEach(component => {
			const field = memoryType.fields[component];
			const {label} = field;
			const {type} = field;
			if (type === "STATUS") {
				memoryFields.push(
					<MemoryFieldDisplay
						key={component}
						fieldType={type}
						memoryInfo={memoryInfo}
						onClick={() => {
							setShowStatusModal(true);
						}}
						fieldId={component}
						fieldName={label}
					/>,
				);
			} else {
				memoryFields.push(
					<MemoryFieldDisplay
						key={component}
						fieldType={type}
						memoryInfo={memoryInfo}
						fieldId={component}
						fieldName={label}
					/>,
				);
			}
		});
	}

	const backgroundColor =
		selectedPalette?.colors?.[memoryInfo?.typeOfMemoryId] ??
		memoryInfo?.schema?.backgroundColor ??
		defaultMemoryTemplateColor;
	const textColor =
		selectedPalette?.textColors?.[memoryInfo?.typeOfMemoryId] ??
		memoryInfo?.schema?.textColor ??
		defaultMemoryTemplateTextColor;

	return (
		<>
			{showShareModal && (
				<ShareModal
					setShowShareModal={setShowShareModal}
					isPublic={isPublic}
					setIsPublic={setIsPublic}
					memoryId={memoryId}
				/>
			)}
			<div style={{backgroundColor: Colors.backgroundGrey}}>
				{showStatusModal ? (
					<StatusModal
						onSelectStatus={handleStatusChange}
						selectedStatus={memoryInfo?.memory?.status ? memoryInfo.memory.status : ""}
						closeModal={() => {
							setShowStatusModal(false);
						}}
					/>
				) : null}
				<div
					style={{
						backgroundColor,
						color: textColor,
					}}
					className="w-full flex-col rounded-br-[100px] px-5"
				>
					<div
						className="flex justify-between pt-5"
						style={{
							visibility: currentUser?.uid && memoryInfo?.editors?.includes(currentUser.uid) ? "visible" : "hidden",
						}}
					>
						<button type="button" onClick={goBack}>
							<IconContext.Provider
								value={useMemo(
									() => ({
										color: textColor,
										size: "24px",
									}),
									[],
								)}
							>
								<FaArrowLeft />
							</IconContext.Provider>
							Back
						</button>
						<div className="flex space-x-2 gap-5">
							<button
								type="button"
								onClick={() => {
									setShowShareModal(true);
								}}
							>
								<IconContext.Provider
									value={useMemo(
										() => ({
											color: textColor,
											size: "24px",
										}),
										[],
									)}
								>
									<FaShare />
								</IconContext.Provider>
								Share
							</button>
							<button type="button" onClick={editMemory}>
								<IconContext.Provider
									value={useMemo(
										() => ({
											color: textColor,
											size: "24px",
										}),
										[],
									)}
								>
									<FaPencilAlt />
								</IconContext.Provider>
								Edit
							</button>
						</div>
					</div>
					<h1 className="py-5 text-4xl">{memoryInfo.title}</h1>
					<div className="flex justify-between">
						<p className="py-5 font-light">{memoryInfo.typeOfMemory}</p>
						{memoryInfo?.memory?.status ? (
							<div className="flex items-center justify-center space-x-2">
								<img src={STATUS_ICONS[memoryInfo.memory.status]} alt="status" />
								<p>{memoryInfo.memory.status}</p>
							</div>
						) : null}
					</div>
					{memoryInfo.categories ? (
						<div className="flex flex-row flex-wrap pb-4">
							{memoryInfo.categories.map((category, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<div key={index}>
									<p className="mb-2 mr-2 rounded-3xl border border-black px-5 py-2">{category}</p>
								</div>
							))}
						</div>
					) : (
						<div className="h-14" />
					)}
				</div>
				<div className="flex flex-col p-10 font-medium">{memoryFields}</div>
				<FilePreview files={memoryInfo.files} />
				{memoryInfo.dateCreated && <DateDisplay label="Date Created" date={memoryInfo.dateCreated} />}
				{memoryInfo.lastUpdated && <DateDisplay label="Last Updated" date={memoryInfo.lastUpdated} />}
				<div
					className="flex justify-center space-x-12 py-12"
					style={{
						visibility: currentUser?.uid && memoryInfo?.editors?.includes(currentUser.uid) ? "visible" : "hidden",
					}}
				>
					<button
						type="button"
						className="h-12 w-36 rounded-[20px] bg-red-500 font-medium text-white"
						onClick={deleteMemory}
					>
						Delete
					</button>
					<button
						type="button"
						className="h-12 w-36 rounded-[20px] bg-blue-500 font-medium text-white"
						onClick={editMemory}
					>
						Edit
					</button>
				</div>
			</div>
		</>
	);
};

export default DisplayMemoryInformation;
