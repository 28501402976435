/* eslint-disable react/no-array-index-key */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import InputContainer from "../../Components/InputContainer";
import happy from "../../../../../../resources/icons/Emoji/Happy.png";
import laugh from "../../../../../../resources/icons/Emoji/Laugh.png";
import love from "../../../../../../resources/icons/Emoji/Love.png";
import sad from "../../../../../../resources/icons/Emoji/Sad.png";
import smile from "../../../../../../resources/icons/Emoji/Smile.png";
import CheckMark from "../../../../../../resources/icons/BlueCheckmark.png";

const EMOJI_DATA = [
	{
		type: "Happy",
		image: happy,
	},
	{
		type: "Laugh",
		image: laugh,
	},
	{
		type: "Love",
		image: love,
	},
	{
		type: "Sad",
		image: sad,
	},
	{
		type: "Smile",
		image: smile,
	},
];

const EmojiList = ({emojis}) => (
	<div className="flex flex-row">
		{EMOJI_DATA.map(({type, image}, index) =>
			emojis && emojis[type] ? (
				<div className="mr-2 flex flex-col items-center text-xs font-medium" key={index}>
					<div>
						<img className="h-6" src={image} alt="emoji" />
					</div>
					<p>{type}</p>
				</div>
			) : null,
		)}
	</div>
);

EmojiList.propTypes = {
	emojis: PropTypes.object,
};
EmojiList.defaultProps = {
	emojis: undefined,
};

const Modal = ({setOpenModal, pressedEmoji, emojis}) => (
	<div className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center overflow-auto border-inherit bg-black bg-opacity-75">
		<div className="h-auto w-auto rounded-lg border-2 border-white bg-white p-5">
			<div className="mr-5px ml-auto flex p-2 font-bold">
				<h1>Emojis</h1>
				<button type="button" className="mr-5px ml-auto font-bold" onClick={() => setOpenModal(false)}>
					X (Close)
				</button>
			</div>

			<div
				style={{
					margin: "40px",
					display: "grid",
					grid: "100px 100px / repeat(3, 100px)",
					gap: "30px",
				}}
			>
				{EMOJI_DATA.map(({type, image}, index) => (
					<div key={index}>
						<button
							type="button"
							onClick={() => {
								pressedEmoji(type);
							}}
						>
							<div className="flex flex-row font-bold">
								<div>
									<img src={image} alt="emoji" />
									{type}
								</div>
								{emojis && emojis[type] === true ? (
									<img src={CheckMark} className="h-7 w-7" alt="checkmark" />
								) : (
									<div className="h-7 w-7" />
								)}
							</div>
						</button>
					</div>
				))}
			</div>
		</div>
	</div>
);

Modal.propTypes = {
	setOpenModal: PropTypes.func,
	pressedEmoji: PropTypes.func,
	emojis: PropTypes.object,
};
Modal.defaultProps = {
	setOpenModal: undefined,
	pressedEmoji: undefined,
	emojis: undefined,
};

const EmojiPicker = ({label, emojis, setEmojis}) => {
	const [openModal, setOpenModal] = useState(false);
	const [anyEmojisSelected, setAnyEmojisSelected] = useState(false);

	const pressedEmoji = emojiType => {
		const tempEmojis = {...emojis};
		tempEmojis[emojiType] = !tempEmojis[emojiType];
		setEmojis(tempEmojis);
	};

	useEffect(() => {
		const emojisKeys = Object.keys(emojis ?? {});
		let emojiIsSelected = false;
		for (let i = 0; i < emojisKeys.length; i++) {
			const currentKey = emojisKeys[i];
			if (emojis[currentKey]) {
				emojiIsSelected = true;
				break;
			}
		}
		setAnyEmojisSelected(emojiIsSelected);
	}, [emojis]);

	return (
		<div>
			<InputContainer label={label}>
				<div>
					{anyEmojisSelected ? <EmojiList emojis={emojis} /> : <p>None</p>}
					<button
						type="button"
						onClick={() => {
							setOpenModal(true);
						}}
					>
						<p>Tap Here to add emoji(s)!</p>
					</button>
				</div>
			</InputContainer>

			{openModal && <Modal setOpenModal={setOpenModal} pressedEmoji={pressedEmoji} emojis={emojis} />}
		</div>
	);
};

EmojiPicker.propTypes = {
	label: PropTypes.string,
	emojis: PropTypes.object,
	setEmojis: PropTypes.func,
};
EmojiPicker.defaultProps = {
	label: "Emojis",
	emojis: undefined,
	setEmojis: undefined,
};

export default EmojiPicker;
