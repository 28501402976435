import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {ref, uploadBytesResumable} from "firebase/storage";

import {storage} from "../../../firebase";
import CloseIcon from "../../../../../../resources/icons/Close.png";
import ProgressBar from "../Dynamic/ProgressBar";

const ImportModal = ({
	handleCloseModal,
	validateFile,
	warningMessage,
	successMessage,
	children,
	accept,
	destination,
}) => {
	const [showProgress, setShowProgress] = useState(false);
	const [uploadTask, setUploadTask] = useState(null);
	const [progress, setProgress] = useState(0);
	const [progressText, setProgressText] = useState("");
	const [userFile, setUserFile] = useState(null);
	const [successful, setSuccessful] = useState(false);

	useEffect(() => {
		if (successful) {
			setSuccessful(false);
			setTimeout(() => {
				if (successMessage) alert(successMessage);
				setProgress(0);
				handleCloseModal();
			}, 250);
		}
	}, [successful]);

	// Handles the file upload
	const handleFileUpload = () => {
		if (userFile) {
			if (!userFile || !userFile.type || (validateFile && !validateFile.test(userFile.type))) {
				if (warningMessage) alert(warningMessage);
				return;
			}
			const cleanedFileName = userFile.name.replace(/\s/g, "_");
			const storageRef = ref(storage, `${destination}/${cleanedFileName}`);
			const task = uploadBytesResumable(storageRef, userFile);
			task.on(
				"state_changed",
				snapshot => {
					const newProgress = snapshot.bytesTransferred / snapshot.totalBytes;
					setProgress(newProgress);
					setProgressText(`${Math.floor(newProgress * 100)}%`);
				},
				error => {
					console.log(error);
					setProgressText("Upload failed");
					setProgress(0);
					setUploadTask(null);
				},
				() => {
					setProgressText("Upload Successful");
					setUserFile(null);
					setSuccessful(true);
					setUploadTask(null);
				},
			);
			setUploadTask(task);
			setShowProgress(true);
		} else {
			alert("No file selected!");
		}
	};

	const cancelUpload = () => {
		if (uploadTask) uploadTask.cancel();
		if (userFile) setUserFile(null);
		setProgress(0);
		setUploadTask(null);
	};

	return (
		<div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center overflow-y-hidden bg-neutral-500 bg-opacity-25">
			<div className="flex w-full max-w-sm flex-col rounded-md bg-gray-100 p-3">
				<div className="flex flex-row items-center justify-end">
					<p>Close</p>
					<button
						type="button"
						className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
						onClick={() => {
							if (uploadTask && progress >= 0 && progress < 1) {
								cancelUpload();
							}
							handleCloseModal();
						}}
					>
						<img className="h-8 w-8 place-self-center object-scale-down" src={CloseIcon} alt="close" />
					</button>
				</div>
				{children}
				{!showProgress && (
					<div className="mt-4 flex flex-row justify-center">
						<input
							className="truncate text-sm"
							type="file"
							onChange={e => {
								setUserFile(e.target.files[0]);
							}}
							accept={accept}
						/>
					</div>
				)}

				{showProgress && (
					<div className="mt-2 flex flex-col items-center">
						<ProgressBar progress={progress} />
						<p>{progressText}</p>
					</div>
				)}
				{userFile && (
					<button
						type="button"
						className={`mt-2 w-fit place-self-center rounded-md px-2 text-white ${
							showProgress ? "bg-red-500" : "bg-blue-400"
						}`}
						onClick={() => {
							if (uploadTask) {
								cancelUpload();
							} else {
								handleFileUpload();
							}
						}}
					>
						{showProgress ? "Cancel Upload" : "Upload"}
					</button>
				)}
			</div>
		</div>
	);
};

ImportModal.propTypes = {
	handleCloseModal: PropTypes.func,
	validateFile: PropTypes.object,
	warningMessage: PropTypes.string,
	successMessage: PropTypes.string,
	children: PropTypes.element,
	accept: PropTypes.string,
	destination: PropTypes.string,
};
ImportModal.defaultProps = {
	handleCloseModal: undefined,
	validateFile: undefined,
	warningMessage: undefined,
	successMessage: undefined,
	children: undefined,
	accept: undefined,
	destination: undefined,
};

export default ImportModal;
