import React, {useState, useEffect, useContext} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import PropTypes from "prop-types";
import {v4 as uuidv4} from "uuid";
import {hexToHsva, hsvaToHex} from "@uiw/color-convert";
import {Wheel, ShadeSlider} from "@uiw/react-color";
import {collection, doc, getDoc, deleteDoc} from "firebase/firestore";
import {cloneDeep, isArray, isEqual} from "lodash";
import {AiOutlineMinus} from "react-icons/ai";
import {BiSolidUpArrow, BiSolidDownArrow} from "react-icons/bi";

import {AuthContext} from "../../AuthProvider";
import MemoryField from "./MemoryField";
import {fieldTypes} from "../../../../../resources/schemas";
import Colors from "../../../../../resources/Colors";
import {firestore} from "../../firebase";
import {setMemoryTemplate, updateMemoryTemplate} from "../../utilities/firestoreUtilities";
import {removeAllEmpty} from "../../../../../resources/commonUtilities";

import TempSelectionDropDown from "./DropDown";

import styles from "./CreateMemoryTemplate.module.css";

// default labels for each component in schema
const DEFAULT_COMPONENT_LABELS = {
	[fieldTypes.date]: "Date",
	[fieldTypes.time]: "Time",
	[fieldTypes.status]: "Status",
	[fieldTypes.text]: "Single Line Text",
	[fieldTypes.doubleText]: "Double Line Text",
	[fieldTypes.tags]: "Tags",
	[fieldTypes.emoji]: "Emoji",
	[fieldTypes.phoneNumber]: "Phone Number",
	[fieldTypes.emailAddress]: "Email Address",
	[fieldTypes.url]: "URL",
	[fieldTypes.password]: "Password",
	[fieldTypes.currency]: "Currency",
	[fieldTypes.location]: "Location",
	[fieldTypes.people]: "People",
	// [fieldTypes.payments]: "Payments",
	[fieldTypes.paymentMethod]: "Payment Method",
	[fieldTypes.transactionType]: "Transaction Type",
	[fieldTypes.tasks]: "Tasks",
	[fieldTypes.simpleTasks]: "Simple Tasks",
};

// default values for components added to schema
const DEFAULT_COMPONENT_VALUES = {
	[fieldTypes.date]: "now",
	[fieldTypes.doubleText]: [{}],
	[fieldTypes.tasks]: [{}],
	[fieldTypes.simpleTasks]: [{}],
};

// default component options for the schema
const DEFAULT_COMPONENT_OPTIONS = {
	[fieldTypes.doubleText]: {
		titleLabel: "Subject",
		titlePlaceHolder: "Write Subject",
		contentLabel: "Description",
		contentPlaceHolder: "Write Description",
	},
};

// order for how components are displayed in template creation screen
const DEFAULT_COMPONENTS_ORDER = [
	fieldTypes.date,
	fieldTypes.time,
	fieldTypes.status,
	fieldTypes.text,
	fieldTypes.doubleText,
	fieldTypes.tags,
	fieldTypes.emoji,
	fieldTypes.phoneNumber,
	fieldTypes.emailAddress,
	fieldTypes.url,
	fieldTypes.password,
	fieldTypes.currency,
	fieldTypes.location,
	fieldTypes.people,
	// fieldTypes.payments,
	fieldTypes.paymentMethod,
	fieldTypes.transactionType,
	fieldTypes.simpleTasks,
	fieldTypes.tasks,
];

// settings for components that are addable to a memory quick look
const ADDABLE_TO_QUICK_LOOK = {
	[fieldTypes.date]: [
		{
			label: "Quick Look",
			key: "date",
			type: "array",
			autoAdd: true,
		},
	],
	[fieldTypes.time]: [
		{
			label: "Quick Look",
			key: "time",
			type: "array",
			autoAdd: true,
		},
		{
			label: "Start Time",
			key: "startTime",
			type: "string",
			cantMatch: ["endTime"],
			minTypeCountForDisplay: 2,
		},
		{
			label: "End Time",
			key: "endTime",
			type: "string",
			cantMatch: ["startTime"],
			minTypeCountForDisplay: 2,
		},
	],
	[fieldTypes.status]: [
		{
			label: "Quick Look",
			key: "status",
			type: "string",
			autoAdd: true,
		},
	],
	[fieldTypes.url]: [
		{
			label: "Quick Look",
			key: "url",
			type: "string",
		},
	],
	[fieldTypes.location]: [
		{
			label: "Quick Look",
			key: "location",
			type: "string",
		},
	],
	[fieldTypes.people]: [
		{
			label: "Quick Look",
			key: "people",
			type: "string",
			autoAdd: true,
		},
	],
	[fieldTypes.tasks]: [
		{
			label: "Quick Look",
			key: "tasks",
			type: "string",
			autoAdd: true,
		},
	],
	[fieldTypes.simpleTasks]: [
		{
			label: "Quick Look",
			key: "simpleTasks",
			type: "string",
			autoAdd: true,
		},
	],
};

// will limit the maximum quantity of specific types of components allowed
const MAX_COMPONENT_QUANTITY = {
	[fieldTypes.status]: 1,
	[fieldTypes.tags]: 1,
};

// fixes ordering of elements of arrays in quickLook object based on componentOrder array
const fixQuickLookOrdering = (quickLook, componentOrder) => {
	if (quickLook && componentOrder && isArray(componentOrder)) {
		Object.keys(quickLook).forEach(k => {
			if (isArray(quickLook[k])) {
				const ogArr = quickLook[k];
				const sortedIds = componentOrder.filter(id => ogArr.includes(id));
				quickLook[k] = sortedIds;
			}
		});
	}
};

const ColorSelectionModal = ({name, color, textColor, setColors, setShowModal}) => {
	const [tempColor, setTempColor] = useState(color);
	const [tempTextColor, setTempTextColor] = useState(textColor);

	const applyChanges = () => {
		setColors({color: tempColor, textColor: tempTextColor});
		setShowModal(false);
	};

	return (
		<div className={styles.colorSelectionModalContentContainer}>
			<button
				type="button"
				onClick={() => setShowModal(false)}
				style={{
					border: "2px solid red",
					borderRadius: "50%",
					width: "30px",
					height: "30px",
					backgroundColor: "transparent",
					color: "red",
					fontWeight: "bold",
					fontSize: "16px",
					cursor: "pointer",
				}}
			>
				X
			</button>
			<div className="flex h-3/5 w-full">
				<div className="flex h-full w-1/3 flex-col">
					<div className="flex h-1/2 w-full flex-col items-center justify-center space-y-2 py-2">
						<p className="text-slate-600">Preview</p>
						<div
							className="flex h-16 w-16 items-center justify-center rounded-full border-2 text-center"
							style={{backgroundColor: tempColor}}
						>
							<p
								style={{
									fontSize: "9px",
									color: tempTextColor,
								}}
							>
								{name || ColorSelectionModal.defaultProps.name}
							</p>
						</div>
					</div>
					<div className="flex h-1/2 w-full flex-col items-center justify-center space-y-2">
						<p className="text-xs">Text Color</p>
						<div className="flex h-1/2 w-full flex-row items-center justify-center">
							<div
								className="flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-transparent"
								style={{
									border: tempTextColor === "#000000" ? `3px solid ${Colors.primaryBlue}` : "0px",
								}}
								role="presentation"
								onClick={() => {
									setTempTextColor("#000000");
								}}
								onKeyDown={() => {
									setTempTextColor("#000000");
								}}
							>
								<div className="h-8 w-8 rounded-full" style={{backgroundColor: "black"}} />
							</div>
							<div
								className="flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-transparent"
								style={{
									border: tempTextColor !== "#000000" ? `3px solid ${Colors.primaryBlue}` : "0px",
								}}
								role="presentation"
								onClick={() => {
									setTempTextColor("#ffffff");
								}}
								onKeyDown={() => {
									setTempTextColor("#ffffff");
								}}
							>
								<div
									className="h-8 w-8 rounded-full"
									style={{
										border: "2px solid gray",
										backgroundColor: "white",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-2/3 flex-col items-center justify-center">
					<div className=" space-y-2">
						<Wheel
							color={hexToHsva(tempColor)}
							onChange={newColor => {
								setTempColor(hsvaToHex(newColor.hsva));
							}}
							height={125}
							width={125}
						/>
						<ShadeSlider
							hsva={hexToHsva(tempColor)}
							onChange={newShade => {
								const prevColor = tempColor;
								const newColor = hsvaToHex({
									...hexToHsva(prevColor),
									...newShade,
								});
								setTempColor(newColor);
							}}
						/>
					</div>
				</div>
			</div>
			<button
				type="button"
				onClick={applyChanges}
				className=" rounded-3xl border-2 border-solid border-blue-200 bg-white p-2 text-sm font-bold hover:border-blue-800 active:border-blue-200"
			>
				Apply
			</button>
		</div>
	);
};
ColorSelectionModal.propTypes = {
	name: PropTypes.string,
	color: PropTypes.string,
	textColor: PropTypes.string,
	setColors: PropTypes.func.isRequired,
	setShowModal: PropTypes.func.isRequired,
};

ColorSelectionModal.defaultProps = {
	name: "Memory Template",
	color: Colors.primaryBlue,
	textColor: "#000000",
};

const CreateTemplateScreen = () => {
	const {currentUser} = useContext(AuthContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const schemaId = searchParams.get("schemaId");

	const [showColorModal, setShowColorModal] = useState(false);

	const [initialValues, setInitialValues] = useState({});
	const [prevValues, setPrevValues] = useState({});
	const [tempValues, setTempValues] = useState({});
	const [combinedValues, setCombinedValues] = useState({...prevValues, ...tempValues});

	const [typeCount, setTypeCount] = useState({});
	const [canSave, setCanSave] = useState(false);

	// useEffect(() => {
	// 	console.log("tempValues:", JSON.stringify(tempValues, null, "\t"));
	// }, [tempValues]);

	useEffect(() => {
		if (!schemaId) {
			const colRef = collection(firestore, `memoryTemplates`);
			const newTemplateId = doc(colRef).id;
			const schemaData = {
				fields: {},
				componentDisplayOrder: [],
				backgroundColor: Colors.primaryBlue,
				textColor: "#000000",
				displayName: "",
				id: newTemplateId,
				quickLook: {},
				defaults: {},
				viewers: [currentUser.uid],
				editors: [currentUser.uid],
			};
			setTempValues(schemaData);
			setInitialValues(cloneDeep(schemaData));
		} else {
			const schemaDocRef = doc(firestore, "memoryTemplates", schemaId);
			getDoc(schemaDocRef).then(schemaDoc => {
				if (schemaDoc.exists) {
					const data = schemaDoc.data();
					setPrevValues(data);
					setInitialValues(cloneDeep(data));
				}
			});
		}
	}, [schemaId]);

	// merges prevValues and tempValues into combinedValues so that all data can be displayed to user
	useEffect(() => {
		const mergedData = {...prevValues, ...tempValues};
		if (prevValues.fields && tempValues.fields) {
			mergedData.fields = {...prevValues.fields, ...tempValues.fields};
		}
		if (prevValues.quickLook && tempValues.quickLook) {
			mergedData.quickLook = {...prevValues.quickLook, ...tempValues.quickLook};
		}
		if (prevValues.defaults && tempValues.defaults) {
			mergedData.defaults = {...prevValues.defaults, ...tempValues.defaults};
		}
		console.log("mergedData:", JSON.stringify(mergedData, null, "\t"));
		setCombinedValues(mergedData);
	}, [prevValues, tempValues]);

	useEffect(() => {
		// check if user is allowed to save
		if (
			!isEqual(removeAllEmpty(initialValues), removeAllEmpty(combinedValues)) &&
			combinedValues?.componentDisplayOrder?.length > 0 &&
			Object.keys(combinedValues?.fields).length > 0 &&
			combinedValues?.displayName
		)
			setCanSave(true);
		else setCanSave(false);
	}, [initialValues, combinedValues]);

	useEffect(() => {
		const tempTypeCount = {};
		if (combinedValues?.componentDisplayOrder) {
			combinedValues.componentDisplayOrder.forEach(id => {
				const type = combinedValues?.fields?.[id]?.type;
				if (type) {
					if (!Object.prototype.hasOwnProperty.call(tempTypeCount, type)) tempTypeCount[type] = 0;
					tempTypeCount[type] += 1;
				}
			});
		}
		setTypeCount(tempTypeCount);
	}, [combinedValues]);

	// adds a component to the memory schema
	const addComponent = type => {
		const temp = cloneDeep(tempValues);
		const compId = uuidv4();
		// adds component to fields
		if (!Object.prototype.hasOwnProperty.call(temp, "fields")) {
			temp.fields = {};
		}
		temp.fields[compId] = {
			label: DEFAULT_COMPONENT_LABELS[type],
			type,
		};
		// adds default component configuration
		if (DEFAULT_COMPONENT_OPTIONS[type]) {
			temp.fields[compId].options = cloneDeep(DEFAULT_COMPONENT_OPTIONS[type]);
		}
		// adds component to display order
		if (!Object.prototype.hasOwnProperty.call(temp, "componentDisplayOrder")) {
			temp.componentDisplayOrder = combinedValues?.componentDisplayOrder
				? [...combinedValues.componentDisplayOrder]
				: [];
		}
		temp.componentDisplayOrder.push(compId);
		// adds in default value to component
		if (DEFAULT_COMPONENT_VALUES[type]) {
			if (!Object.prototype.hasOwnProperty.call(temp, "defaults")) {
				temp.defaults = {};
			}
			if (!Object.prototype.hasOwnProperty.call(temp.defaults, "memory")) {
				temp.defaults.memory = {};
			}
			temp.defaults.memory[compId] = DEFAULT_COMPONENT_VALUES[type];
		}
		// automatically adds component to quickLook
		if (ADDABLE_TO_QUICK_LOOK[type]) {
			let addedToQuickLook = false;
			ADDABLE_TO_QUICK_LOOK[type].forEach(q => {
				if (q.autoAdd) {
					if (!Object.prototype.hasOwnProperty.call(temp, "quickLook")) {
						temp.quickLook = {};
					}
					addedToQuickLook = true;
					switch (q.type) {
						case "string":
							// if not already set, set to component id
							if (!temp.quickLook[q.key]) temp.quickLook[q.key] = compId;
							break;
						case "array":
							if (!isArray(temp.quickLook[q.key])) temp.quickLook[q.key] = [];
							temp.quickLook[q.key].push(compId);
							break;
						default:
							break;
					}
				}
			});
			if (addedToQuickLook) fixQuickLookOrdering(temp?.quickLook, temp?.componentDisplayOrder);
		}
		setTempValues(temp);
	};

	// moves element in componentDisplayOrder by a certain amount and adjusts any quick look options that will need adjusting
	const moveComponent = (compId, incr) => {
		const temp = cloneDeep(tempValues);
		if (!temp?.componentDisplayOrder) temp.componentDisplayOrder = [...combinedValues.componentDisplayOrder];
		if (temp?.componentDisplayOrder && isArray(temp.componentDisplayOrder)) {
			const index = temp.componentDisplayOrder.indexOf(compId);
			if (index + incr >= 0 && index + incr < temp.componentDisplayOrder.length) {
				temp.componentDisplayOrder.splice(index, 1);
				temp.componentDisplayOrder.splice(index + incr, 0, compId);
			}
		}
		if (!temp?.quickLook) temp.quickLook = cloneDeep(combinedValues.quickLook);
		fixQuickLookOrdering(temp?.quickLook, temp?.componentDisplayOrder);
		setTempValues(temp);
	};

	// removes component from schema
	const removeComponent = compId => {
		const temp = cloneDeep(tempValues);
		if (!temp?.fields) temp.fields = cloneDeep(combinedValues.fields);
		if (!temp?.componentDisplayOrder) temp.componentDisplayOrder = [...combinedValues.componentDisplayOrder];
		if (!temp?.quickLook && prevValues?.quickLook) temp.quickLook = cloneDeep(combinedValues.quickLook);
		if (!temp?.defaults && prevValues?.defaults) temp.defaults = cloneDeep(combinedValues.defaults);
		const compType = temp?.fields?.[compId]?.type;
		const newTypeCount = typeCount[compType] ?? 1 - 1;
		// remove from fields
		if (temp?.fields?.[compId]) {
			if (prevValues?.fields?.[compId]) temp.fields[compId] = null;
			else delete temp.fields[compId];
		}
		// remove from component display order
		if (temp?.componentDisplayOrder && temp.componentDisplayOrder.includes(compId)) {
			const index = temp.componentDisplayOrder.indexOf(compId);
			temp.componentDisplayOrder.splice(index, 1);
		}
		// remove from quickLooks
		if (temp?.quickLook && compType && ADDABLE_TO_QUICK_LOOK[compType]) {
			ADDABLE_TO_QUICK_LOOK[compType].forEach(q => {
				switch (q.type) {
					case "array":
						if (temp?.quickLook?.[q.key] && isArray(temp?.quickLook?.[q.key])) {
							const index = temp.quickLook[q.key].indexOf(compId);
							if (index >= 0) temp.quickLook[q.key].splice(index, 1);
						}
						if (q.minTypeCountForDisplay && newTypeCount < q.minTypeCountForDisplay) {
							if (prevValues?.quickLook?.[q.key]) temp.quickLook[q.key] = null;
							else delete temp.quickLook[q.key];
						}
						break;
					case "string":
						if (
							(temp?.quickLook?.[q.key] && temp?.quickLook?.[q.key] === compId) ||
							(q.minTypeCountForDisplay && newTypeCount < q.minTypeCountForDisplay)
						) {
							if (prevValues?.quickLook?.[q.key]) temp.quickLook[q.key] = null;
							else delete temp.quickLook[q.key];
						}
						break;
					default:
						break;
				}
			});
		}
		// remove from defaults
		if (temp?.defaults?.memory?.[compId]) {
			if (prevValues?.defaults?.memory?.[compId]) temp.defaults.memory[compId] = null;
			else delete temp.defaults.memory[compId];
		}
		setTempValues(temp);
	};

	const isInQuickLook = (dataType, key, id) => {
		switch (dataType) {
			case "string":
				return combinedValues?.quickLook?.[key] === id;
			case "array":
				return combinedValues?.quickLook?.[key]?.includes(id);
			default:
				return false;
		}
	};

	const changeQuickLookValue = (dataType, key, id, cantMatchKey = undefined) => {
		const temp = cloneDeep(tempValues);
		const inQuickLook = isInQuickLook(dataType, key, id);
		console.log("key", key, "is of data type", dataType, "and", inQuickLook ? "is" : "isn't", "in the quickLook");
		console.log("temp quicklook", temp.quickLook);
		if (!Object.prototype.hasOwnProperty.call(temp, "quickLook"))
			temp.quickLook = combinedValues.quickLook ? cloneDeep(combinedValues.quickLook) : {};
		switch (dataType) {
			case "string":
				if (inQuickLook) {
					if (prevValues?.quickLook?.[key]) temp.quickLook[key] = null;
					else delete temp.quickLook[key];
				} else temp.quickLook[key] = id;
				if (cantMatchKey && temp.quickLook[cantMatchKey] === id) {
					temp.quickLook[cantMatchKey] = null;
					if (prevValues?.quickLook?.[cantMatchKey]) temp.quickLook[cantMatchKey] = null;
					else delete temp.quickLook[cantMatchKey];
				}
				break;
			case "array":
				if (inQuickLook) {
					const index = temp.quickLook[key].indexOf(id);
					if (index >= 0) {
						temp.quickLook[key].splice(index, 1);
					}
				} else {
					if (!isArray(temp.quickLook[key])) temp.quickLook[key] = [];
					temp.quickLook[key].push(id);
				}
				if (cantMatchKey && temp.quickLook[cantMatchKey].includes(id)) {
					const index = temp.quickLook[cantMatchKey].indexOf(id);
					if (index >= 0) {
						temp.quickLook[cantMatchKey].splice(index, 1);
					}
				}
				break;
			default:
				break;
		}
		fixQuickLookOrdering(temp?.quickLook, temp?.componentDisplayOrder);
		setTempValues(temp);
	};

	const saveTemplate = () => {
		const templateId = schemaId ?? tempValues.id;
		const templateAction = schemaId ? updateMemoryTemplate : setMemoryTemplate;
		templateAction(templateId, tempValues).then(() => (schemaId ? navigate("/app/MemoryTemplates") : navigate(-1)));
	};

	const deleteTemplate = () => {
		if (schemaId) {
			deleteDoc(doc(firestore, "memoryTemplates", schemaId)).then(() => {
				alert("Deleted template!");
				window.location.reload();
			});
		}
	};

	return (
		<div>
			<p className="my-10 text-3xl font-bold text-center"> Template Builder </p>
			<p className="my-10 text-3xl text-center"> Here, you can create and save your memory templates </p>

			<div className={styles.dropDownMenuContainer}>
				<TempSelectionDropDown />
			</div>

			{showColorModal ? (
				<ColorSelectionModal
					name={combinedValues?.displayName}
					color={combinedValues?.backgroundColor}
					textColor={combinedValues?.textColor}
					setColors={({color, textColor}) => {
						const temp = {...tempValues};
						if (color) temp.backgroundColor = color;
						if (textColor) temp.textColor = textColor;
						setTempValues(temp);
					}}
					showModal={showColorModal}
					setShowModal={setShowColorModal}
				/>
			) : null}

			<div className="flex flex-row justify-between bg-white-300">
				<div>
					<p style={{fontSize: "24px", textAlign: "center"}}>Memory Template Name </p>
					<input
						className="mt-2 w-full pl-3 text-lg font-bold border-blue-500 border-2 rounded-md p-2"
						value={combinedValues?.displayName ?? ""}
						placeholder="Enter Memory Template Name"
						onChange={text => {
							const newName = text.target.value;
							const temp = {...tempValues, displayName: newName};
							setTempValues(temp);
						}}
					/>
				</div>
				<div role="presentation" className="flex items-center">
					<button
						className="inset-0 m-1 flex justify-end rounded-3xl border-2 border-solid border-blue-200 bg-white p-2 text-sm font-bold hover:border-blue-800 active:border-blue-200"
						type="button"
						onClick={() => setShowColorModal(true)}
					>
						Change Color
					</button>
				</div>
				<div className="flex items-center">
					<button
						className="inset-0 m-1 flex justify-end rounded-3xl border-2 border-solid border-blue-200 bg-white p-2 text-sm font-bold hover:border-blue-800 active:border-blue-200"
						type="button"
						onClick={saveTemplate}
						disabled={!canSave}
					>
						Save
					</button>
				</div>
			</div>
			<div className="flex">
				<div
					className={styles.templateCreatorComponent}
					style={{maxHeight: "100%", maxWidth: "50%", overflowY: "auto"}}
				>
					<p style={{fontSize: "24px", textAlign: "center"}}>Available Components</p>
					<div className="space-y-4">
						{DEFAULT_COMPONENTS_ORDER.map(k => (
							<div
								key={k}
								className={styles.availableComponentContainer}
								style={{maxHeight: "400px", overflowY: "auto"}}
							>
								<MemoryField
									type={k}
									label={DEFAULT_COMPONENT_LABELS[k]}
									value={DEFAULT_COMPONENT_VALUES[k]}
									options={DEFAULT_COMPONENT_OPTIONS[k]}
									disabled
								/>
								<button
									className={styles.createPlusButton}
									type="button"
									onClick={() => addComponent(k)}
									disabled={MAX_COMPONENT_QUANTITY[k] && typeCount[k] >= MAX_COMPONENT_QUANTITY[k]}
								>
									+
								</button>
							</div>
						))}
					</div>
				</div>
				<div className={styles.newTemplateFormatContainer} style={{flex: "1 1 auto", overflowY: "auto"}}>
					<p style={{fontSize: "24px", textAlign: "center"}}>Selected Components</p>
					<div className="space-y-4 bg-gray-100 rounded-lg">
						{combinedValues?.componentDisplayOrder?.map((id, index) => (
							<div key={id} className={styles.newTemplateComponentHeader} style={{padding: "10px"}}>
								<div>
									<input
										defaultValue={combinedValues?.fields?.[id]?.label ?? ""}
										placeholder="Enter Memory Component Name"
										onChange={text => {
											const newName = text.target.value;
											const temp = cloneDeep(tempValues);
											if (!temp.fields) temp.fields = {};
											if (!temp.fields[id]) temp.fields[id] = cloneDeep(combinedValues.fields[id]);
											temp.fields[id].label = newName;
											setTempValues(temp);
										}}
										style={{
											border: "2px solid #60a5fa",
											borderRadius: "5px",
											padding: "8px",
										}}
									/>
									<MemoryField
										type={combinedValues?.fields?.[id]?.type}
										label={combinedValues?.fields?.[id]?.type}
										value={combinedValues?.defaults?.memory?.[id]}
										options={combinedValues?.fields?.[id]?.options}
										disabled
									/>
								</div>
								<div className="flex flex-row">
									{combinedValues?.fields?.[id]?.type &&
										ADDABLE_TO_QUICK_LOOK?.[combinedValues.fields[id].type]?.map(q =>
											(q.minTypeCountForDisplay &&
												typeCount[combinedValues.fields[id].type] &&
												typeCount[combinedValues.fields[id].type] >= q.minTypeCountForDisplay) ||
											!q.minTypeCountForDisplay ? (
												<div key={q.key}>
													{q.label}
													<input
														type="checkbox"
														onChange={() => {
															changeQuickLookValue(q.type, q.key, id, q.cantMatch);
														}}
														checked={isInQuickLook(q.type, q.key, id) ?? false}
														style={{
															fontWeight: "bold",
															margin: "0 20px",
															fontStyle: "Arial",
														}}
													/>
												</div>
											) : null,
										)}
								</div>
								{index !== 0 && (
									<button
										type="button"
										aria-label="Move Up"
										onClick={() => moveComponent(id, -1)}
										className={styles.circleButton}
									>
										<BiSolidUpArrow size={15} />
									</button>
								)}
								{combinedValues.componentDisplayOrder.length - 1 !== index && (
									<button
										type="button"
										aria-label="Move Down"
										onClick={() => moveComponent(id, 1)}
										className={styles.circleButton}
									>
										<BiSolidDownArrow size={15} />
									</button>
								)}
								<button
									type="button"
									aria-label="Remove"
									onClick={() => removeComponent(id)}
									className={styles.circleButton}
								>
									<AiOutlineMinus size={20} />
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="flex justify-center mb-5">
				<button className={styles.createButton} type="button" onClick={saveTemplate} disabled={!canSave}>
					Save
				</button>
			</div>
		</div>
	);
};

export default CreateTemplateScreen;
