import React from "react";
import PropTypes from "prop-types";
import InputContainer from "./InputContainer";
import style from "./TextPicker.module.css";

const TextPicker = ({
	inputLabel = "Description Title",
	inputPlaceholder = "Write Description Title",
	textAreaLabel = "Description Content",
	textAreaPlaceholder = "Write Description",
	onRemove,
	title,
	onChange,
	value = {},
}) => (
	<div className={style.textPicker}>
		{(onRemove || title) && (
			<header className={style.header}>
				{title && <h2>{title}</h2>}
				{onRemove && (
					<button type="button" onClick={() => onRemove()}>
						REMOVE
					</button>
				)}
			</header>
		)}
		<InputContainer label={inputLabel}>
			<input
				type="text"
				id="title"
				placeholder={inputPlaceholder}
				value={value.title ?? ""}
				onChange={text =>
					onChange({
						...value,
						title: text.target.value,
					})
				}
			/>
		</InputContainer>
		<InputContainer label={textAreaLabel}>
			<textarea
				rows="3"
				placeholder={textAreaPlaceholder}
				value={value.content ?? ""}
				onChange={text =>
					onChange({
						...value,
						content: text.target.value,
					})
				}
			/>
		</InputContainer>
	</div>
);

TextPicker.propTypes = {
	inputLabel: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	textAreaLabel: PropTypes.string,
	textAreaPlaceholder: PropTypes.string,
	onRemove: PropTypes.func,
	title: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.object,
};
TextPicker.defaultProps = {
	inputLabel: "Description Title",
	inputPlaceholder: "Write Description Title",
	textAreaLabel: "Description Content",
	textAreaPlaceholder: "Write Description",
	onRemove: undefined,
	title: undefined,
	onChange: undefined,
	value: {},
};

export default TextPicker;
