import React, {useState, useEffect, useContext, useRef, useMemo} from "react";
import {useNavigate, useSearchParams, createSearchParams} from "react-router-dom";
import {doc, getDoc, deleteDoc} from "firebase/firestore";
import {ref, getDownloadURL} from "firebase/storage";
import {AiOutlineMail} from "react-icons/ai";
import {HiOutlineChatBubbleLeft, HiOutlinePhone} from "react-icons/hi2";
import PropTypes from "prop-types";

import {storage, firestore} from "../../firebase";
import {AuthContext} from "../../AuthProvider";
import {getContactName, getContactInitials} from "../../../../../resources/commonUtilities";
import FilePreview from "./Components/FilePreview";
import style from "./DisplayContactDetails.module.css";
import editDetailsStyle from "../Create/CreateMemory.module.css";

const formatBirthday = birthday => {
	if (!birthday || !birthday.month || !birthday.day || !birthday.year) {
		// We return null because we don't want to render anything if it's not available.
		return null;
	}

	return new Date(`${birthday?.month}/${birthday?.day}/${birthday?.year}`).toLocaleDateString();
};

const extractAllObjectsInArray = ({arr, keyTag, keyIsRequired = true, valueIsRequired = true, valueTag}) => {
	if (!arr || !Array.isArray(arr)) {
		return {};
	}

	const addValueToObject = (obj, key, value) => {
		if (obj[key]) {
			obj[key].push(value);
		} else {
			obj[key] = [value];
		}
	};
	const newObject = {};
	arr.forEach(obj => {
		if (
			(Object.prototype.hasOwnProperty.call(obj, keyTag) || !keyIsRequired) &&
			(Object.prototype.hasOwnProperty.call(obj, valueTag) || !valueIsRequired)
		) {
			const value = Object.prototype.hasOwnProperty.call(obj, valueTag) ? obj[valueTag] : "";
			if (Object.prototype.hasOwnProperty.call(obj, keyTag)) {
				addValueToObject(newObject, obj[keyTag], value);
			} else if (!keyIsRequired) {
				addValueToObject(newObject, "", value);
			}
		}
	});

	return newObject;
};

const DetailsLoader = ({categoryTitle, detailLabelToDetailObjectMap, customRef}) => {
	const valueIsInvalid = val => val === undefined || val === null;

	if (
		Object.keys(detailLabelToDetailObjectMap).length === 0 ||
		Object.values(detailLabelToDetailObjectMap).every(arr => arr.every(valueIsInvalid))
	) {
		return <div />;
	}

	// Precondition the label to detail object by removing undefined and null objects.
	detailLabelToDetailObjectMap = Object.fromEntries(
		Object.entries(detailLabelToDetailObjectMap).filter(v => !valueIsInvalid(v)),
	);
	return (
		<>
			<span className={style.categoryTitle} ref={customRef}>
				{categoryTitle}
			</span>
			<div className={style.categoryContainer}>
				{Object.entries(detailLabelToDetailObjectMap).map(([key, arr], objIndex) =>
					arr.map(
						(value, arrIndex) =>
							value && (
								<div key={`${objIndex.toString()},${arrIndex.toString()}`} className={style.detailContainer}>
									<span className={style.detailLabel}>{key}</span>
									<span className={style.detail}>{value}</span>
								</div>
							),
					),
				)}
			</div>
		</>
	);
};
DetailsLoader.propTypes = {
	categoryTitle: PropTypes.string,
	detailLabelToDetailObjectMap: PropTypes.object,
	customRef: PropTypes.object,
};
DetailsLoader.defaultProps = {
	categoryTitle: undefined,
	detailLabelToDetailObjectMap: {},
	customRef: undefined,
};

const URLSLoader = ({urls}) => {
	// This is to not allow relative URLs to be clicked on which would allow users to go through our app routes.
	const prefixes = ["http://", "https://"];
	const modifyUrlToAbsolute = url => {
		if (prefixes.every(prefix => url.substr(0, prefix.length) !== prefix)) {
			return `https://${url}`;
		}
		return url;
	};

	return (
		urls.length > 0 && (
			<>
				<span className={style.categoryTitle}>URLs</span>
				<div className={style.categoryContainer}>
					{urls.map((url, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<div key={index} className={style.detailContainer}>
							<a className={style.url} target="_blank" href={modifyUrlToAbsolute(url)} rel="noreferrer noopener">
								{url}
							</a>
						</div>
					))}
				</div>
			</>
		)
	);
};
URLSLoader.propTypes = {
	urls: PropTypes.array,
};
URLSLoader.defaultProps = {
	urls: [],
};

const ProfileImgLoader = ({profileImage, name, className}) => {
	const [thumbnail, setThumbnail] = useState(null);
	const initials = useMemo(() => getContactInitials(name) ?? "", [name]);

	useEffect(() => {
		if (profileImage?.thumbnail?.storagePath) {
			getDownloadURL(ref(storage, profileImage.thumbnail.storagePath)).then(url => {
				setThumbnail(() => url);
			});
		}
	}, [profileImage]);

	return (
		<div className={className ?? style.profileIconContainer}>
			{thumbnail ? <img src={thumbnail} alt="Contact Profile Icon" /> : <div>{initials}</div>}
		</div>
	);
};
ProfileImgLoader.propTypes = {
	profileImage: PropTypes.shape({
		file: PropTypes.shape({
			author: PropTypes.string,
			name: PropTypes.string,
			size: PropTypes.number,
			storageBucket: PropTypes.string,
			storagePath: PropTypes.string,
		}),
		thumbnail: PropTypes.shape({
			name: PropTypes.string,
			size: PropTypes.number,
			storageBucket: PropTypes.string,
			storagePath: PropTypes.string,
		}),
	}),
	name: PropTypes.string,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
ProfileImgLoader.defaultProps = {
	profileImage: undefined,
	name: undefined,
	className: undefined,
};

const DisplayContactDetails = () => {
	const navigate = useNavigate();
	const userContext = useContext(AuthContext);
	const {currentUser} = userContext;
	const {careData} = userContext;
	const [searchParams] = useSearchParams();

	const contactId = searchParams.get("id");

	const [currValues, setCurrValues] = useState({});
	const [canEdit, setCanEdit] = useState(false);
	const phoneNumbersRef = useRef(null);
	const emailsRef = useRef(null);

	useEffect(() => {
		if (contactId) {
			// initializes contact values to pre-existing values
			const contactDocRef = doc(firestore, "contacts", contactId);
			getDoc(contactDocRef).then(contactDoc => {
				if (contactDoc.exists) {
					const data = contactDoc.data();
					setCurrValues(data);
				}
			});
		}
	}, [contactId]);

	useEffect(() => {
		updateCanEdit(currValues?.editors ?? []);
	}, [currValues]);

	const editContact = () => {
		if (contactId) {
			const params = {id: contactId};
			navigate({
				pathname: "/app/CreateContact",
				search: `?${createSearchParams(params)}`,
			});
		}
	};

	const deleteContact = () => {
		if (contactId) {
			deleteDoc(doc(firestore, "contacts", contactId)).then(() => goBack());
		}
	};

	const goBack = () => {
		navigate(-1);
	};

	// Note that we directly update the `canEdit` state within this function instead of returning a boolean,
	// since we need the associated components to refresh with state update.
	const updateCanEdit = editors => {
		const isAnEditor = editors.includes(currentUser.uid);
		if (isAnEditor) {
			setCanEdit(true);
			// End early to avoid firing the follow-on extensive search through all editors.
			return;
		}

		editors.forEach(editorId => {
			// Iteratively checks through all editors to see who the current user is supporting with edit benefits.
			if (careData.supporting.canEditContacts.includes(editorId)) {
				setCanEdit(true);
			}
		});

		setCanEdit(false);
	};

	return (
		<div className="w-full">
			<div className={editDetailsStyle.top}>
				<button type="button" onClick={goBack}>
					Back
				</button>
				<button type="button" onClick={editContact}>
					Edit
				</button>
			</div>
			<div className={style.mainContainer}>
				<div className={style.topSectionContainer}>
					<div className={style.profileIconBlock}>
						<ProfileImgLoader profileImage={currValues?.profileImage} name={currValues?.name} />
					</div>
					<div className={style.nameContainer}>
						<span className={style.nameStyle}>{currValues?.name ?? getContactName(currValues?.contactInfo ?? {})}</span>
					</div>
					<div className={style.contactIconsBlock}>
						<button
							className={style.contactIconContainer}
							type="button"
							onClick={() => {
								try {
									phoneNumbersRef.current.scrollIntoView({behavior: "smooth"});
								} catch {
									alert("Contact has no phone number...");
								}
							}}
						>
							<HiOutlineChatBubbleLeft className={`scale-x-[-1] ${style.contactIcon}`} />
							<span className={style.contactIconCaption}>Text</span>
						</button>
						<button
							className={style.contactIconContainer}
							type="button"
							onClick={() => {
								try {
									phoneNumbersRef.current.scrollIntoView({behavior: "smooth"});
								} catch {
									alert("Contact has no phone number...");
								}
							}}
						>
							<HiOutlinePhone className={style.contactIcon} />
							<span className={style.contactIconCaption}>Call</span>
						</button>
						<button
							className={style.contactIconContainer}
							type="button"
							onClick={() => {
								try {
									emailsRef.current.scrollIntoView({behavior: "smooth"});
								} catch {
									alert("Contact has no email addresses...");
								}
							}}
						>
							<AiOutlineMail className={style.contactIcon} />
							<span className={style.contactIconCaption}>Email</span>
						</button>
					</div>
				</div>
				<DetailsLoader
					categoryTitle="User Info"
					detailLabelToDetailObjectMap={{
						"Job Title": [currValues?.contactInfo?.jobTitle],
						"Department": [currValues?.contactInfo?.department],
						"Company": [currValues?.contactInfo?.company],
						"Relationship": [currValues?.contactInfo?.relationship],
						"Birthday": [formatBirthday(currValues?.contactInfo?.birthday)],
					}}
				/>
				<DetailsLoader
					categoryTitle="Phone Numbers"
					detailLabelToDetailObjectMap={extractAllObjectsInArray({
						arr: currValues?.contactInfo?.phoneNumbers,
						keyIsRequired: false,
						keyTag: "label",
						valueTag: "number",
					})}
					customRef={phoneNumbersRef}
				/>
				<DetailsLoader
					categoryTitle="Emails"
					detailLabelToDetailObjectMap={extractAllObjectsInArray({
						arr: currValues?.contactInfo?.emailAddresses,
						keyIsRequired: false,
						keyTag: "label",
						valueTag: "email",
					})}
					customRef={emailsRef}
				/>
				<DetailsLoader
					categoryTitle="Addresses"
					detailLabelToDetailObjectMap={extractAllObjectsInArray({
						arr: currValues?.contactInfo?.postalAddresses,
						keyIsRequired: false,
						keyTag: "label",
						valueTag: "formattedAddress",
					})}
				/>
				<URLSLoader
					urls={Object.keys(
						extractAllObjectsInArray({
							arr: currValues?.contactInfo?.urlAddresses,
							keyTag: "url",
							valueIsRequired: false,
						}),
					)}
				/>
				{currValues?.contactInfo?.note && currValues?.contactInfo?.note.trim() && (
					<>
						<span className={style.categoryTitle}>Note</span>
						<div className={style.categoryContainer}>
							<div className={style.detailContainer}>
								<span className={style.noteDetail}>{currValues?.contactInfo?.note}</span>
							</div>
						</div>
					</>
				)}
				<FilePreview files={currValues?.files ?? []} />
				<div className={style.bottomSectionContainer}>
					<div className={style.detailContainer}>
						<span className={style.detailLabel}>Date Created</span>
						<span className={style.detail}>{currValues?.dateCreated?.toDate()?.toLocaleString()}</span>
					</div>
					<div className={style.detailContainer}>
						<span className={style.detailLabel}>Last Updated</span>
						<span className={style.detail}>{currValues?.lastUpdated?.toDate()?.toLocaleString()}</span>
					</div>
					{canEdit && (
						<div className={style.buttonContainer}>
							<button type="button" className={style.deleteButton} onClick={deleteContact}>
								Delete
							</button>
							<button type="button" className={style.editButton} onClick={editContact}>
								Edit
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export {ProfileImgLoader};
export default DisplayContactDetails;
