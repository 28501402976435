import React, {useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import {httpsCallable} from "firebase/functions";

import {functions} from "../../firebase";
import Profile from "../Components/Profile";
import StepCounter from "./Components/StepCounter";
import ValueOptions from "../../../../../resources/ValueOptions";

const roleToText = {
	Carereceiver: "support",
	Caregiver: "add as your caregiver",
};

const CareUserSelect = () => {
	const {role} = useParams();
	const [enteredEmail, setEnteredEmail] = useState("");
	const [desiredUserId, setDesiredUserId] = useState("");
	const [userNotFound, setUserNotFound] = useState(false);
	const [step, setStep] = useState(0);
	const oppositeRole = role === "Caregiver" ? "Carereceiver" : "Caregiver";
	const findUser = httpsCallable(functions, "findUser");

	const searchForUser = () => {
		if (enteredEmail.length > 0) {
			console.log("searching for user", enteredEmail);
			setUserNotFound(false);
			findUser({email: enteredEmail}).then(result => {
				// Read result of the Cloud Function.
				console.log(JSON.stringify(result, null, "\t"));
				const {uid, email} = result.data;
				if (uid && email) {
					console.log("user with email found");
					setEnteredEmail("");
					setDesiredUserId(uid);
					setStep(1);
				} else {
					setDesiredUserId("");
					setEnteredEmail("");
					setUserNotFound(true);
					setStep(0);
				}
			});
		}
	};

	return (
		<div className="flex flex-col items-center space-y-4">
			<StepCounter options={ValueOptions.careSetupNavOptions} selectedIndex={step} />
			{desiredUserId.length > 0 ? (
				<div className="space-y-4">
					<div className="flex flex-row flex-wrap items-center rounded-lg bg-[#F1F5FC] p-2">
						<p className="mr-4 font-bold text-blue-900">
							{" "}
							{`Is this the user you wish to ${roleToText[oppositeRole]}?`}
						</p>
						<Profile displayFullName id={desiredUserId} />
					</div>
					<div className="flex flex-row justify-center space-x-4">
						<button
							type="button"
							className="h-10 w-44 rounded-md bg-[#60AEF6] text-center font-bold text-white hover:text-blue-200"
							onClick={() => {
								setDesiredUserId("");
								setEnteredEmail("");
								setUserNotFound(false);
								setStep(0);
							}}
						>
							No
						</button>
						<NavLink
							className="flex h-10 w-44 items-center justify-center rounded-md bg-[#60AEF6] font-bold text-white hover:text-blue-200"
							to={`/app/CareUserAgree/${role}/${desiredUserId}`}
						>
							<p>Yes</p>
						</NavLink>
					</div>
				</div>
			) : (
				<div className="flex flex-col items-center space-y-2">
					<div className="flex flex-col items-center space-y-2 rounded-lg bg-[#F1F5FC] p-2">
						<span className="font-bold text-blue-900">
							{`Enter the email address of the user you would like to ${roleToText[oppositeRole]}`}
						</span>
						{userNotFound && (
							<p className="text-sm font-bold text-red-500">The email you have entered does not match with any users</p>
						)}
						<input
							className="w-full max-w-xl border-2 border-current border-gray-300 font-semibold"
							type="email"
							value={enteredEmail}
							onChange={text => setEnteredEmail(text.target.value)}
							onKeyDown={event => {
								if (event.key === "Enter") searchForUser();
							}}
						/>
					</div>
					<button
						type="button"
						className="h-10 w-full max-w-xs rounded-md bg-[#60AEF6] font-bold text-white hover:text-blue-200"
						onClick={searchForUser}
					>
						Search
					</button>
				</div>
			)}
		</div>
	);
};

export default CareUserSelect;
