import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {v4 as uuidv4} from "uuid";

const FilePicker = ({setFiles}) => {
	const [fileList, setFileList] = useState({});
	const [changedFile, setChangedFile] = useState({id: null, file: null});

	useEffect(() => {
		console.log("filelist:", fileList);
	}, [fileList]);

	useEffect(() => {
		setFiles(
			Object.entries(fileList)
				.map(([, value]) => value.file)
				.filter(n => n),
		);
	}, [fileList]);

	useEffect(() => {
		handleFileChange(changedFile.id, changedFile.file);
	}, [changedFile]);

	let handleFileChange = (id, file) => {
		if (id !== null) {
			const temp = {...fileList};
			temp[id].file = file;
			setFileList(temp);
		}
	};

	return (
		<div className="mt-12 text-center">
			<button
				type="button"
				className="rounded-lg font-bold text-blue-500"
				onClick={() => {
					const id = uuidv4();
					const newObj = {
						file: null,
						input: (
							<input
								className="w-64 py-2 text-sm"
								type="file"
								onChange={e => {
									setChangedFile({id, file: e.target.files[0]});
								}}
							/>
						),
					};
					const temp = {...fileList};
					temp[id] = newObj;
					setFileList(temp);
				}}
			>
				+ Add File
			</button>
			<div className="flex flex-col justify-center p-5">
				{Object.entries(fileList).map(([key, value]) => (
					<div key={key} className="flex justify-between">
						{value.input}
						<button
							type="button"
							className="font-bold text-red-500"
							onClick={() => {
								const temp = {...fileList};
								delete temp[key];
								setFileList(temp);
							}}
						>
							Remove
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

FilePicker.propTypes = {
	setFiles: PropTypes.func,
};
FilePicker.defaultProps = {
	setFiles: undefined,
};

export default FilePicker;
