import React, {useState} from "react";
import {AsYouType} from "libphonenumber-js";
import {NavLink, useNavigate} from "react-router-dom";
import {doc, setDoc, serverTimestamp} from "firebase/firestore";
import {getAuth, createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {auth, firestore} from "../firebase";
import TextInput from "./Components/TextInput";
import Logo from "../../../../resources/images/01.png";
// Built react components
import StatusPicker from "./Create/Components/StatusPicker";
import DatePicker from "./Components/DatePicker";

const GENDER_OPTIONS = ["Male", "Female", "Other"];
const BINARY_OPTION = ["Yes", "No"];

const SignUp = () => {
	const navigate = useNavigate();

	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [birthdate, setBirthdate] = useState(null);
	const [gender, setGender] = useState("");
	const [veteran, setVeteran] = useState("No");
	const [caregiver, setCaregiver] = useState("No");
	const [pin, setPin] = useState("");
	const [securityQA1, setSecurityQA1] = useState({
		question: "",
		answer: "",
	});
	const [securityQA2, setSecurityQA2] = useState({
		question: "",
		answer: "",
	});

	const getActualBirthDate = date => {
		if (!date) return date;
		const newDate = new Date(date);
		newDate.setHours(0);
		newDate.setMinutes(0);
		newDate.setSeconds(0);
		return newDate;
	};

	const signUp = async (inputEmail, inputPassword, inputConfirmPassword, metaData) => {
		if (
			metaData.firstName === "" ||
			metaData.lastName === "" ||
			metaData.email === "" ||
			metaData.password === "" ||
			metaData.confirmPassword === ""
		) {
			alert("Please fill out all required fields");
		} else if (
			!inputEmail
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				)
		) {
			alert("Please enter a valid email address");
		} else if (inputPassword !== inputConfirmPassword) {
			alert("Passwords do not match");
		} else {
			await createUserWithEmailAndPassword(auth, inputEmail, inputPassword)
				.then(currentUser => {
					const {user} = currentUser;

					console.log(user);

					return setDoc(doc(firestore, "pendingUsers", user.uid), {
						id: user.uid,
						firstName: metaData.firstName,
						middleName: metaData.middleName,
						lastName: metaData.lastName,
						mobileNumber: metaData.mobileNumber,
						emailAddress: inputEmail,
						// added missing gender, veteran ,and caregiver
						gender: metaData.gender,
						veteran: metaData.veteran === "Yes",
						caregiver: metaData.caregiver === "Yes",
						// ended missing values
						birthdate: metaData.birthdate,
						pin: metaData.pin,
						securityQA1: metaData.securityQA1,
						securityQA2: metaData.securityQA2,
						role: "supported",
						dateCreated: serverTimestamp(),
					});
				})
				.then(docRef => {
					const auths = getAuth();
					console.log(docRef);
					sendEmailVerification(auths.currentUser).then(() => {
						console.log("Verification Email Sent!");
					});
					alert("Account Created");
					navigate("/app");
				})
				.catch(error => console.log(error));
		}
	};

	const submitSignUp = () => {
		signUp(email, password, confirmPassword, {
			firstName,
			middleName,
			lastName,
			mobileNumber,
			gender,
			veteran,
			caregiver,
			birthdate: getActualBirthDate(birthdate),
			pin,
			securityQA1,
			securityQA2,
		});
	};

	return (
		<center>
			<div className="w-96 space-y-2 rounded-md border-2 px-4 py-2 text-left">
				<center className="">
					<NavLink to="/">
						<img style={{display: "inline"}} className="h-10 w-10 md:h-20 md:w-20 " src={Logo} alt="logo" />
						<p className="text-3xl font-extrabold text-blue-300">Want2Remember</p>
					</NavLink>
					<p className="text-2xl font-extrabold text-blue-900">Sign Up</p>
				</center>
				<p className="text-red-600">* Required</p>
				<TextInput type="text" label="First Name *" value={firstName} onChange={setFirstName} />
				<TextInput type="text" label="Middle Name" value={middleName} onChange={setMiddleName} />
				<TextInput type="text" label="Last Name *" value={lastName} onChange={setLastName} />
				<TextInput type="email" label="Email Address *" value={email} onChange={setEmail} />
				<TextInput type="password" label="Password *" value={password} onChange={setPassword} />
				<TextInput type="password" label="Confirm Password *" value={confirmPassword} onChange={setConfirmPassword} />
				<TextInput
					type="tel"
					label="Mobile Number"
					value={mobileNumber}
					onChange={text => {
						// if deleting a single char from phone number, will remove the last digit
						// and will continue removing until the 2nd to last digit is reached
						if (mobileNumber.length > 1 && mobileNumber.length - text.length === 1) {
							let r = mobileNumber.length - 1;
							let deletedNumber = false;
							for (let i = r; i >= 0; i--) {
								if (mobileNumber[i] >= "0" && mobileNumber[i] <= "9") {
									r = i;
									if (!deletedNumber) deletedNumber = true;
									else break;
								}
							}
							setMobileNumber(new AsYouType("US").input(mobileNumber.slice(0, r + 1)));
						} else {
							setMobileNumber(new AsYouType("US").input(text));
						}
					}}
				/>
				<StatusPicker statuses={GENDER_OPTIONS} title="Gender" selectedStatus={gender} onSelectStatus={setGender} />
				<StatusPicker
					statuses={BINARY_OPTION}
					title="Are you a veteran?"
					selectedStatus={veteran}
					onSelectStatus={setVeteran}
					allowDeselect={false}
				/>
				<StatusPicker
					statuses={BINARY_OPTION}
					title="Are you a caregiver?"
					selectedStatus={caregiver}
					onSelectStatus={setCaregiver}
					allowDeselect={false}
				/>
				<DatePicker label="Birthdate" date={birthdate} setDate={setBirthdate} />
				<br />
				<p className="pl-1 font-bold text-gray-500">Create a pin and two security questions:</p>
				<TextInput type="password" label="Pin" value={pin} onChange={setPin} maxLength={4} />
				<TextInput
					type="text"
					label="Question One"
					value={securityQA1.question}
					onChange={changedText => {
						setSecurityQA1({
							question: changedText,
							answer: securityQA1.answer,
						});
					}}
				/>
				<TextInput
					type="text"
					label="Answer Question One"
					value={securityQA1.answer}
					onChange={changedText => {
						setSecurityQA1({
							question: securityQA1.question,
							answer: changedText,
						});
					}}
				/>
				<TextInput
					type="text"
					label="Question Two"
					value={securityQA2.question}
					onChange={changedText => {
						setSecurityQA2({
							question: changedText,
							answer: securityQA2.answer,
						});
					}}
				/>
				<TextInput
					type="text"
					label="Answer Question Two"
					value={securityQA2.answer}
					onChange={changedText => {
						setSecurityQA2({
							question: securityQA2.question,
							answer: changedText,
						});
					}}
				/>
			</div>
			<div className="w-96 space-y-2 rounded-md px-4 py-4 text-center">
				<button
					type="submit"
					className="w-full rounded-lg bg-blue-400 p-3 font-semibold text-white hover:bg-blue-300 focus:outline-none focus:ring focus:ring-violet-300 active:bg-blue-300"
					onClick={submitSignUp}
				>
					Sign Up
				</button>
			</div>
			<div className="flex w-96 rounded-md border-2 px-4 py-4 text-center">
				<p className="basis-2/3 text-black ">Already have an account?</p>
				<a
					href="SignIn"
					className="font-semibold text-blue-400 hover:text-blue-700 active:bg-blue-300 active:text-blue-500 "
				>
					Sign In
				</a>
			</div>
		</center>
	);
};

export default SignUp;
