import React, {useEffect, useState, useContext} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {isEqual, cloneDeep} from "lodash";
import {ref, uploadBytes} from "firebase/storage";
import {doc, getDoc, collection} from "firebase/firestore";

import {AuthContext} from "../../AuthProvider";
import {firestore, storage} from "../../firebase";
import {schemas, fieldTypes} from "../../../../../resources/schemas";
import {mergeSchema, combineDateAndTime} from "../../../../../resources/commonUtilities";
import style from "./CreateMemory.module.css";
import TitlePicker from "./Components/TitlePicker";
import MemoryField from "./MemoryField";
import FilePicker from "./Components/FilePicker";
import FileEditor from "./Components/FileEditor";
import CategoryPicker from "./Components/CategoryPicker";
import {setMemory, updateMemory} from "../../utilities/firestoreUtilities";

const CreateMemory = () => {
	const {currentUser} = useContext(AuthContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const memoryTypeId = searchParams.get("memoryTypeId");
	const memoryId = searchParams.get("memoryId");
	const memoryFields = [];

	const [availableSchemas, setAvailableSchemas] = useState(schemas);

	const [creatingNew, setCreatingNew] = useState(true);
	const [prevValues, setPrevValues] = useState({});
	const [tempValues, setTempValues] = useState({});
	const [canCreate, setCanCreate] = useState(false);
	const [created, setCreated] = useState(false);
	const [newFiles, setNewFiles] = useState([]);

	useEffect(() => {
		if (!memoryId) {
			const schemaData = cloneDeep(availableSchemas[memoryTypeId]);
			if (schemaData) {
				// initializes memory values
				const tempNewMemoryValues = schemaData.defaults ? cloneDeep(schemaData.defaults) : {};
				tempNewMemoryValues.schema = schemaData;
				tempNewMemoryValues.typeOfMemoryId = schemaData.id;
				tempNewMemoryValues.typeOfMemory = schemaData.displayName;
				tempNewMemoryValues.viewers = [currentUser.uid];
				tempNewMemoryValues.editors = [currentUser.uid];

				// fix default values
				Object.keys(tempNewMemoryValues.memory).forEach(component => {
					if (tempNewMemoryValues.schema.fields[component] && tempNewMemoryValues.schema.fields[component].type) {
						switch (tempNewMemoryValues.schema.fields[component].type) {
							case fieldTypes.date:
								if (tempNewMemoryValues.memory[component] === "now") {
									const today = new Date();
									tempNewMemoryValues.memory[component] = new Date(
										today.getFullYear(),
										today.getMonth(),
										today.getDate(),
									);
								}
								break;
							case fieldTypes.time:
								if (tempNewMemoryValues.memory[component] === "now") {
									const newTime = new Date();
									newTime.setSeconds(0);
									newTime.setMilliseconds(0);
									tempNewMemoryValues.memory[component] = newTime;
								}
								break;
							default:
								break;
						}
					}
				});

				setPrevValues(tempNewMemoryValues);
				setTempValues(cloneDeep(tempNewMemoryValues));
			}
		} else if (Object.keys(prevValues).length === 0) {
			const memoryDocRef = doc(firestore, "memories", memoryId);
			getDoc(memoryDocRef).then(memoryDoc => {
				const data = {...memoryDoc.data()};

				const memTypeId = data?.typeOfMemoryId;

				setPrevValues(cloneDeep(data));

				const prevSchema = data.schema ?? {};
				const updatedSchema = memTypeId ? availableSchemas[memTypeId] ?? {} : {};
				const mergedSchema = mergeSchema(updatedSchema, prevSchema);

				data.typeOfMemory = mergedSchema.displayName;
				data.schema = mergedSchema;

				setTempValues(cloneDeep(data));

				setCreatingNew(false);
			});
		} else {
			const data = cloneDeep(tempValues);
			const prevSchema = prevValues.schema ?? {};
			const memTypeId = prevValues?.typeOfMemoryId;
			const updatedSchema = memTypeId ? availableSchemas[memTypeId] ?? {} : {};
			const mergedSchema = mergeSchema(updatedSchema, prevSchema);

			data.typeOfMemory = mergedSchema.displayName;
			data.schema = mergedSchema;

			setTempValues(cloneDeep(data));
		}
	}, [availableSchemas, memoryTypeId, memoryId]);

	// retrieves memory type's template if not one of the default templates
	useEffect(() => {
		if ((memoryTypeId || prevValues?.typeOfMemoryId) && !availableSchemas[memoryTypeId ?? prevValues?.typeOfMemoryId]) {
			const templateDocRef = doc(firestore, "memoryTemplates", memoryTypeId ?? prevValues?.typeOfMemoryId);
			getDoc(templateDocRef)
				.then(templateDoc => {
					const data = {...templateDoc.data()};
					const tempSchemas = {...availableSchemas};

					tempSchemas[templateDoc.id] = data;

					console.log("new schema data:", JSON.stringify(data, null, "\t"));

					setAvailableSchemas(tempSchemas);
				})
				.catch(error => {
					console.log("Error:", error);
				});
		}
	}, [memoryTypeId, prevValues, availableSchemas]);

	useEffect(() => {
		if (tempValues.title) {
			if (tempValues.title === undefined || tempValues.title.trim() === "") {
				setCanCreate(false);
			} else {
				setCanCreate(true);
			}
		}
	}, [tempValues.title]);

	useEffect(() => {
		const filteredTempValues = {...tempValues};
		delete filteredTempValues.usersInfo;
		delete filteredTempValues.schema;
		console.log("tempValues:", JSON.stringify(filteredTempValues, null, "\t"));
	}, [tempValues]);

	const handleUpload = (userFile, userId, memId) => {
		const cleanedFileName = userFile.name.replace(/\s/g, "_");
		const storageRef = ref(storage, `/userFiles/${userId}/memories/${memId}/${cleanedFileName}`);
		uploadBytes(storageRef, userFile).then(() => {
			console.log(`Uploaded ${userFile.name}`);
		});
	};

	const updateTempValue = (property, newValue) => {
		const newObj = {
			...tempValues,
			memory: {...tempValues.memory, [property]: newValue},
		};

		setTempValues(newObj);
	};

	const goBack = () => {
		navigate(-1);
	};

	const saveMemory = async () => {
		let sortingDate = null;
		const colRef = collection(firestore, `memories`);
		const memId = memoryId ?? doc(colRef).id;
		const schemaData = tempValues.schema;
		const newTempSaveData = cloneDeep(tempValues);

		const userDocRef = doc(firestore, `publicUsersInfo`, currentUser.uid);
		const userPublicData = await getDoc(userDocRef);

		setCreated(true);

		if (prevValues?.usersInfo) {
			newTempSaveData.usersInfo = {...prevValues.usersInfo};
		} else {
			newTempSaveData.usersInfo = {};
		}

		newTempSaveData.usersInfo[currentUser.uid] = userPublicData.data();

		// determines new sorting date
		if ((schemaData?.quickLook?.date || schemaData?.quickLook?.time) && tempValues.memory) {
			let sortDay = new Date();
			let sortTime = new Date();
			if (schemaData.quickLook.date) {
				for (let i = 0; i < schemaData.quickLook.date.length && sortingDate === null; i++) {
					const dateKey = schemaData.quickLook.date[i];
					if (tempValues?.memory[dateKey]) {
						sortDay = tempValues.memory[dateKey];
						break;
					}
				}
			}
			if (schemaData.quickLook.time) {
				for (let j = 0; j < schemaData.quickLook.time.length && sortingDate === null; j++) {
					const timeKey = schemaData.quickLook.time[j];
					if (tempValues?.memory[timeKey]) {
						sortTime = tempValues.memory[timeKey];
						break;
					}
				}
			}
			sortingDate = combineDateAndTime(sortDay, sortTime);
		}
		if (!sortingDate) sortingDate = new Date();

		if (!tempValues.sortDate || tempValues.sortDate !== sortingDate) {
			newTempSaveData.sortDate = sortingDate;
		}

		// removes any duplicate data
		if (!creatingNew) {
			Object.entries(prevValues).forEach(([key, value]) => {
				if (newTempSaveData[key] && isEqual(value, newTempSaveData[key])) {
					delete newTempSaveData[key];
					console.log("removing field:", key);
				}
				if (key === "sortDate" && value && value.seconds) {
					const oldSortDate = new Date(value.seconds * 1000);
					if (newTempSaveData[key] && isEqual(oldSortDate, newTempSaveData[key])) {
						delete newTempSaveData[key];
						console.log("removing field:", key);
					}
				}
			});

			if (prevValues.memory && newTempSaveData.memory) {
				Object.entries(prevValues.memory).forEach(([key, value]) => {
					if (newTempSaveData.memory[key] && isEqual(value, newTempSaveData.memory[key])) {
						delete newTempSaveData.memory[key];
					}
				});
			}

			if (newTempSaveData.memory && Object.keys(newTempSaveData.memory).length === 0) delete newTempSaveData.memory;

			if (prevValues?.usersInfo && isEqual(prevValues.usersInfo, newTempSaveData.usersInfo)) {
				delete newTempSaveData.usersInfo;
			}
		}

		const memoryAction = creatingNew ? setMemory : updateMemory;

		memoryAction(memId, newTempSaveData)
			.then(() => {
				Object.values(newFiles).forEach(file => {
					handleUpload(file, currentUser.uid, memId);
				});
				if (!creatingNew) goBack();
				else navigate("/app");
			})
			.catch(error => {
				console.error("error:", error);
				setCreated(false);
			});
	};

	tempValues?.schema?.componentDisplayOrder?.forEach(component => {
		const field = tempValues.schema.fields[component];
		if (!field) {
			memoryFields.push(<p key={component}>{component} field doesn&#39;t exist</p>);

			console.log(`${component} doesn't exist`);
		} else {
			const {label, type, options} = field;
			const startingValue = tempValues.memory && tempValues.memory[component] ? tempValues.memory[component] : null;

			memoryFields.push(
				<MemoryField
					type={type}
					label={label}
					options={options}
					key={component}
					value={startingValue}
					onChange={newValue => {
						updateTempValue(component, newValue);
					}}
				/>,
			);
		}
	});

	return (
		<>
			<div className={style.top}>
				<button type="button" onClick={goBack}>
					Back
				</button>
				{canCreate && !created && (
					<button type="button" onClick={saveMemory}>
						Save
					</button>
				)}
			</div>
			<div className={style.fields}>
				<h1>{tempValues.schema ? tempValues.schema.displayName : ""}</h1>

				<TitlePicker
					label="Title"
					value={tempValues.title ?? ""}
					onChange={value => setTempValues({...tempValues, title: value})}
				/>
				<CategoryPicker
					selectedCategories={tempValues.categories}
					setSelectedCategories={value => setTempValues({...tempValues, categories: value})}
				/>
				<FilePicker setFiles={setNewFiles} />
				<FileEditor existingFiles={tempValues.files} setExistingFiles={f => setTempValues({...tempValues, files: f})} />
				{memoryFields}
				<div className={style.bottomButtons}>
					{canCreate && !created && (
						<button type="button" onClick={saveMemory}>
							Save
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default CreateMemory;
