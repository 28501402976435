/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import {IoClose} from "react-icons/io5";
import {FaCheckSquare} from "react-icons/fa";
import {IconContext} from "react-icons";

import ValueOptions from "../../../../../../resources/ValueOptions";
import Colors from "../../../../../../resources/Colors";

const styles = {
	selectedIcon: {
		color: Colors.successGreen,
		size: "18px",
	},
	closeIcon: {
		size: "50px",
	},
};

const StatusModal = ({selectedStatus, onSelectStatus, closeModal}) => {
	const handleSelected = event => {
		const status = event.target.value;
		if (status === selectedStatus) {
			onSelectStatus("");
			return;
		}
		onSelectStatus(status);
	};

	const statusContent = ValueOptions.status.map((status, index) => {
		const isSelected = status && status === selectedStatus;
		if (isSelected) {
			return (
				<div key={index} className="flex items-center space-x-[10px]">
					<IconContext.Provider value={styles.selectedIcon}>
						<FaCheckSquare />
					</IconContext.Provider>
					<button
						type="button"
						style={{color: Colors.primaryBlue}}
						onClick={handleSelected}
						value={status}
						key={status}
					>
						{status}
					</button>
				</div>
			);
		}
		return (
			<div key={index} className="flex items-center space-x-[10px]">
				<button
					type="button"
					style={{color: Colors.primaryBlue}}
					className="pl-[28px]"
					onClick={handleSelected}
					value={status}
					key={status}
				>
					{status}
				</button>
			</div>
		);
	});

	return (
		<div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center overflow-y-hidden bg-neutral-500 bg-opacity-25">
			<div className="flex h-1/3 w-1/3 items-center rounded-[50px] bg-white p-10">
				<div className="flex h-[80%] w-2/3 flex-col justify-between">{statusContent}</div>
				<div className="flex h-full w-1/3 flex-col items-end justify-start">
					<button type="button" aria-label="Close" onClick={closeModal}>
						<IconContext.Provider value={styles.closeIcon}>
							<IoClose />
						</IconContext.Provider>
					</button>
				</div>
			</div>
		</div>
	);
};

StatusModal.propTypes = {
	selectedStatus: PropTypes.string,
	onSelectStatus: PropTypes.func,
	closeModal: PropTypes.func,
};
StatusModal.defaultProps = {
	selectedStatus: undefined,
	onSelectStatus: undefined,
	closeModal: undefined,
};

export default StatusModal;
