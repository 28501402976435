import React, {useState} from "react";
import PropTypes from "prop-types";
import {useNavigate, createSearchParams} from "react-router-dom";

import {ProfileImgLoader} from "../Detail/DisplayContactDetails";
import ContactModal from "./ContactModal";
// import actionTypes from "./actionTypes";

const Contact = ({contactIn}) => {
	const navigate = useNavigate();
	const [type, setType] = useState("");

	const displayContactDetails = () => {
		const params = {id: contactIn.id};
		navigate({
			pathname: "/app/DisplayContactDetails",
			search: `?${createSearchParams(params)}`,
		});
	};

	// const cornerButton = (actionId) => {
	// 	const action = actionTypes.find(a => a.id === actionId);
	// 	return action ? (
	// 	  <button
	// 		type="button"
	// 		onClick={(e) => {
	// 			e.stopPropagation();
	// 		  setType(action.id);
	// 		}}
	// 	  >
	// 		<img className="h-6" src={action.icon} alt={action.label} />
	// 		<p>{action.label}</p>
	// 	  </button>
	// 	) : null;
	//   };

	return (
		<div className="w-full max-w-sm overflow-clip rounded-2xl border-2 p-2 sm:w-4/5 md:w-3/4 md:max-w-md lg:max-w-lg">
			{type && (
				<ContactModal
					contactIn={contactIn.contactInfo}
					type={type}
					close={() => {
						setType("");
					}}
				/>
			)}
			<div className="flex justify-between">
				<div>
					<ProfileImgLoader
						className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-blue-500 text-center text-lg font-bold text-white"
						profileImage={contactIn.profileImage}
						name={contactIn.name}
					/>

					<div className="max-w-[250px] overflow-hidden text-left font-bold">{contactIn.name ?? "?"}</div>
				</div>
				{/* <div className="space-x-2">
					{actionTypes.map(action => (
					<React.Fragment key={action.id}>{cornerButton(action.id)}</React.Fragment>
					))}
				</div> */}
			</div>
			<button
				type="button"
				onClick={displayContactDetails}
				onKeyDown={displayContactDetails}
				className="mb-2 mt-2 w-3/4 rounded-lg bg-gray-200 text-black"
			>
				View More
			</button>
		</div>
	);
};

Contact.propTypes = {
	contactIn: PropTypes.object,
};
Contact.defaultProps = {
	contactIn: undefined,
};

export default Contact;
