import {doc, setDoc, updateDoc, serverTimestamp, arrayUnion, arrayRemove, deleteField} from "firebase/firestore";
import {cloneDeep} from "lodash";

import {firestore, auth} from "../firebase";
import {objFlatten} from "../../../../resources/commonUtilities";
import ValueOptions from "../../../../resources/ValueOptions";

const setMemory = (memoryId, memoryData) => {
	const user = auth.currentUser;
	const appVersion = ValueOptions.webVersion;
	const memDocRef = doc(firestore, "memories", memoryId);
	const additionalData = {
		dateCreated: new Date(),
		lastUpdated: new Date(),
		id: memDocRef.id,
		platform: "web",
		platformVersion: appVersion,
		author: user.uid,
		ogAuthor: user.uid,
	};
	const memoryDataCopy = cloneDeep(memoryData);
	return setDoc(memDocRef, {
		...memoryData,
		...additionalData,
		lastUpdateReceived: serverTimestamp(),
		history: arrayUnion({...memoryDataCopy, ...additionalData}),
	});
};

const updateMemory = (memoryId, memoryData) => {
	const user = auth.currentUser;
	const appVersion = ValueOptions.webVersion;
	const memDocRef = doc(firestore, "memories", memoryId);
	const flattenedNewMemoryValues = objFlatten(memoryData, ["memory"]);
	const additionalData = {
		lastUpdated: new Date(),
		platform: "web",
		platformVersion: appVersion,
		author: user.uid,
	};
	const memoryDataCopy = cloneDeep(memoryData);
	return updateDoc(memDocRef, {
		...flattenedNewMemoryValues,
		...additionalData,
		lastUpdateReceived: serverTimestamp(),
		history: arrayUnion({...memoryDataCopy, ...additionalData}),
	});
};

const setMemoryTemplate = (templateId, templateData) => {
	const user = auth.currentUser;
	const memTempDocRef = doc(firestore, "memoryTemplates", templateId);
	const appVersion = ValueOptions.webVersion;
	const additionalData = {
		dateCreated: new Date(),
		lastUpdated: new Date(),
		id: memTempDocRef.id,
		platform: "web",
		platformVersion: appVersion,
		author: user.uid,
		ogAuthor: user.uid,
	};
	const templateDataCopy = cloneDeep(templateData);
	return setDoc(memTempDocRef, {
		...templateData,
		...additionalData,
		lastUpdateReceived: serverTimestamp(),
		history: arrayUnion({...templateDataCopy, ...additionalData}),
	});
};

const updateMemoryTemplate = (templateId, templateData) => {
	const user = auth.currentUser;
	const memTempDocRef = doc(firestore, "memoryTemplates", templateId);
	const appVersion = ValueOptions.webVersion;
	const flattenedNewTemplateValues = objFlatten(templateData, ["fields", "quickLook", "defaults"]);
	Object.keys(flattenedNewTemplateValues).forEach(key => {
		if (flattenedNewTemplateValues[key] === null) {
			flattenedNewTemplateValues[key] = deleteField();
		}
	});
	const additionalData = {
		lastUpdated: new Date(),
		platform: "web",
		platformVersion: appVersion,
		author: user.uid,
	};
	const templateDataCopy = cloneDeep(templateData);
	return updateDoc(memTempDocRef, {
		...flattenedNewTemplateValues,
		...additionalData,
		lastUpdateReceived: serverTimestamp(),
		history: arrayUnion({...templateDataCopy, ...additionalData}),
	});
};

const setContact = (contactId, contactData) => {
	const user = auth.currentUser;
	const appVersion = ValueOptions.webVersion;
	const contDocRef = doc(firestore, "contacts", contactId);
	const additionalData = {
		dateCreated: new Date(),
		lastUpdated: new Date(),
		id: contDocRef.id,
		platform: "web",
		platformVersion: appVersion,
		author: user.uid,
		ogAuthor: user.uid,
	};
	const contactDataCopy = cloneDeep(contactData);
	return setDoc(contDocRef, {
		...contactData,
		...additionalData,
		lastUpdateReceived: serverTimestamp(),
		history: arrayUnion({...contactDataCopy, ...additionalData}),
	});
};

const updateContact = (contactId, contactData) => {
	const user = auth.currentUser;
	const appVersion = ValueOptions.webVersion;
	const contDocRef = doc(firestore, "contacts", contactId);
	const flattenedNewContactValues = objFlatten(contactData, ["contactInfo"]);
	const additionalData = {
		lastUpdated: new Date(),
		platform: "web",
		platformVersion: appVersion,
		author: user.uid,
	};
	const contactDataCopy = cloneDeep(contactData);
	return updateDoc(contDocRef, {
		...flattenedNewContactValues,
		...additionalData,
		lastUpdateReceived: serverTimestamp(),
		history: arrayUnion({...contactDataCopy, ...additionalData}),
	});
};

const updateCustomPalette = palette => {
	const user = auth.currentUser;
	const {colors, textColors} = palette;
	return updateDoc(doc(firestore, `users/${user.uid}/userData/custom`), {
		"colorPalettesData.colorPalettes.custom.colors": colors,
		"colorPalettesData.colorPalettes.custom.textColors": textColors,
		"colorPalettesData.customPaletteMade": true,
	});
};

const updateDefaultPaletteKey = key => {
	const user = auth.currentUser;
	return updateDoc(doc(firestore, `users/${user.uid}/userData/custom`), {
		"colorPalettesData.defaultPaletteKey": key,
	});
};

const updateMemoryVisibility = (memoryId, makePublic) => {
	const memoryRef = doc(firestore, "memories", memoryId);
	return updateDoc(memoryRef, {isPublic: makePublic});
};

export {
	setMemory,
	updateMemory,
	setContact,
	updateContact,
	updateCustomPalette,
	updateDefaultPaletteKey,
	setMemoryTemplate,
	updateMemoryTemplate,
	updateMemoryVisibility,
};
