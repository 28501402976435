import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import {ref, getDownloadURL} from "firebase/storage";
import {doc, onSnapshot} from "firebase/firestore";
import {useNavigate} from "react-router-dom";

import ModalTiles from "../Components/Modals/ModalTiles";
import {AuthContext} from "../../AuthProvider";
import ImportMemoriesIcon from "../../../../../resources/icons/Settings/ImportMemories.png";
import ExportMemoriesIcon from "../../../../../resources/icons/Settings/ExportMemories.png";
import SecurePinIcon from "../../../../../resources/icons/Settings/SecurePin.png";
import SendUsFeedbackIcon from "../../../../../resources/icons/Settings/SendUsFeedback.png";
import HelpIcon from "../../../../../resources/icons/Settings/Help.png";
import CloseIcon from "../../../../../resources/icons/Close.png";
import ProfileIcon from "../../../../../resources/icons/Profile.png";
import ImportModal from "../Components/Modals/ImportModal";
import {auth, storage, firestore} from "../../firebase";

const ProfileModal = ({title, onClose}) => {
	const {currentUser} = useContext(AuthContext);
	const [showPictureModal, setShowPictureModal] = useState(false);
	const [showImportModal, setShowImportModal] = useState(false);
	const [profileRef, setProfileRef] = useState(null);

	useEffect(() => {
		if (currentUser) {
			return onSnapshot(doc(firestore, `publicUsersInfo/${currentUser.uid}`), d => {
				const userInfo = d.data();
				if (userInfo?.profileImage?.thumbnail) {
					const {storagePath} = userInfo.profileImage.thumbnail;
					if (storagePath) {
						getDownloadURL(ref(storage, storagePath)).then(url => {
							setProfileRef(url);
						});
					}
				}
			});
		}
		return undefined;
	}, [currentUser]);

	const navigate = useNavigate();
	const signOut = () => {
		auth.signOut();
		navigate("/");
	};

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center bg-neutral-500 bg-opacity-25 font-bold">
			{showImportModal ? (
				<ImportModal
					handleCloseModal={() => setShowImportModal(false)}
					validateFile={/application\/(?:x-zip-compressed|zip)/}
					warningMessage="Please select a .zip file"
					successMessage="File uploaded successfully. Data import may take a few minutes to process"
					accept="application/zip, application/x-zip-compressed"
					destination={`/userFiles/${currentUser.uid}/importData`}
				>
					<p className="place-self-center text-sm">Please Import Memories</p>
				</ImportModal>
			) : null}

			{showPictureModal ? (
				<ImportModal
					handleCloseModal={() => setShowPictureModal(false)}
					validateFile={/image\/(?:jpg|jpeg|png)/}
					warningMessage="Please select a .jpg, .png, or .jpeg file"
					successMessage="File uploaded successfully"
					accept="image/jpeg,image/png,image/jpg"
					destination={`/userFiles/${currentUser.uid}/profile`}
				>
					<p className="mb-2 place-self-center text-sm">Current Profile Picture:</p>
					<img
						className="h-24 w-24 place-self-center rounded-full border-2"
						src={profileRef || ProfileIcon}
						alt="profile"
					/>
				</ImportModal>
			) : null}

			<div className="w-96 rounded-md bg-gray-100 p-3">
				<div className="flex justify-end">
					<div className="flex items-center">
						<p>Close</p>
						<button
							type="button"
							className="inset-0 m-1 flex h-8 w-8 justify-end rounded-full border-2 border-solid border-blue-200 bg-white text-xs font-bold hover:border-blue-800 active:border-blue-200"
							onClick={onClose}
						>
							<img className="h-8 w-8 place-self-center object-scale-down" src={CloseIcon} alt="close" />
						</button>
					</div>
				</div>
				<div className="m-1 rounded-md bg-white p-2 ">
					<p className="text-2xl">{title}</p>
					<p className="text-s font-medium">{auth.currentUser.email}</p>
				</div>
				<div className="flex flex-col">
					<ModalTiles
						title="Change Profile Picture"
						description="Upload new image"
						pic={ProfileIcon}
						onClick={() => {
							setShowPictureModal(true);
						}}
					/>
					<ModalTiles
						title="Import Memories"
						description="From another account"
						pic={ImportMemoriesIcon}
						onClick={() => {
							setShowImportModal(true);
						}}
					/>
					<ModalTiles title="Export Memories" description="# Memories" pic={ExportMemoriesIcon} />
					<ModalTiles
						title="Create or Edit Secure Pin"
						description="Recommended for Private Files"
						pic={SecurePinIcon}
					/>
					<ModalTiles title="Send us Feedback" pic={SendUsFeedbackIcon} description="Get in Contact with the Team" />
					<ModalTiles title="Help" description="Frequently Asked Questions" pic={HelpIcon} />
				</div>
				<div className="flex flex-col rounded-md py-2">
					<button
						type="button"
						onClick={signOut}
						className="rounded-md bg-red-500 p-3 font-semibold text-white hover:bg-red-700 focus:outline-none active:bg-red-500"
					>
						Sign Out
					</button>
				</div>
			</div>
		</div>
	);
};

ProfileModal.propTypes = {
	title: PropTypes.string,
	onClose: PropTypes.func,
};
ProfileModal.defaultProps = {
	title: "My Account",
	onClose: undefined,
};

export default ProfileModal;
