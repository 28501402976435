/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import {FaPencilAlt} from "react-icons/fa";
import {IconContext} from "react-icons";
import {isEqual} from "lodash";

import {removeAllEmpty} from "../../../../../resources/commonUtilities";

import Colors from "../../../../../resources/Colors";
import happy from "../../../../../resources/icons/Emoji/Happy.png";
import laugh from "../../../../../resources/icons/Emoji/Laugh.png";
import love from "../../../../../resources/icons/Emoji/Love.png";
import sad from "../../../../../resources/icons/Emoji/Sad.png";
import smile from "../../../../../resources/icons/Emoji/Smile.png";

const EMOJI_DATA = [
	{
		type: "Happy",
		image: happy,
	},
	{
		type: "Laugh",
		image: laugh,
	},
	{
		type: "Love",
		image: love,
	},
	{
		type: "Sad",
		image: sad,
	},
	{
		type: "Smile",
		image: smile,
	},
];

const DATE_DISPLAY_OPTIONS = {
	year: "numeric",
	month: "short",
	day: "numeric",
};
const TIME_DISPLAY_REGEX = /(\d?\d:)(\d?\d):(?:\d?\d)(\s(?:AM|PM))/;

const timeStampToDate = x => {
	if (x?.seconds) {
		const tempDate = new Date(x.seconds * 1000);
		return tempDate;
	}
	if (typeof x === "object") {
		const {month, day, year} = x;
		const newDate = new Date();
		newDate.setHours(0, 0, 0, 0);
		if (!isNaN(month)) newDate.setMonth(month - 1);
		if (day) newDate.setDate(day);
		if (!isNaN(year)) newDate.setFullYear(year);
		return newDate;
	}
	return x;
};

const styles = {
	pencilIcon: {
		color: Colors.primaryBlue,
		size: "18px",
	},
};

const kFieldTypes = [
	"CALENDAR_SWITCH",
	"PEOPLE",
	"REMINDER_SCHEDULE_TIME",
	"TEXT",
	"PHONE_NUMBER",
	"EMAIL_ADDRESS",
	"URL",
	"PASSWORD",
	"CURRENCY",
	"PAYMENT_METHOD",
	"TRANSACTION_TYPE",
	"DOUBLE_TEXT",
	"TASKS",
	"SIMPLE_TASKS",
	"TIME",
	"REOCCURRENCE",
	"EMOJI",
	"REMINDER_SNOOZE_DURATION",
	"DATE",
	"LOCATION",
	"STATUS",
	"TAGS",
];

const MemoryFieldDisplay = ({fieldType, memoryInfo, fieldName, fieldId, onClick}) => {
	if (memoryInfo.memory && memoryInfo.memory[fieldId]) {
		if (fieldType === "CALENDAR_SWITCH") {
			// NA
		}
		if (fieldType === "PEOPLE") {
			// Not yet implemented
		}
		if (fieldType === "REMINDER_SCHEDULE_TIME") {
			// Not yet implemented
		}
		if (
			fieldType === "TEXT" ||
			fieldType === "PHONE_NUMBER" ||
			fieldType === "EMAIL_ADDRESS" ||
			fieldType === "URL" ||
			fieldType === "PASSWORD" ||
			fieldType === "CURRENCY" ||
			fieldType === "PAYMENT_METHOD" ||
			fieldType === "TRANSACTION_TYPE"
		) {
			return (
				<div className="my-2 flex w-full justify-between rounded-xl bg-white p-4">
					<div>
						<p style={{color: Colors.mediumGrey}}>{fieldName}</p>
					</div>
					<div>
						<p>{memoryInfo.memory[fieldId]}</p>
					</div>
				</div>
			);
		}
		if (fieldType === "DOUBLE_TEXT") {
			if (!isEqual(removeAllEmpty(memoryInfo.memory[fieldId]), {})) {
				return (
					<div>
						<div>
							<p style={{color: Colors.mediumGrey}}>{fieldName}</p>
						</div>
						{memoryInfo.memory[fieldId].map(({title, content}, index) => {
							if (!title && !content) return null;
							return (
								<div key={index} className="my-2 flex w-full flex-col rounded-xl border-2 bg-white p-4">
									{title && <p style={{color: Colors.mediumGrey}}>{title}</p>}
									{content && (
										<div
											style={{backgroundColor: Colors.backgroundGrey}}
											className="my-2 w-full rounded-xl border-2 p-2"
										>
											<p>{content}</p>
										</div>
									)}
								</div>
							);
						})}
					</div>
				);
			}
			return null;
		}
		if (fieldType === "TASKS") {
			const filteredTasks = memoryInfo.memory[fieldId].filter(task => !isEqual(removeAllEmpty(task), {}));
			if (filteredTasks.length === 0) return null;
			return (
				<div>
					<div className="p-4">
						<p style={{color: Colors.mediumGrey}}>Tasks</p>
					</div>
					<div>
						{filteredTasks.map((task, index) => {
							if (isEqual(removeAllEmpty(task), {})) {
								return null;
							}
							return (
								<div key={index} className="my-2 w-full justify-between space-y-8 rounded-xl border-2 bg-white p-4">
									<div>
										<p style={{color: Colors.mediumGrey}}>{`Task ${index + 1}`}</p>
									</div>
									{task.title ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Task Title</p>
											</div>
											<div>
												<p>{task.title}</p>
											</div>
										</div>
									) : null}
									{task.content ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Task Content</p>
											</div>
											<div>
												<p>{task.content}</p>
											</div>
										</div>
									) : null}
									{task.date ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Task Date</p>
											</div>
											<div>
												<p>{timeStampToDate(task.date).toLocaleDateString("en-US", DATE_DISPLAY_OPTIONS)}</p>
											</div>
										</div>
									) : null}
									{task.startingTime ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Task Starting Time</p>
											</div>
											<div>
												<p>
													{timeStampToDate(task.startingTime)
														.toLocaleTimeString()
														.match(TIME_DISPLAY_REGEX)
														.slice(1)
														.join("")}
												</p>
											</div>
										</div>
									) : null}
									{task.endingTime ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Task Ending Time</p>
											</div>
											<div>
												<p>
													{timeStampToDate(task.endingTime)
														.toLocaleTimeString()
														.match(TIME_DISPLAY_REGEX)
														.slice(1)
														.join("")}
												</p>
											</div>
										</div>
									) : null}
									{task.status ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Status</p>
											</div>
											<div>
												<p style={{color: Colors.primaryBlue}}>{task.status}</p>
											</div>
										</div>
									) : null}
									{task.tags ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Tags</p>
											</div>
											<div className="flex w-2/3 flex-wrap justify-end">
												{task.tags.map((tag, idx) => (
													<div
														style={{borderColor: Colors.primaryBlue}}
														className="mb-2 mr-2 whitespace-nowrap rounded-full border p-1 px-2"
														key={idx}
													>
														<p
															style={{
																color: Colors.primaryBlue,
															}}
														>
															{tag}
														</p>
													</div>
												))}
											</div>
										</div>
									) : null}
									{task.notes && !isEqual(removeAllEmpty(task.notes), {}) ? (
										<div className="flex w-full flex-col">
											<div>
												<p style={{color: Colors.mediumGrey}}>Notes</p>
											</div>
											<div>
												{task.notes.map(({title, content}, idx) => {
													if (!title && !content) return null;
													return (
														<div key={idx} className="my-2 flex w-full flex-col rounded-xl border-2 bg-white p-4">
															{title && (
																<div>
																	<p style={{color: Colors.mediumGrey}}>{title}</p>
																</div>
															)}
															{content && (
																<div
																	style={{
																		backgroundColor: Colors.backgroundGrey,
																	}}
																	className="my-2 w-full rounded-xl border-2 p-2"
																>
																	<p>{content}</p>
																</div>
															)}
														</div>
													);
												})}
											</div>
										</div>
									) : null}
								</div>
							);
						})}
					</div>
				</div>
			);
		}
		if (fieldType === "SIMPLE_TASKS") {
			const filteredTasks = memoryInfo.memory[fieldId].filter(task => !isEqual(removeAllEmpty(task), {}));
			if (filteredTasks.length === 0) return null;
			return (
				<div>
					<div className="p-4">
						<p style={{color: Colors.mediumGrey}}>Simple Tasks</p>
					</div>
					<div>
						{filteredTasks.map((task, index) => {
							if (isEqual(removeAllEmpty(task), {})) {
								return null;
							}
							return (
								<div key={index} className="my-2 w-full justify-between space-y-8 rounded-xl border-2 bg-white p-4">
									<div>
										<p style={{color: Colors.mediumGrey}}>{`Task ${index + 1}`}</p>
									</div>
									{task.title ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Task Title</p>
											</div>
											<div>
												<p>{task.title}</p>
											</div>
										</div>
									) : null}
									{task.status ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Status</p>
											</div>
											<div>
												<p style={{color: Colors.primaryBlue}}>{task.status}</p>
											</div>
										</div>
									) : null}
								</div>
							);
						})}
					</div>
				</div>
			);
		}
		if (fieldType === "TIME") {
			const date = timeStampToDate(memoryInfo.memory[fieldId]);
			const timeRegex = /(\d?\d:)(\d?\d):(?:\d?\d)(\s(?:AM|PM))/;
			return (
				<div className="my-2 flex w-full justify-between p-4">
					<div>
						<p style={{color: Colors.mediumGrey}}>{fieldName}</p>
					</div>
					<div>
						<p>{date.toLocaleTimeString().match(timeRegex).slice(1).join("")}</p>
					</div>
				</div>
			);
		}
		if (fieldType === "REOCCURRENCE") {
			if (memoryInfo?.memory[fieldId]) {
				return (
					<div className="my-2 flex w-full justify-between rounded-xl border-2 bg-white p-4">
						<div>
							<p style={{color: Colors.mediumGrey}}>{fieldName}</p>
						</div>
						<div>
							<p>{memoryInfo.memory[fieldId]}</p>
						</div>
					</div>
				);
			}
		}
		if (fieldType === "EMOJI") {
			const emojis = memoryInfo.memory[fieldId];
			return (
				<div className="my-2 flex w-full justify-between rounded-xl p-4">
					<div>
						<p style={{color: Colors.mediumGrey}}>Emoji</p>
					</div>
					<div className="flex flex-row">
						{EMOJI_DATA.map(({type, image}, index) =>
							emojis && emojis[type] ? (
								<div className="mr-2 flex flex-col items-center text-xs font-medium" key={index}>
									<div>
										<img className="h-6" src={image} alt="emoji" />
									</div>
									<p>{type}</p>
								</div>
							) : null,
						)}
					</div>
				</div>
			);
		}
		if (fieldType === "REMINDER_SNOOZE_DURATION") {
			const snooze = memoryInfo?.memory[fieldId];
			let newText = "None";

			if (snooze.hr > 0 || snooze.min > 0) {
				newText = "Every ";
			}

			if (snooze.hr > 0) {
				newText += `${snooze.hr} hr`;
			}

			if (snooze.hr > 0 && snooze.min > 0) {
				newText += ":";
			}

			if (snooze.min > 0) {
				newText += `${snooze.min} min`;
			}

			if (snooze.min === 0 && snooze.hr === 0) {
				newText = "Never Occurs";
			}
			return (
				<div className="my-2 flex w-full justify-between rounded-xl border-2 bg-white p-4">
					<div>
						<p style={{color: Colors.mediumGrey}}>Snooze Timer</p>
					</div>
					<div>
						<p>{newText}</p>
					</div>
				</div>
			);
		}
		if (fieldType === "DATE") {
			const date = timeStampToDate(memoryInfo.memory[fieldId]);
			return (
				<div className="my-2 flex w-full justify-between rounded-xl border-2 bg-white p-4">
					<div>
						<p style={{color: Colors.mediumGrey}}>{fieldName}</p>
					</div>
					<div>
						<p>{date.toLocaleDateString("en-US", DATE_DISPLAY_OPTIONS)}</p>
					</div>
				</div>
			);
		}
		if (fieldType === "LOCATION") {
			return (
				<div className="my-2 flex w-full flex-row items-start justify-between rounded-xl border-2 bg-white p-4">
					<div className="flex basis-1/3">
						<p style={{color: Colors.mediumGrey}}>Location</p>
					</div>
					<div className="flex basis-2/3 justify-end">
						<p>
							{memoryInfo.memory[fieldId].locationDetails?.fullAddress
								? memoryInfo.memory[fieldId].locationDetails?.fullAddress
								: memoryInfo.memory[fieldId].latLng}
						</p>
					</div>
				</div>
			);
		}
		if (fieldType === "STATUS") {
			return (
				<div className="my-2 flex w-full items-center justify-between rounded-xl border-2 bg-white p-4">
					<div>
						<p style={{color: Colors.mediumGrey}}>Status</p>
					</div>
					<div>
						<p style={{color: Colors.primaryBlue}}>{memoryInfo.memory[fieldId]}</p>
					</div>
					<button
						type="button"
						className="flex w-[100px] items-center justify-between rounded-lg border p-1 px-2"
						style={{
							color: Colors.primaryBlue,
							borderColor: Colors.primaryBlue,
						}}
						onClick={onClick}
					>
						<IconContext.Provider value={styles.pencilIcon}>
							<FaPencilAlt />
						</IconContext.Provider>
						Change
					</button>
				</div>
			);
		}

		if (fieldType === "TAGS") {
			return (
				<div className="my-2 flex w-full justify-between rounded-xl p-4">
					<div>
						<p style={{color: Colors.mediumGrey}}>Tags</p>
					</div>
					<div className="flex basis-2/3 flex-wrap justify-evenly">
						{memoryInfo.memory[fieldId].map((tag, index) => (
							<div
								style={{borderColor: Colors.primaryBlue}}
								className="mb-1 ml-1 rounded-full border p-1 px-2"
								key={index}
							>
								<p
									style={{
										color: Colors.primaryBlue,
									}}
								>
									{tag}
								</p>
							</div>
						))}
					</div>
				</div>
			);
		}
		if (fieldType === "PAYMENTS") {
			const filteredPayments = memoryInfo.memory[fieldId].filter(task => !isEqual(removeAllEmpty(task), {}));
			if (filteredPayments.length === 0) return null;
			return (
				<div>
					<div className="p-4">
						<p style={{color: Colors.mediumGrey}}>Payments</p>
					</div>
					<div>
						{filteredPayments.map((payment, index) => {
							if (isEqual(removeAllEmpty(payment), {})) {
								return null;
							}
							return (
								<div key={index} className="my-2 w-full justify-between space-y-8 rounded-xl border-2 bg-white p-4">
									<div>
										<p style={{color: Colors.mediumGrey}}>{`Payment ${index + 1}`}</p>
									</div>
									{payment.source ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>
													{memoryInfo?.schema?.fields?.[fieldId]?.options?.sourceLabel
														? memoryInfo.schema.fields[fieldId].options.sourceLabel
														: "Source"}
												</p>
											</div>
											<div>
												<p>{payment.source}</p>
											</div>
										</div>
									) : null}
									{payment.vendor ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Vendor</p>
											</div>
											<div>
												<p>{payment.vendor}</p>
											</div>
										</div>
									) : null}
									{payment.renewalDate ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Renewal Date</p>
											</div>
											<div>
												<p>{timeStampToDate(payment.renewalDate).toLocaleDateString("en-US", DATE_DISPLAY_OPTIONS)}</p>
											</div>
										</div>
									) : null}
									{payment.dueDate ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Due Date</p>
											</div>
											<div>
												<p>{timeStampToDate(payment.dueDate).toLocaleDateString("en-US", DATE_DISPLAY_OPTIONS)}</p>
											</div>
										</div>
									) : null}
									{payment.reoccurrenceType ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Reoccurrence Type</p>
											</div>
											<div>
												<p>{payment.reoccurrenceType}</p>
											</div>
										</div>
									) : null}
									{payment.amount ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Amount</p>
											</div>
											<div>
												<p>${payment.amount}</p>
											</div>
										</div>
									) : null}
									{payment.reason ? (
										<div className="flex w-full justify-between">
											<div>
												<p style={{color: Colors.mediumGrey}}>Reason</p>
											</div>
											<div>
												<p>{payment.reason}</p>
											</div>
										</div>
									) : null}
								</div>
							);
						})}
					</div>
				</div>
			);
		}

		return <h6>{fieldName} Not Yet Implemented</h6>;
	}
	return null;
};

MemoryFieldDisplay.propTypes = {
	fieldType: PropTypes.oneOf(kFieldTypes),
	memoryInfo: PropTypes.object,
	fieldName: PropTypes.string,
	fieldId: PropTypes.string,
	onClick: PropTypes.func,
};
MemoryFieldDisplay.defaultProps = {
	fieldType: undefined,
	memoryInfo: undefined,
	fieldName: undefined,
	fieldId: undefined,
	onClick: () => null,
};

export default MemoryFieldDisplay;
