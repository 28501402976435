/* eslint-disable react/no-array-index-key */
import React, {useState} from "react";
import {NavLink} from "react-router-dom";

import Colors from "../../../../../resources/Colors";
import CaregiverICN from "../../../../../resources/icons/friends.png";
import SupporterICN from "../../../../../resources/icons/care.png";

const OPTIONS = {
	caregiver: {
		icon: CaregiverICN,
		label: "Caregiver",
		id: "Caregiver",
	},
	carereceiver: {
		icon: SupporterICN,
		label: "Supported",
		id: "Carereceiver",
	},
};

const CareRoleSelect = () => {
	const [highlightButtons, setHighlightButtons] = useState({});

	const renderOptionButtons = ({label, icon, id}) => (
		<NavLink
			to={`/app/CareUserSelect/${id}`}
			className="flex h-[100px] w-[450px]
        place-items-center rounded-lg border-[1px] border-[#6D6D6D] text-center "
			style={
				highlightButtons[id]
					? {backgroundColor: Colors.primaryBlue, color: "white"}
					: {backgroundColor: "white", color: Colors.tabGrey}
			}
			onMouseOver={() => setHighlightButtons({...highlightButtons, [id]: true})}
			onMouseOut={() => setHighlightButtons({...highlightButtons, [id]: false})}
		>
			<img src={icon} alt="care role" className="ml-5 h-[60px] w-[60px]" />
			<p className="inline-block w-full pr-[40px] text-center text-2xl font-bold">{label}</p>
		</NavLink>
	);

	return (
		<div className="text-center">
			<p className="text-[30px] font-bold ">Caregiver Setup</p>
			<p className="text-[20px] font-bold ">Please select the role you will be having</p>
			<div className="flex flex-row flex-wrap justify-center">
				{Object.keys(OPTIONS).map((value, index) => (
					<div key={index} className="mx-5 mt-5">
						{renderOptionButtons(OPTIONS[value])}
					</div>
				))}
			</div>
		</div>
	);
};

export default CareRoleSelect;
