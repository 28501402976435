import React, {useState, useEffect, useContext} from "react";
import {collection, query, where, getDocs} from "firebase/firestore";
import {useNavigate, useSearchParams} from "react-router-dom";

import {firestore} from "../../firebase";
import {AuthContext} from "../../AuthProvider";
import styles from "./CreateMemoryTemplate.module.css";

const TempSelectionDropDown = () => {
	const navigate = useNavigate();
	const {currentUser} = useContext(AuthContext);
	const [searchParams] = useSearchParams();
	const schemaId = searchParams.get("schemaId");

	const [schemaIds, setSchemaIds] = useState([]);
	const [schemaNames, setSchemaNames] = useState([]);

	useEffect(() => {
		fetchSchemaIds();
	}, []);

	const fetchSchemaIds = async () => {
		const q = query(
			collection(firestore, "memoryTemplates"),
			where("viewers", "array-contains-any", [currentUser.uid]),
		);

		const querySnapshot = await getDocs(q);
		const tempSchemaIdArray = querySnapshot.docs.map(currDoc => currDoc.id);
		const tempSchemaNames = querySnapshot.docs.map(currDoc => currDoc.data().displayName);
		console.log("ids:", tempSchemaIdArray);
		console.log("names:", tempSchemaNames);
		setSchemaIds(tempSchemaIdArray);
		setSchemaNames(tempSchemaNames);
	};

	const handleSelectChange = event => {
		const selectedSchemaId = event.target.value;
		if (selectedSchemaId !== schemaId) {
			if (selectedSchemaId) navigate(`/app/CreateTemplate/?schemaId=${selectedSchemaId}`);
			else navigate("/app/CreateTemplate");
			location.reload();
		}
	};

	return (
		<div style={{marginBottom: "20px"}}>
			<span htmlFor="schemaDropdown" className={styles.templateSelectionPrompt}>
				Select a Template to edit:
			</span>
			<select
				id="schemaDropdown"
				value={schemaId ?? ""}
				className={`${styles.dropDownDefaultBar} ${styles.centered}`}
				onChange={handleSelectChange}
				style={{width: "100%"}}
			>
				<option value="" className="border-black, text-center">
					-- Select --
				</option>
				{schemaIds.map((id, index) => (
					<option key={id} value={id} className="text-center" style={{fontFamily: "Arial Bold, Arial, sans-serif"}}>
						{schemaNames[index]}
					</option>
				))}
			</select>
		</div>
	);
};

export default TempSelectionDropDown;
