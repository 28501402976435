import React from "react";
import PropTypes from "prop-types";

import ValueOptions from "../../../../../resources/ValueOptions";
import LocationPicker from "./Components/LocationPicker";
import DatePicker from "../Components/DatePicker";
import SaveToCalendar from "./Components/SaveToCalendar";
import TextPicker from "../Components/TextPicker";
import TimePicker from "./Components/TimePicker";
import StatusPicker from "./Components/StatusPicker";
import TagsPicker from "./Components/TagsPicker";
import DynamicList from "./Components/DynamicList";
// import {ReminderReoccurrence, ReminderSnoozeDuration} from "./Components/ReminderDateTime";
import OneLineTextPicker from "../Components/OneLineTextPicker";
import AddToDo from "./Components/AddToDo";
import AddSimpleToDo from "./Components/AddSimpleToDo";
import EmojiPicker from "./Components/EmojiPicker";
import AddPayments from "./Components/AddPayments";

const kTYPES = [
	"TEXT",
	"DATE",
	"TIME",
	"EMOJI",
	"PHONE_NUMBER",
	"EMAIL_ADDRESS",
	"URL",
	"PASSWORD",
	"TAGS",
	"STATUS",
	"LOCATION",
	"PEOPLE",
	"DOUBLE_TEXT",
	"CURRENCY",
	"PAYMENTS",
	"PAYMENT_METHOD",
	"TRANSACTION_TYPE",
	"TASKS",
	"SIMPLE_TASKS",
];

const MemoryField = ({type, value, onChange, label = "", options = {}, placeholder, disabled}) => {
	const clonedProps = {label, placeholder, value, options, onChange, disabled};

	if (type === "CALENDAR_SWITCH") {
		return <SaveToCalendar {...clonedProps} />;
	}

	if (
		type === "TEXT" ||
		type === "PASSWORD" ||
		type === "EMAIL_ADDRESS" ||
		type === "PHONE_NUMBER" ||
		type === "URL" ||
		type === "CURRENCY"
	) {
		const extraProps = {};

		const {keyboardType, multiline} = options;

		if (keyboardType) {
			switch (keyboardType) {
				case "email-address":
					extraProps.inputType = "email";
					break;
				case "number-pad":
					extraProps.inputType = "number";
					break;
				default:
					break;
			}
		}

		switch (type) {
			case "PHONE_NUMBER":
				extraProps.inputType = "tel";
				break;
			case "EMAIL_ADDRESS":
				extraProps.inputType = "email";
				break;
			case "URL":
				extraProps.inputType = "url";
				break;
			case "PASSWORD":
				extraProps.inputType = "tel";
				break;
			case "CURRENCY":
				extraProps.inputType = "number";
				break;
			default:
				break;
		}

		return (
			<OneLineTextPicker
				inputLabel={label}
				setTextInput={onChange}
				textInput={value ?? ""}
				inputPlaceholder={`Enter ${label}`}
				multiline={multiline}
				{...extraProps}
			/>
		);
	}

	if (type === "DOUBLE_TEXT") {
		const {titleLabel, titlePlaceHolder, contentLabel, contentPlaceHolder} = options;

		return (
			<DynamicList value={value || []} onChange={onChange} itemName={label}>
				<TextPicker
					inputLabel={titleLabel ?? `${label} Title`}
					inputPlaceholder={titlePlaceHolder ?? `Write ${label} Title`}
					textAreaLabel={contentLabel ?? undefined}
					textAreaPlaceholder={contentPlaceHolder ?? undefined}
				/>
			</DynamicList>
		);
	}

	if (type === "TASKS") {
		return (
			<DynamicList value={value || []} onChange={onChange} itemName={label}>
				<AddToDo />
			</DynamicList>
		);
	}

	if (type === "SIMPLE_TASKS") {
		return (
			<DynamicList value={value || []} onChange={onChange} itemName={label}>
				<AddSimpleToDo />
			</DynamicList>
		);
	}

	if (type === "TIME") {
		return <TimePicker value={value} onChange={onChange} label={label} />;
	}

	if (type === "TRANSACTION_TYPE") {
		return (
			<StatusPicker
				statuses={ValueOptions.transactionTypes}
				selectedStatus={value}
				title={label}
				onSelectStatus={onChange}
			/>
		);
	}

	if (type === "PAYMENT_METHOD") {
		return (
			<StatusPicker
				statuses={ValueOptions.paymentMethods}
				selectedStatus={value}
				title={label}
				onSelectStatus={onChange}
			/>
		);
	}

	if (type === "PAYMENTS") {
		const {sourceLabel, vendor, dueDate} = options;
		return (
			<DynamicList value={value || []} onChange={onChange} itemName={label}>
				<AddPayments sourceLabel={sourceLabel} vendor={vendor} dueDate={dueDate} />
			</DynamicList>
		);
	}

	// if (type === "REOCCURRENCE") {
	// 	return <ReminderReoccurrence selectedReoccurrence={value} onSetReoccurrence={onChange} label={label} />;
	// }

	if (type === "EMOJI") {
		return <EmojiPicker emojis={value} setEmojis={onChange} label="Emojis" />;
	}

	// if (type === "REMINDER_SNOOZE_DURATION") {
	// 	return <ReminderSnoozeDuration snoozeData={value} onSetSnoozeData={onChange} label={label} />;
	// }

	if (type === "DATE") {
		return <DatePicker date={value} setDate={onChange} label={label} />;
	}

	if (type === "LOCATION") {
		return <LocationPicker location={value ?? ValueOptions.defaultLocationInfo} setLocation={onChange} />;
	}

	if (type === "STATUS") {
		return (
			<StatusPicker statuses={ValueOptions.status} selectedStatus={value} title={label} onSelectStatus={onChange} />
		);
	}

	if (type === "TAGS") {
		return <TagsPicker label="Tags" tags={value || []} setTags={onChange} />;
	}

	return <p>{type} Not Yet Implemented</p>;
};

MemoryField.propTypes = {
	type: PropTypes.oneOf(kTYPES),
	value: PropTypes.any,
	onChange: PropTypes.func,
	label: PropTypes.string,
	options: PropTypes.object,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
};
MemoryField.defaultProps = {
	type: undefined,
	value: undefined,
	onChange: undefined,
	label: "",
	options: {},
	placeholder: undefined,
	disabled: false,
};

export default MemoryField;
