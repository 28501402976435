/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

const StepCounter = ({options, selectedIndex, className = ""}) => {
	const renderStep = (item, index) => (
		<div className="mb-2 mr-4 flex flex-col items-center sm:mr-8">
			<span
				className={`${
					index === selectedIndex ? "bg-[#2D8CE3]" : "bg-blue-200"
				} h-6 w-6 rounded-full text-center font-bold text-white sm:h-[40px] sm:w-[40px] sm:text-[25px]`}
			>
				{index + 1}
			</span>
			<span
				className={`${
					index === selectedIndex ? "text-[#2D8CE3]" : "text-blue-200"
				} inline-block w-full text-center font-bold`}
			>
				{item}
			</span>
		</div>
	);

	return (
		<div className={className}>
			<div className="flex flex-row flex-wrap">
				{options.map((item, index) => (
					<div key={index}>{renderStep(item, index)}</div>
				))}
			</div>
		</div>
	);
};

StepCounter.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string),
	selectedIndex: PropTypes.number,
	className: PropTypes.string,
};
StepCounter.defaultProps = {
	options: undefined,
	selectedIndex: undefined,
	className: "",
};

export default StepCounter;
