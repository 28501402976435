// Import the functions you need from the SDKs you need
import {initializeApp, getApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getStorage, connectStorageEmulator} from "firebase/storage";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";

import firebaseSchema from "../firebase.json";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
	apiKey: "AIzaSyD2cjc9m_1-rJEefldIyJnetsy6izyIEhw",
	authDomain: "want2remembercloud.firebaseapp.com",
	databaseURL: "https://want2remembercloud-default-rtdb.firebaseio.com",
	projectId: "want2remembercloud",
	storageBucket: "want2remembercloud.appspot.com",
	messagingSenderId: "723376385654",
	appId: "1:723376385654:web:c6d10fe2c771fe8959b957",
	measurementId: "G-L7FFE4CL85",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const getFirebaseValues = () => {
	let firestore = null;
	let auth = null;
	let storage = null;
	let functions = null;

	if (import.meta.env.MODE === "development") {
		const emulatorHosts = firebaseSchema.emulators;
		firestore = getFirestore();
		auth = getAuth();
		storage = getStorage();
		functions = getFunctions(getApp());
		connectFirestoreEmulator(firestore, emulatorHosts.firestore.host, emulatorHosts.firestore.port);
		connectAuthEmulator(auth, `http://${emulatorHosts.auth.host}:${emulatorHosts.auth.port}/`);
		connectStorageEmulator(storage, emulatorHosts.storage.host, emulatorHosts.storage.port);
		connectFunctionsEmulator(functions, emulatorHosts.functions.host, emulatorHosts.functions.port);
	} else {
		firestore = getFirestore(app);
		auth = getAuth(app);
		storage = getStorage(app);
		functions = getFunctions(app);
	}

	return {firestore, auth, storage, functions};
};

const {firestore, auth, storage, functions} = getFirebaseValues();
const analytics = getAnalytics(app);

export {firestore, auth, storage, functions, analytics};
