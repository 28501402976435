import React, {useState} from "react";
import {sendPasswordResetEmail} from "firebase/auth";
import {useNavigate} from "react-router-dom";

import {auth} from "../firebase";
import Logo from "../../../../resources/images/01.png";
import TextInput from "./Components/TextInput";

const ResetPassword = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");

	const resetPassword = inputEmail => {
		if (
			// Check for any missing fields
			inputEmail === ""
		) {
			alert("Please fill out the field given");
		} else if (
			// Check if the email is valid
			!inputEmail
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				)
		) {
			alert("Please enter a valid email address");
		} else {
			sendPasswordResetEmail(auth, inputEmail)
				.then(() => {
					console.log("Password Has Been Reset");
					navigate("/SignIn");
					alert("Password has been reset! Please check your email address.");
				})
				.catch(error => {
					alert(error.message);
				});
		}
	};

	const submitReset = () => {
		resetPassword(email);
	};

	return (
		<div className="m-5 flex">
			<div className="m-auto font-extrabold">
				<center className="m-4">
					<img style={{display: "inline"}} className="h-20 w-20 pl-3" src={Logo} alt="logo" />
					<p className="text-3xl text-blue-300">Forgot Password</p>
				</center>
				<div>
					<div className="mx-2 my-4">
						<TextInput label="Enter Email Address" value={email} onChange={setEmail} />
					</div>
					<div className="my-6 space-y-2 rounded-md px-2">
						<button
							type="button"
							className="w-96 rounded-md bg-blue-400 p-3 font-semibold text-white hover:bg-blue-700 focus:outline-none focus:ring focus:ring-violet-300 active:bg-blue-500"
							onClick={submitReset}
						>
							Reset Password
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
