import React, {useState, useCallback} from "react";
import PropTypes from "prop-types";
import {debounce} from "lodash";
import {CiSearch} from "react-icons/ci";

const Search = ({placeholder, setSearchQuery, className}) => {
	const [inputValue, setInputValue] = useState("");

	const debouncedSearchQuery = useCallback(
		debounce(nextValue => {
			setSearchQuery(nextValue);
		}, 500),
		[],
	); // 500ms delay when typing

	const handleInputChange = ({target: {value}}) => {
		setInputValue(value);
		debouncedSearchQuery(value);
	};

	const handleSearch = event => {
		event.preventDefault();
		// setSearchQuery(inputValue)
	};

	return (
		<div className={`search-container ${className}`}>
			<form
				className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-white align-middle transition duration-150 ease-in-out focus-within:border-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500"
				onSubmit={handleSearch}
			>
				<input
					type="text"
					placeholder={placeholder}
					value={inputValue}
					onChange={handleInputChange}
					className="search-input w-full py-2 pl-2 text-gray-700 focus:outline-none"
				/>
				<CiSearch className="my-2 mr-2 text-2xl text-gray-400" />
			</form>
		</div>
	);
};

Search.propTypes = {
	placeholder: PropTypes.string,
	setSearchQuery: PropTypes.func.isRequired,
	className: PropTypes.string,
};
Search.defaultProps = {
	placeholder: "",
	className: "",
};

export default Search;
