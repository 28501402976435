import React from "react";
import PropTypes from "prop-types";

import TextPicker from "../../Components/TextPicker";
import DatePicker from "../../Components/DatePicker";
import TimePicker from "./TimePicker";
import StatusPicker from "./StatusPicker";
import TagsPicker from "./TagsPicker";
import DynamicList from "./DynamicList";
import ValueOptions from "../../../../../../resources/ValueOptions";

const AddToDo = ({
	title,
	inputLabel = "Task Title",
	inputPlaceholder = "Tap to enter task title",
	textAreaLabel = "Task Description",
	textAreaPlaceholder = "Tap to enter task description",
	value = {},
	onChange,
}) => {
	const updateTempValue = (property, newValue) => {
		const newObj = {...value, [property]: newValue};
		onChange(newObj);
	};

	return (
		<div>
			<TextPicker
				title={title}
				inputLabel={inputLabel}
				inputPlaceholder={inputPlaceholder}
				textAreaLabel={textAreaLabel}
				textAreaPlaceholder={textAreaPlaceholder}
				value={value}
				onChange={textPickerValue => {
					onChange({...value, ...textPickerValue});
				}}
			/>
			<DatePicker label="Date" date={value.date} setDate={date => updateTempValue("date", date)} />
			<TimePicker
				label="Starting Time"
				value={value.startingTime}
				onChange={time => updateTempValue("startingTime", time)}
			/>
			<TimePicker label="Ending Time" value={value.endingTime} onChange={time => updateTempValue("endingTime", time)} />
			<StatusPicker
				title="Status"
				statuses={ValueOptions.status}
				selectedStatus={value.status ?? ""}
				onSelectStatus={status => updateTempValue("status", status)}
			/>
			<TagsPicker tags={value.tags ?? []} setTags={tags => updateTempValue("tags", tags)} />
			<DynamicList
				value={value.notes ?? []}
				onChange={notes => {
					updateTempValue("notes", notes);
				}}
				itemName="Note"
			>
				<TextPicker
					inputLabel="Note Title"
					inputPlaceholder="Write Note Title"
					textAreaLabel="Note Description"
					textAreaPlaceholder="Write Note Description"
				/>
			</DynamicList>
		</div>
	);
};

AddToDo.propTypes = {
	title: PropTypes.string,
	inputLabel: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	textAreaLabel: PropTypes.string,
	textAreaPlaceholder: PropTypes.string,
	value: PropTypes.shape({
		title: PropTypes.string,
		content: PropTypes.string,
		date: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.shape({
				nanoseconds: PropTypes.number,
				seconds: PropTypes.number,
			}),
		]),
		startingTime: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.shape({
				nanoseconds: PropTypes.number,
				seconds: PropTypes.number,
			}),
		]),
		endingTime: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.shape({
				nanoseconds: PropTypes.number,
				seconds: PropTypes.number,
			}),
		]),
		status: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.string),
		notes: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				content: PropTypes.string,
			}),
		),
	}),
	onChange: PropTypes.func,
};
AddToDo.defaultProps = {
	title: undefined,
	inputLabel: "Task Title",
	inputPlaceholder: "Tap to enter task title",
	textAreaLabel: "Task Description",
	textAreaPlaceholder: "Tap to enter task description",
	value: {},
	onChange: undefined,
};

export default AddToDo;
