import React from "react";
import PropTypes from "prop-types";
import classes from "./Switch.module.css";

const Switch = ({checked, onToggle}) => {
	let finalClass = classes.switch;

	finalClass += checked ? ` ${classes.on}` : ` ${classes.off}`;

	return (
		<button type="button" aria-label="Switch" className={finalClass} onClick={onToggle}>
			<div className={classes.nob} />
		</button>
	);
};

Switch.propTypes = {
	checked: PropTypes.bool,
	onToggle: PropTypes.func,
};
Switch.defaultProps = {
	checked: undefined,
	onToggle: undefined,
};

export default Switch;
