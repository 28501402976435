import React from "react";
import PropTypes from "prop-types";

import StatusPicker from "./StatusPicker";
import OneLineTextPicker from "../../Components/OneLineTextPicker";
import ValueOptions from "../../../../../../resources/ValueOptions";

const AddSimpleToDo = ({
	inputLabel = "Simple Task Title",
	inputPlaceholder = "Tap to enter task title",
	value = {},
	onChange,
}) => {
	const updateTempValue = (property, newValue) => {
		const newObj = {...value, [property]: newValue};
		onChange(newObj);
	};

	return (
		<div>
			<OneLineTextPicker
				inputLabel={inputLabel}
				inputPlaceholder={inputPlaceholder}
				textInput={value.title ?? ""}
				setTextInput={input => updateTempValue("title", input)}
			/>
			<StatusPicker
				title="Status"
				statuses={ValueOptions.status}
				selectedStatus={value.status ?? ""}
				onSelectStatus={selection => updateTempValue("status", selection)}
			/>
		</div>
	);
};

AddSimpleToDo.propTypes = {
	inputLabel: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	value: PropTypes.shape({
		title: PropTypes.string,
		status: PropTypes.string,
	}),
	onChange: PropTypes.func,
};
AddSimpleToDo.defaultProps = {
	inputLabel: "Simple Task Title",
	inputPlaceholder: "Tap to enter task title",
	value: {},
	onChange: undefined,
};

export default AddSimpleToDo;
