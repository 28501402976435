import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {ref, getDownloadURL} from "firebase/storage";
import {doc, onSnapshot} from "firebase/firestore";

import {firestore, storage} from "../../firebase";
import ProfileIcon from "../../../../../resources/icons/Profile.png";

const Profile = ({name, id, pic, onClick, hideName, displayFullName, exportUserData, exportUserName, className}) => {
	const [userData, setUserData] = useState({});
	const [userName, setUserName] = useState("...");
	const [profileImgRef, setProfileImgRef] = useState(ProfileIcon);

	useEffect(() => {
		if (id && (!name || !pic)) {
			const userDataSubscriber = onSnapshot(doc(firestore, `publicUsersInfo/${id}`), d => {
				setUserData(d.data());
			});

			return userDataSubscriber;
		}
		return undefined;
	}, [id]);

	useEffect(() => {
		if (exportUserData) exportUserData(userData);
	}, [userData]);

	useEffect(() => {
		if (exportUserName) exportUserName(userName);
	}, [userName]);

	useEffect(() => {
		if (userData?.profileImage?.thumbnail) {
			const {storagePath} = userData.profileImage.thumbnail;
			if (storagePath) {
				getDownloadURL(ref(storage, storagePath)).then(url => {
					setProfileImgRef(url);
				});
			}
		} else {
			setProfileImgRef(ProfileIcon);
		}

		if (userData?.firstName || userData?.middleName || userData?.lastName) {
			const {firstName, middleName, lastName} = userData;
			if (displayFullName) {
				const shortenedMiddleName = middleName && middleName.length > 0 ? middleName[0] : middleName;
				setUserName(makeName([firstName, shortenedMiddleName, lastName]));
			} else {
				setUserName(firstName ?? lastName ?? middleName);
			}
		}
	}, [userData, displayFullName]);

	const makeName = nameArr => {
		let fullName = "";
		for (let i = 0; i < nameArr.length; i++) {
			if (nameArr[i]) {
				if (fullName.length > 0) fullName += " ";
				fullName += nameArr[i];
			}
		}
		return fullName;
	};

	// blue border around "Me" profile
	return (
		<div className={`justify-center ${className}`}>
			{/* blue border for user profile pic */}
			<button
				type="button"
				className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border-2 border-solid border-sky-300 hover:border-blue-800 active:border-blue-200 sm:h-16 sm:w-16"
				onClick={onClick}
			>
				<div>
					<img className="h-8 w-8 sm:h-16 sm:w-16" src={pic ?? profileImgRef} alt="profile" />
				</div>
			</button>
			{!hideName && (
				<div>
					<span className="inline-block w-full text-center text-xs font-bold">{name ?? userName}</span>
				</div>
			)}
		</div>
	);
};

Profile.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	pic: PropTypes.string,
	onClick: PropTypes.func,
	hideName: PropTypes.bool,
	displayFullName: PropTypes.bool,
	exportUserData: PropTypes.func,
	exportUserName: PropTypes.func,
	className: PropTypes.string,
};
Profile.defaultProps = {
	name: undefined,
	id: undefined,
	pic: undefined,
	onClick: undefined,
	hideName: false,
	displayFullName: true,
	exportUserData: undefined,
	exportUserName: undefined,
	className: undefined,
};

export default Profile;
