import React from "react";
import PropTypes from "prop-types";
import classes from "./StatusPicker.module.css";

const StatusPicker = ({statuses, title, selectedStatus, onSelectStatus, allowDeselect}) => {
	const handleSelected = event => {
		const status = event.target.value;

		if (status === selectedStatus && allowDeselect) {
			onSelectStatus("");
			return;
		}
		onSelectStatus(status);
	};

	const statusContent = statuses.map(status => {
		const isSelected = status && status === selectedStatus;
		const buttonClass = `${classes.status} ${isSelected ? classes.selected : classes.nonSelected}`;

		return (
			<button type="button" className={buttonClass} onClick={handleSelected} value={status} key={status}>
				{status}
			</button>
		);
	});

	return (
		<div>
			<h4 className={classes.header}>
				<b>{title}</b>
			</h4>
			<div className={classes.options}>{statusContent}</div>
		</div>
	);
};

StatusPicker.propTypes = {
	statuses: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string,
	selectedStatus: PropTypes.string,
	onSelectStatus: PropTypes.func,
	allowDeselect: PropTypes.bool,
};
StatusPicker.defaultProps = {
	statuses: undefined,
	title: undefined,
	selectedStatus: undefined,
	onSelectStatus: undefined,
	allowDeselect: true,
};

export default StatusPicker;
