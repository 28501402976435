import React from "react";
import PropTypes from "prop-types";

const ModalTiles = ({onClick, pic, title, description}) => (
	<button
		type="button"
		className="mt-2 flex w-full flex-grow items-center rounded-lg border-2 border-solid border-white bg-white p-2 font-bold hover:border-blue-800 active:border-blue-200"
		onClick={onClick}
	>
		<div className="m-1">
			<img className="h-8 w-8 object-scale-down" src={pic} alt="" />
		</div>
		<div className="m-1 text-left">
			<div>{title}</div>
			<div className="text-xs font-medium">{description}</div>
		</div>
	</button>
);

ModalTiles.propTypes = {
	onClick: PropTypes.func,
	pic: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
};
ModalTiles.defaultProps = {
	onClick: undefined,
	pic: undefined,
	title: undefined,
	description: undefined,
};

export default ModalTiles;
