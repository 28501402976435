import React from "react";
import PropTypes from "prop-types";

import InputContainer from "./InputContainer";

const DatePicker = ({label, date, setDate}) => {
	const formatDate = value => {
		if (value) {
			try {
				let adjustedDate = value;
				if (typeof value === "string") {
					const newDate = new Date(value);
					adjustedDate = newDate.toString() === "Invalid Date" ? null : newDate;
				} else if (value.seconds) {
					adjustedDate = new Date(value.seconds * 1000);
				} else if (typeof value === "object" && Object.prototype.hasOwnProperty.call(value, "month") && value.day) {
					const {month, day, year} = value;
					const newDate = new Date();
					newDate.setHours(0, 0, 0, 0);
					if (!isNaN(month)) newDate.setMonth(month - 1);
					if (day) newDate.setDate(day);
					if (!isNaN(year)) newDate.setFullYear(year);
					adjustedDate = newDate;
				}
				const isoDate = adjustedDate.toISOString();
				const indexOfT = isoDate.indexOf("T");
				return isoDate.substring(0, indexOfT);
			} catch (error) {
				return "";
			}
		} else {
			return "";
		}
	};

	return (
		<InputContainer label={label}>
			<input
				type="date"
				value={formatDate(date)}
				onChange={newDate => {
					const splitValue = newDate.target.value.split("-");
					if (splitValue.length === 3) {
						const year = Number(splitValue[0]);
						const month = Number(splitValue[1]) - 1;
						const day = Number(splitValue[2]);
						const tempDate = new Date();
						tempDate.setHours(0, 0, 0, 0);
						tempDate.setFullYear(year, month, day);
						setDate(tempDate);
					} else {
						setDate(null);
					}
				}}
			/>
		</InputContainer>
	);
};

DatePicker.propTypes = {
	label: PropTypes.string,
	date: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
		PropTypes.shape({
			nanoseconds: PropTypes.number,
			seconds: PropTypes.number,
		}),
	]),
	setDate: PropTypes.func,
};
DatePicker.defaultProps = {
	label: undefined,
	date: undefined,
	setDate: undefined,
};

export default DatePicker;
