import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import {useNavigate, useSearchParams} from "react-router-dom";
import {collection, doc, getDoc} from "firebase/firestore";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage";
import {isEqual, cloneDeep, isArray} from "lodash";

import {AuthContext} from "../../AuthProvider";
import {firestore, storage} from "../../firebase";
import FilePicker from "./Components/FilePicker";
import FileEditor from "./Components/FileEditor";
import OneLineTextPicker from "../Components/OneLineTextPicker";
import PhonePicker from "../Components/PhonePicker";
import LocationPicker from "./Components/LocationPicker";
import DatePicker from "../Components/DatePicker";
import DynamicList from "./Components/DynamicList";
import {getContactName} from "../../../../../resources/commonUtilities";
import {setContact, updateContact} from "../../utilities/firestoreUtilities";
import ValueOptions from "../../../../../resources/ValueOptions";
import style from "./CreateMemory.module.css";

const photoFileTypes = ["image/png", "image/jpeg", "image/jpg"];

const LabeledPhonePicker = ({value = {}, onChange}) => (
	<>
		<OneLineTextPicker
			inputLabel="Phone Label"
			inputPlaceholder="ex. Mobile"
			textInput={value.label ?? ""}
			setTextInput={newValue => onChange({...value, label: newValue})}
		/>
		<PhonePicker
			inputLabel="Phone Number"
			inputPlaceholder="Phone Number"
			phoneInput={value.number ?? ""}
			setPhoneInput={newValue => onChange({...value, number: newValue})}
		/>
	</>
);

LabeledPhonePicker.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
};
LabeledPhonePicker.defaultProps = {
	value: {},
	onChange: undefined,
};

const LabeledEmail = ({value = {}, onChange}) => (
	<>
		<OneLineTextPicker
			inputLabel="Email Label"
			inputPlaceholder="ex. Work"
			textInput={value.label ?? ""}
			setTextInput={newValue => onChange({...value, label: newValue})}
		/>
		<OneLineTextPicker
			inputLabel="Email"
			inputPlaceholder="Email"
			textInput={value.email ?? ""}
			setTextInput={newValue => onChange({...value, email: newValue})}
		/>
	</>
);

LabeledEmail.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
};
LabeledEmail.defaultProps = {
	value: {},
	onChange: undefined,
};

const LabeledLocation = ({value = {}, onChange}) => (
	<>
		<OneLineTextPicker
			inputLabel="Location Label"
			inputPlaceholder="ex. House"
			textInput={value.label ?? ""}
			setTextInput={newValue => onChange({...value, label: newValue})}
		/>
		<LocationPicker
			location={value.location ?? ValueOptions.defaultLocationInfo}
			setLocation={newValue => onChange({...value, location: newValue})}
		/>
	</>
);

LabeledLocation.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
};
LabeledLocation.defaultProps = {
	value: {},
	onChange: undefined,
};

const LabeledURL = ({value = {}, onChange}) => (
	<OneLineTextPicker
		// inputLabel="URL"
		inputPlaceholder="ex. www.want2remember.com"
		textInput={value.url ?? ""}
		setTextInput={newValue => onChange({url: newValue})}
	/>
);

LabeledURL.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
};
LabeledURL.defaultProps = {
	value: {},
	onChange: undefined,
};

const convertDateToContactDate = value => {
	if (value && value instanceof Date && value.getTime && !isNaN(value.getTime())) {
		const newValue = {
			day: value.getDate(),
			month: value.getMonth() + 1,
			year: value.getFullYear(),
		};
		return newValue;
	}
	return null;
};

const convertLocationsToContactLocations = value => {
	if (value && isArray(value)) {
		const tempLocations = [];
		value.forEach(locationData => {
			const newLocation = {
				...(locationData?.location?.locationDetails ?? {}),
			};
			if (locationData?.label) newLocation.label = locationData.label;
			if (locationData?.location?.latLng) newLocation.latLng = locationData.location.latLng;
			if (locationData?.location?.locationType) newLocation.locationType = locationData.location.locationType;

			if (!newLocation.formattedAddress && newLocation.fullAddress) {
				newLocation.formattedAddress = newLocation.fullAddress.replace(/undefined,\s/g, "");
				delete newLocation.fullAddress;
			}
			if (!newLocation.street && !newLocation.neighborhood && (newLocation.address1 || newLocation.address2)) {
				newLocation.street = [newLocation.address1, newLocation.address2].filter(a => Boolean(a)).join("\n");
			}
			if (!newLocation.postCode && newLocation.zip) newLocation.postCode = newLocation.zip;

			tempLocations.push(newLocation);
		});
		return tempLocations;
	}
	return value;
};

const convertContactLocationsToLocations = value => {
	if (value && isArray(value)) {
		const tempContactLocations = [];
		const keysToTransferToLocationDetails = ["fullAddress", "address1", "address2", "city", "state", "country", "zip"];
		value.forEach(locationData => {
			const newLocation = {location: {locationDetails: {}}};

			if (locationData.label) newLocation.label = locationData.label;
			if (locationData.latLng) newLocation.location.latLng = locationData.latLng;
			if (locationData.locationType) newLocation.location.locationType = locationData.locationType;

			keysToTransferToLocationDetails.forEach(key => {
				if (locationData[key]) newLocation.location.locationDetails[key] = locationData[key];
			});

			if (!newLocation.location.locationDetails.fullAddress && locationData.formattedAddress)
				newLocation.location.locationDetails.fullAddress = locationData.formattedAddress;

			if (!newLocation.location.locationDetails.fullAddress) {
				const keys = ["street", "neighborhood", "poBox", "city", "state", "postCode", "country"];
				let tempFullAddress = "";
				keys.forEach((addrKey, index) => {
					if (locationData[addrKey]) {
						tempFullAddress += locationData[addrKey];
						if (addrKey === "state" && index !== keys.length - 1) tempFullAddress += " ";
						else if (index !== keys.length - 1) tempFullAddress += ", ";
					}
				});
				if (tempFullAddress) newLocation.location.locationDetails.fullAddress = tempFullAddress;
			}

			if (!newLocation.location.locationDetails.zip && locationData.postCode)
				newLocation.location.locationDetails.zip = locationData.postCode;

			if (
				!newLocation.location.locationDetails.address1 &&
				!newLocation.location.locationDetails.address2 &&
				locationData.street
			) {
				const splitStreet = locationData.street.split("\n");
				[newLocation.location.locationDetails.address1] = splitStreet;
				if (splitStreet.length > 1) {
					newLocation.location.locationDetails.address2 = splitStreet.slice(1).join(" ");
				}
			}

			if (locationData.poBox) {
				if (!newLocation.location.locationDetails.address2) {
					newLocation.location.locationDetails.address2 = "";
				}
				newLocation.location.locationDetails.address2 += locationData.poBox;
			}

			if (
				!newLocation.location.locationDetails.address1 &&
				!newLocation.location.locationDetails.address2 &&
				locationData.neighborhood
			) {
				newLocation.location.locationDetails.address1 = locationData.neighborhood;
			}

			if (Object.keys(newLocation.location.locationDetails).length === 0) delete newLocation.location.locationDetails;

			if (Object.keys(newLocation.location).length === 0) delete newLocation.location;

			tempContactLocations.push(newLocation);
		});
		return tempContactLocations;
	}
	return value;
};

const handleUpload = (userFile, userId, contactId, profileImage = false) => {
	const cleanedFileName = userFile.name.replace(/\s/g, "_");
	const storageRef = ref(
		storage,
		`/userFiles/${userId}/contacts/${contactId}/${profileImage ? "profile/" : ""}${cleanedFileName}`,
	);
	uploadBytes(storageRef, userFile).then(() => {
		console.log(`Uploaded ${userFile.name}`);
	});
};

const CreateContact = () => {
	const {currentUser} = useContext(AuthContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const contactId = searchParams.get("id");

	const [creatingNew, setCreatingNew] = useState(true);
	const [prevValues, setPrevValues] = useState({});
	const [tempValues, setTempValues] = useState({});
	const [canEdit, setCanEdit] = useState(false);
	const [created, setCreated] = useState(false);
	const [newFiles, setNewFiles] = useState([]);
	const [newProfilePic, setNewProfilePic] = useState(null);
	const [thumbnailRef, setThumbnailRef] = useState(null);

	useEffect(() => {
		if (!contactId) {
			// initializes contact values to default values
			const tempNewContactValues = {
				viewers: [currentUser.uid],
				editors: [currentUser.uid],
				usersInfo: {},
				contactInfo: {},
			};
			setPrevValues(tempNewContactValues);
			setTempValues(cloneDeep(tempNewContactValues));
			setCanEdit(true);
		} else {
			// initializes contact values to pre-existing values
			const contactDocRef = doc(firestore, "contacts", contactId);
			getDoc(contactDocRef).then(contactDoc => {
				if (contactDoc.exists) {
					const data = contactDoc.data();
					setPrevValues(data);
					setTempValues(cloneDeep(data));
					setCreatingNew(false);
					setCanEdit(true);
					fetchContactImage(data);
				}
			});
		}
	}, [contactId]);

	useEffect(() => {
		if (!created && canEdit) {
			const name = getContactName(tempValues.contactInfo ?? {});
			if (name && (!tempValues.name || tempValues.name !== name)) updateRootValue("name", name);
		}
	}, [tempValues]);

	useEffect(() => {
		console.log("tempValues:", JSON.stringify(tempValues, null, "\t"));
	}, [tempValues]);

	const updateContactInfoValue = (key, value) => {
		const tempObj = {...tempValues};
		tempObj.contactInfo = tempValues.contactInfo ? {...tempValues.contactInfo} : {};
		tempObj.contactInfo[key] = value;
		setTempValues(tempObj);
	};

	const updateRootValue = (key, value) => {
		const tempObj = {...tempValues};
		tempObj[key] = value;
		setTempValues(tempObj);
	};

	const goBack = () => {
		navigate(-1);
	};

	const saveContact = async () => {
		const colRef = collection(firestore, `contacts`);
		const tempContactId = contactId ?? doc(colRef).id;
		const newTempSaveData = cloneDeep(tempValues);
		const userDocRef = doc(firestore, `publicUsersInfo`, currentUser.uid);
		const userPublicData = await getDoc(userDocRef);

		const contactAction = creatingNew ? setContact : updateContact;

		if (userPublicData.exists) {
			setCreated(true);
			setCanEdit(false);

			if (prevValues?.usersInfo) {
				newTempSaveData.usersInfo = {...prevValues.usersInfo};
			} else {
				newTempSaveData.usersInfo = {};
			}

			newTempSaveData.usersInfo[currentUser.uid] = userPublicData.data();

			// removes any duplicate data
			if (!creatingNew) {
				Object.entries(prevValues).forEach(([key, value]) => {
					if (newTempSaveData[key] && isEqual(value, newTempSaveData[key])) {
						console.log("removed field", key, "from tempValues");
						delete newTempSaveData[key];
					}
				});

				if (prevValues.contactInfo && newTempSaveData.contactInfo) {
					Object.entries(prevValues.contactInfo).forEach(([key, value]) => {
						if (newTempSaveData.contactInfo[key] && isEqual(value, newTempSaveData.contactInfo[key])) {
							console.log("removed field", key, "from contactInfo in tempValues");
							delete newTempSaveData.contactInfo[key];
						}
					});
				}

				if (newTempSaveData.contactInfo && Object.keys(newTempSaveData.contactInfo).length === 0)
					delete newTempSaveData.contactInfo;

				if (prevValues?.usersInfo && isEqual(prevValues.usersInfo, newTempSaveData.usersInfo)) {
					delete newTempSaveData.usersInfo;
				}
			}

			contactAction(tempContactId, newTempSaveData)
				.then(() => {
					Object.values(newFiles).forEach(file => {
						handleUpload(file, currentUser.uid, tempContactId);
					});
					if (newProfilePic) {
						handleUpload(newProfilePic, currentUser.uid, tempContactId, true);
					}
					goBack();
				})
				.catch(error => {
					console.error("error:", error);
					setCreated(false);
					setCanEdit(true);
				});
		}
	};

	const fetchContactImage = contact => {
		if (contact?.profileImage?.thumbnail) {
			const contactImage = contact.profileImage;
			const {thumbnail} = contactImage;
			if (thumbnail && thumbnail.storagePath) {
				getDownloadURL(ref(storage, thumbnail.storagePath)).then(url => {
					setThumbnailRef(url);
				});
			}
		}
	};

	return (
		<div className="w-full">
			<div className={style.top}>
				<button type="button" onClick={goBack}>
					Back
				</button>
				<button type="button" onClick={saveContact}>
					Save
				</button>
			</div>
			<div className="mx-auto w-full max-w-md pb-6">
				<div className="m-10 text-3xl font-medium">{creatingNew ? "Create Contact" : "Edit Contact"}</div>
				{thumbnailRef && (
					<div>
						<span className="mb-2 text-[20px] font-bold text-[#6B7280]">Saved Profile Picture</span>
						<img className="mb-2 h-24 w-24 rounded-full border-2" src={thumbnailRef} alt="thumbnail" />
					</div>
				)}
				<div className="mb-1 mt-1 flex flex-col">
					<span className="mb-2 text-[20px] font-bold text-[#6B7280]">Profile Picture</span>
					<input
						className="w-64 text-sm font-medium"
						type="file"
						accept="image/*"
						onChange={e => {
							const imageFile = e.target.files[0];
							if (imageFile && !photoFileTypes.includes(imageFile.type)) {
								alert("Contact photo must be a .jpg, .jpeg, or .png file!");
								setNewFiles(null);
							} else setNewProfilePic(e.target.files[0]);
						}}
					/>
				</div>
				<FilePicker setFiles={setNewFiles} />
				<FileEditor existingFiles={tempValues.files} setExistingFiles={value => updateRootValue("files", value)} />
				<OneLineTextPicker
					inputType="text"
					inputLabel="First Name"
					inputPlaceholder="First Name"
					textInput={tempValues?.contactInfo?.givenName ?? ""}
					setTextInput={value => updateContactInfoValue("givenName", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Middle Name"
					inputPlaceholder="Middle Name"
					textInput={tempValues?.contactInfo?.middleName ?? ""}
					setTextInput={value => updateContactInfoValue("middleName", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Last Name"
					inputPlaceholder="Last Name"
					textInput={tempValues?.contactInfo?.familyName ?? ""}
					setTextInput={value => updateContactInfoValue("familyName", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Prefix"
					inputPlaceholder="Prefix"
					textInput={tempValues?.contactInfo?.prefix ?? ""}
					setTextInput={value => updateContactInfoValue("prefix", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Suffix"
					inputPlaceholder="Suffix"
					textInput={tempValues?.contactInfo?.suffix ?? ""}
					setTextInput={value => updateContactInfoValue("suffix", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Company"
					inputPlaceholder="Company"
					textInput={tempValues?.contactInfo?.company ?? ""}
					setTextInput={value => updateContactInfoValue("company", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Department"
					inputPlaceholder="Department"
					textInput={tempValues?.contactInfo?.department ?? ""}
					setTextInput={value => updateContactInfoValue("department", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Job Title"
					inputPlaceholder="Job Title"
					textInput={tempValues?.contactInfo?.jobTitle ?? ""}
					setTextInput={value => updateContactInfoValue("jobTitle", value)}
				/>
				<DatePicker
					label="Birthday"
					date={tempValues?.contactInfo?.birthday ?? undefined}
					setDate={value => updateContactInfoValue("birthday", convertDateToContactDate(value))}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Relationship"
					inputPlaceholder="Relationship"
					textInput={tempValues?.contactInfo?.relationship ?? ""}
					setTextInput={value => updateContactInfoValue("relationship", value)}
				/>
				<OneLineTextPicker
					inputType="text"
					inputLabel="Note"
					inputPlaceholder="Note"
					textInput={tempValues?.contactInfo?.note ?? ""}
					setTextInput={value => updateContactInfoValue("note", value)}
				/>
				<DynamicList
					value={tempValues?.contactInfo?.emailAddresses ?? []}
					onChange={value => updateContactInfoValue("emailAddresses", value)}
					itemName="Email"
				>
					<LabeledEmail />
				</DynamicList>
				<DynamicList
					value={tempValues?.contactInfo?.phoneNumbers ?? []}
					onChange={value => updateContactInfoValue("phoneNumbers", value)}
					itemName="Phone Number"
				>
					<LabeledPhonePicker />
				</DynamicList>
				<DynamicList
					value={convertContactLocationsToLocations(tempValues?.contactInfo?.postalAddresses ?? [])}
					onChange={value => updateContactInfoValue("postalAddresses", convertLocationsToContactLocations(value))}
					itemName="Address"
				>
					<LabeledLocation />
				</DynamicList>
				<DynamicList
					value={tempValues?.contactInfo?.urlAddresses ?? []}
					onChange={value => updateContactInfoValue("urlAddresses", value)}
					itemName="URL"
				>
					<LabeledURL />
				</DynamicList>
				{!created && canEdit && (
					<button
						type="button"
						className="ml-auto mt-5 block rounded-3xl border-2 border-solid border-blue-200 bg-white px-4 py-2 text-sm font-bold hover:border-blue-800 active:border-blue-200"
						onClick={saveContact}
					>
						Save
					</button>
				)}
			</div>
		</div>
	);
};

export default CreateContact;
