import {useState, useEffect, React} from "react";
import {ref, getDownloadURL} from "firebase/storage";
import {isArray} from "lodash";
import PropTypes from "prop-types";
import {FaPaperclip} from "react-icons/fa";

import Colors from "../../../../../../resources/Colors";
import {storage} from "../../../firebase";

const FilePreview = ({files}) => {
	const [thumbnails, setThumbnails] = useState({});
	const [urls, setURLs] = useState({});

	useEffect(() => {
		if (files && isArray(files) && files.length > 0) {
			const filePromises = files.map(file =>
				getDownloadURL(ref(storage, file.file.storagePath)).then(url => ({
					file: file.file.storagePath,
					url,
				})),
			);
			const thumbPromises = files
				.filter(file => file.thumbnail)
				.map(file =>
					getDownloadURL(ref(storage, file.thumbnail.storagePath)).then(url => ({
						file: file.file.storagePath,
						url,
					})),
				);
			Promise.all(thumbPromises).then(thumbsArr => {
				const tempThumbs = {};
				thumbsArr.forEach(thumbs => {
					tempThumbs[thumbs.file] = thumbs.url;
				});
				setThumbnails(tempThumbs);
			});
			Promise.all(filePromises).then(urlsArr => {
				const tempUrls = {};
				urlsArr.forEach(download => {
					console.log("url", download.url);
					tempUrls[download.file] = download.url;
				});
				setURLs(tempUrls);
			});
		}
	}, [files]);

	return (
		<div>
			{files && isArray(files) && files.length > 0 ? (
				<div className="p-10">
					<p className="font-medium" style={{color: Colors.lightGrey}}>
						Files
					</p>
					<div className="my-2 space-y-2 rounded-xl border-2 bg-white">
						{files.map(file => (
							<div className="flex flex-row p-4" key={file.file.storagePath}>
								{thumbnails[file.file.storagePath] ? (
									<div key={file.file.storagePath}>
										<img className="flex rounded-lg" src={thumbnails[file.file.storagePath]} alt="thumbail" />
									</div>
								) : (
									<div>
										<FaPaperclip className="m-20 flex rounded-lg" style={{fontSize: "40px", color: "gray"}} />
									</div>
								)}
								<div className="pl-5">
									<p className="break-all font-bold">{file.file.name}</p>
									{urls[file.file.storagePath] ? (
										<a
											key={file.file.storagePath}
											className="font-bold text-blue-500"
											href={urls[file.file.storagePath]}
											target="_blank"
											rel="noreferrer"
										>
											Download
										</a>
									) : null}
									{/* {urls.map(
                                (url, index) =>
                                    url &&
                                    file.file.storagePath === url.file && (
                                        <a
                                            key={index}
                                            className="font-bold text-blue-500"
                                            href={url.download_url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Download
                                        </a>
                                    ),
                            )} */}
								</div>
							</div>
						))}
					</div>
				</div>
			) : null}
		</div>
	);
};

FilePreview.propTypes = {
	files: PropTypes.arrayOf(
		PropTypes.shape({
			file: PropTypes.shape({
				author: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				size: PropTypes.number.isRequired,
				storageBucket: PropTypes.string.isRequired,
				storagePath: PropTypes.string.isRequired,
			}).isRequired,
			thumbnail: PropTypes.shape({
				name: PropTypes.string.isRequired,
				size: PropTypes.number.isRequired,
				storageBucket: PropTypes.string.isRequired,
				storagePath: PropTypes.string.isRequired,
			}),
		}),
	),
};
FilePreview.defaultProps = {
	files: [],
};

export default FilePreview;
