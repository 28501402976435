import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {isArray, isEqual} from "lodash";

import {FiPaperclip} from "react-icons/fi";
import {AuthContext} from "../../../AuthProvider";
import QuickLookGenInfo from "./QuickLookGenInfo";
import QuickLookTimeAgo from "./QuickLookTimeAgo";
import QuickLookMemoryDate from "./QuickLookMemoryDate";
import QuickLookViewers from "./QuickLookViewers";
import {mergeSchema} from "../../../../../../resources/commonUtilities";
import {schemas} from "../../../../../../resources/schemas";
import ValueOptions from "../../../../../../resources/ValueOptions";

const {defaultMemoryTemplateColor, defaultMemoryTemplateTextColor} = ValueOptions;

const QuickLookCard = ({memData}) => {
	const navigate = useNavigate();
	const {currentUser, selectedPalette} = useContext(AuthContext);

	const [mergedSchema, setMergedSchema] = useState(memData?.schema ?? {});

	useEffect(() => {
		const memorySchema = memData?.schema ?? {};
		const schemaId = memorySchema?.id ?? null;
		const savedSchema = schemaId ? schemas[schemaId] ?? {} : {};
		const tempMergedSchema = mergeSchema(savedSchema, memorySchema);
		if (!isEqual(mergedSchema, tempMergedSchema)) {
			setMergedSchema(tempMergedSchema);
		}
	}, [memData]);

	const backgroundColor =
		selectedPalette?.colors?.[memData?.typeOfMemoryId] ??
		memData?.schema?.backgroundColor ??
		defaultMemoryTemplateColor;
	const textColor =
		selectedPalette?.textColors?.[memData?.typeOfMemoryId] ??
		memData?.schema?.textColor ??
		defaultMemoryTemplateTextColor;

	return (
		<button
			type="button"
			key={memData.id}
			className="w-full max-w-sm overflow-clip rounded-3xl border-8 bg-white sm:w-4/5 md:w-3/4 md:max-w-md lg:max-w-lg"
			style={{borderColor: backgroundColor}}
			onClick={() => navigate(`/app/DisplayMemoryInformation/?memoryId=${memData.id}`)}
		>
			<div className="flex">
				<div className="basis-1/4 rounded-tl-3xl p-3">
					<QuickLookMemoryDate dateSchema={mergedSchema?.quickLook?.date ?? []} memData={memData} />
					{(memData?.viewers ?? []).filter(id => id !== currentUser.uid).length > 0 && (
						<div className="pt-3">
							<QuickLookViewers viewerIds={memData.viewers ?? []} usersInfo={memData.usersInfo} />
						</div>
					)}
				</div>
				<div className="basis-3/4">
					<QuickLookGenInfo
						memoryTitle={memData.title}
						status={memData.memory.status}
						memoryType={memData.typeOfMemory}
						bgColor={backgroundColor}
						textColor={textColor}
					/>
				</div>
			</div>
			{memData?.files?.length > 0 && (
				<div className="flex">
					<div className="basis-1/4 p-3" />
					<div className="flex basis-3/4">
						<div className="flex items-center">
							<FiPaperclip style={{transform: "rotate(225deg) rotateX(180deg)"}} />
							<p className="place-self-center text-black">{memData?.files?.length} Files</p>
						</div>
					</div>
				</div>
			)}
			<div className="flex">
				<div className="basis-1/4 p-3">
					<QuickLookTimeAgo
						timeSchema={mergedSchema?.quickLook?.time ?? []}
						dateSchema={mergedSchema?.quickLook?.date ?? []}
						memData={memData}
					/>
				</div>
				<ul className="flex basis-3/4 flex-wrap pr-3 pt-3">
					{memData?.categories &&
						isArray(memData.categories) &&
						memData.categories.map(currCat => (
							<li key={currCat} className="mb-1 mr-2 align-middle">
								<p
									className="text-md min-w-[50px] rounded-full border-2 p-2 text-center font-bold"
									style={{
										borderColor: backgroundColor,
										color: backgroundColor,
									}}
								>
									{currCat}
								</p>
							</li>
						))}
				</ul>
			</div>
		</button>
	);
};

QuickLookCard.propTypes = {
	memData: PropTypes.object,
};
QuickLookCard.defaultProps = {
	memData: undefined,
};

export default QuickLookCard;
