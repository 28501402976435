import React from "react";
import PropTypes from "prop-types";
import classes from "./InputContainer.module.css";

const InputContainer = ({label, buttonLabel, buttonOnClick, children, className = "", ...props}) => {
	const finalClass = classes.inputContainer;
	const headerClass = classes.headerContainer;

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={`${finalClass} ${className}`} {...props}>
			<div className={headerClass}>
				{label && <h2>{label}</h2>}
				{buttonLabel && buttonOnClick && (
					<button type="button" onClick={buttonOnClick}>
						{buttonLabel}
					</button>
				)}
			</div>

			<div>{children}</div>
		</div>
	);
};
InputContainer.propTypes = {
	label: PropTypes.string,
	buttonLabel: PropTypes.string,
	buttonOnClick: PropTypes.func,
	children: PropTypes.element,
	className: PropTypes.string,
};
InputContainer.defaultProps = {
	label: undefined,
	buttonLabel: undefined,
	buttonOnClick: undefined,
	children: undefined,
	className: "",
};

export default InputContainer;
